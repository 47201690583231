import React from 'react';
import { Layout, Button, Badge, Space, Typography, theme, Popover, List } from 'antd';
import { BellOutlined, RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import logo from '../../logo.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom';

const { Header } = Layout;
const { Text, Paragraph } = Typography;
const { useToken } = theme;

const StyledHeader = styled(Header)`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 32px;
  height: auto;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LogoImage = styled.img`
  height: 25.5px;
  object-fit: contain;
`;

const NavButtons = styled(Space)`
  display: flex;
  gap: 8px;
`;

const UserInfo = styled.div`
  text-align: right;
  line-height: 16px;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;


const NotificationContent = styled.div`
  width: 400px;
  max-height: 400px;
  overflow-y: auto;
  background-color: #f5f5f5;
  margin-bottom: 32px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
`;

const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const NotificationItem = styled.div`
  width: 100%;
  .time {
    color: #8c8c8c;
    font-size: 12px;
  }
  .category-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }
  .category {
    color: #8c8c8c;
    font-size: 12px;
  }
`;


const StyledListItem = styled(List.Item)`
  &:hover {
    background-color: #fafafa;
    cursor: pointer;
  }
`;



const HeaderAdmin: React.FC = () => {
    const { token } = useToken();
    const location = useLocation();
    const navigate = useNavigate();

    const UserName = styled(Text)`
        display: block;
        color: ${token['blue-6']};
    `;

    const UserRole = styled(Text)`
        display: block;
        color: ${token['colorTextSecondary']};
    `;

    const notifications = [
        {
            category: 'СО',
            title: '7 дней до окончания срока годности',
            substance: 'N-метилэфедрин',
            time: '14.10.24 в 10:00'
        },
        {
            category: 'Реактивы/Прекурсоры',
            title: '4 дней до окончания срока годности',
            substance: 'Бензальдегид',
            time: '14.10.24 в 10:00'
        },
        {
            category: 'Реактивы/Прекурсоры',
            title: '4 дней до окончания срока годности',
            substance: 'Бензальдегид',
            time: '14.10.24 в 10:00'
        },
        {
            category: 'Реактивы/Прекурсоры',
            title: '4 дней до окончания срока годности',
            substance: 'Бензальдегид',
            time: '14.10.24 в 10:00'
        },
    ];

    const notificationContent = (
        <NotificationContent>
            <NotificationHeader>
                <Space>
                    <Typography.Text strong style={{ fontSize: 20 }}>
                        Уведомления
                    </Typography.Text>
                    <Badge count={notifications.length} />
                </Space>
                <Typography.Link>
                    Посмотреть все
                </Typography.Link>
            </NotificationHeader>
            <List
                  dataSource={notifications}
                  renderItem={item => (
                      <StyledListItem style={{ padding: '8px 16px' }}>
                          <NotificationItem>
                              <div className="category-row">
                                  <span className="category">{item.category}</span>
                                  <span className="time">{item.time}</span>
                              </div>
                              <div>{item.title}</div>
                              <Typography.Text strong>{item.substance}</Typography.Text>
                          </NotificationItem>
                      </StyledListItem>
                  )}
              />
        </NotificationContent>
    );

    return (
        <StyledHeader>
        <Logo>
            <LogoImage alt="" src={logo} />
        </Logo>


        <RightSection>
            <Space>
            <UserInfo>
                <UserName>Продавец</UserName>
                {/* <UserRole>глобальный админ</UserRole> */}
            </UserInfo>
            <Button type="primary" shape="circle" style={{ width: "40px", height: "40px", backgroundColor: token['blue-6'] }}>
                Д
            </Button>
            <Popover 
                content={notificationContent}
                trigger="click"
                placement="bottomRight"
                overlayInnerStyle={{ padding: 0, marginTop: 32, borderRadius: 8 }}
            >
                <Badge count={notifications.length} size="small" style={{ 
                    borderRadius: '50%',
                    minWidth: '20px',
                    height: '20px',
                    padding: '0 6px',
                    fontSize: '12px',
                    lineHeight: '20px'
                }}>
                    <Button type="text" icon={<BellOutlined style={{ fontSize: '18px' }} />} />
                </Badge>
            </Popover>
            </Space>
        </RightSection>

        </StyledHeader>
    );
};

export default HeaderAdmin;