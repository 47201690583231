import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Table, Modal, Space, Typography, Select, DatePicker, Checkbox, Popover } from "antd";
import React, { useState } from "react";
import styled from 'styled-components';
import type { ColumnsType } from 'antd/es/table';
import type { TableProps } from 'antd';
import dayjs from 'dayjs';

const { Link } = Typography;

const StyledButton = styled(Button)`
  height: 40px;
  border-radius: 4px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTable = styled((props: TableProps<any>) => <Table {...props} />)`
  .ant-table-tbody > tr > td {
    height: 55px;
    padding: 8px;
  }
  
  .ant-table-body {
    height: 260px !important;
    overflow-y: auto !important;
  }
`;

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 14px;
  margin-top: 8px;
  display: block;
  text-decoration: underline !important;
  text-underline-offset: 2px;
`;

interface DataType {
    key: React.Key;
    number: number;
    name: string;
    manufacturer: string;
    purpose: string;
    indicator: string;
    certifiedValue: number;
    errorValue: number;
    additionalInfo: string;
    normativeDocument: string;
    expirationDate: dayjs.Dayjs;
    expirationNotification: string;
    releaseDate: dayjs.Dayjs;
    notes: string;
    isPrecursor: boolean;
}

interface ImportTableProps {
    onCancel: () => void;
    open: boolean;
    selectedReagents: any[];
    fileName?: string;
}

const standardTypes = [
    'ГСО 1234-56 Стандартный образец 1',
    'ОСО 7890-12 Стандартный образец 2',
    'СОП 3456-78 Стандартный образец 3',
].map(item => ({ label: item, value: item }));

const manufacturerOptions = [
    'ФГУП "ВНИИМ им. Д.И. Менделеева"',
    'ФГУП "УНИИМ"',
    'ООО "Химтест"',
].map(item => ({ label: item, value: item }));

const purposeOptions = [
    'Калибровка',
    'Поверка',
    'Контроль точности',
].map(item => ({ label: item, value: item }));

const indicatorOptions = [
    'Массовая доля железа',
    'Массовая доля углерода',
    'Массовая доля серы',
].map(item => ({ label: item, value: item }));

const notificationOptions = [
    'За месяц',
    'За 2 месяца',
    'За 3 месяца',
].map(item => ({ label: item, value: item }));

const ColumnMappingPopover = styled.div`
  width: 250px;
`;

const ColumnTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  
  .title-content {
    display: flex;
    flex-direction: column;
  }
  
  .edit-icon {
    color: #8c8c8c;
    font-size: 14px;
    margin-left: auto;
  }
  
  &:hover {
    color: #1890ff;
    
    .edit-icon {
      color: #1890ff;
    }
  }
`;

const ImportTable: React.FC<ImportTableProps> = ({ onCancel, open, fileName = "файл" }) => {
    const [dataSource, setDataSource] = useState<DataType[]>([
        {
            key: '1',
            number: 1,
            name: 'ГСО 1234-56 Стандартный образец 1',
            manufacturer: 'ФГУП "ВНИИМ им. Д.И. Менделеева"',
            purpose: 'Калибровка',
            indicator: 'Массовая доля железа',
            certifiedValue: 0,
            errorValue: 0,
            additionalInfo: '',
            normativeDocument: '',
            expirationDate: dayjs(),
            expirationNotification: 'За месяц',
            releaseDate: dayjs(),
            notes: '',
            isPrecursor: false
        }
    ]);
    const [columnMapping, setColumnMapping] = useState<Record<string, string>>({});

    const handleDelete = (key: React.Key) => {
        setDataSource(prev => prev.filter(item => item.key !== key));
    };

    const handleInputChange = (key: React.Key, field: string, value: any) => {
        setDataSource(prev => prev.map(item => {
            if (item.key === key) {
                return {
                    ...item,
                    [field]: value
                };
            }
            return item;
        }));
    };

    const handleColumnMappingChange = (columnKey: string, excelColumn: string) => {
        setColumnMapping(prev => ({
            ...prev,
            [columnKey]: excelColumn
        }));
    };

    const renderColumnTitle = (title: string, columnKey: string) => {
        const content = (
            <ColumnMappingPopover>
                <div style={{ marginBottom: 8 }}>
                    <div style={{ marginBottom: 4, color: 'rgba(0, 0, 0, 0.45)', fontSize: 12 }}>
                        Укажите название колонки из Excel
                    </div>
                    <Input
                        placeholder="Название колонки"
                        value={columnMapping[columnKey] || ''}
                        onChange={(e) => handleColumnMappingChange(columnKey, e.target.value)}
                        style={{ width: '100%' }}
                    />
                </div>
            </ColumnMappingPopover>
        );

        return (
            <Popover
                content={content}
                title="Сопоставление колонок"
                trigger="click"
                placement="bottom"
            >
                <ColumnTitle>
                    <div className="title-content">
                        <span>{title}</span>
                        {columnMapping[columnKey] && (
                            <div style={{ fontSize: 12, color: '#1890ff', marginTop: 4 }}>
                                {columnMapping[columnKey]}
                            </div>
                        )}
                    </div>
                    <EditOutlined className="edit-icon" />
                </ColumnTitle>
            </Popover>
        );
    };

    const columns: ColumnsType<DataType> = [
        {
            title: renderColumnTitle('№ п/п', 'number'),
            dataIndex: 'number',
            key: 'number',
            width: 100,
            fixed: 'left',
        },
        {
            title: renderColumnTitle('Наименование, тип, номер и категория СО', 'name'),
            dataIndex: 'name',
            key: 'name',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(newValue) => handleInputChange(record.key, 'name', newValue)}
                    options={standardTypes}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle('Изготовитель СО', 'manufacturer'),
            dataIndex: 'manufacturer',
            key: 'manufacturer',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(newValue) => handleInputChange(record.key, 'manufacturer', newValue)}
                    options={manufacturerOptions}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle('Назначение', 'purpose'),
            dataIndex: 'purpose',
            key: 'purpose',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(newValue) => handleInputChange(record.key, 'purpose', newValue)}
                    options={purposeOptions}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle('Показатель', 'indicator'),
            dataIndex: 'indicator',
            key: 'indicator',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(newValue) => handleInputChange(record.key, 'indicator', newValue)}
                    options={indicatorOptions}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle('Аттестов. значение м.д. %', 'certifiedValue'),
            dataIndex: 'certifiedValue',
            key: 'certifiedValue',
            width: 232,
            render: (value: number, record: DataType) => (
                <Input
                    type="number"
                    value={value}
                    onChange={(e) => handleInputChange(record.key, 'certifiedValue', Number(e.target.value))}
                />
            ),
        },
        {
            title: renderColumnTitle('Погрешность а.з. абс. %', 'errorValue'),
            dataIndex: 'errorValue',
            key: 'errorValue',
            width: 232,
            render: (value: number, record: DataType) => (
                <Input
                    type="number"
                    value={value}
                    onChange={(e) => handleInputChange(record.key, 'errorValue', Number(e.target.value))}
                />
            ),
        },
        {
            title: renderColumnTitle('Доп сведения', 'additionalInfo'),
            dataIndex: 'additionalInfo',
            key: 'additionalInfo',
            width: 232,
            render: (value: string, record: DataType) => (
                <Input
                    value={value}
                    onChange={(e) => handleInputChange(record.key, 'additionalInfo', e.target.value)}
                />
            ),
        },
        {
            title: renderColumnTitle('Нормативный документ', 'normativeDocument'),
            dataIndex: 'normativeDocument',
            key: 'normativeDocument',
            width: 232,
            render: (value: string, record: DataType) => (
                <Input
                    value={value}
                    onChange={(e) => handleInputChange(record.key, 'normativeDocument', e.target.value)}
                />
            ),
        },
        {
            title: renderColumnTitle('Срок годности экземпляра СО', 'expirationDate'),
            dataIndex: 'expirationDate',
            key: 'expirationDate',
            width: 232,
            render: (value: dayjs.Dayjs, record: DataType) => (
                <DatePicker
                    value={value}
                    onChange={(date) => handleInputChange(record.key, 'expirationDate', date)}
                    format="DD.MM.YYYY"
                    style={{ width: '100%' }}
                />
            ),
        },
        {
            title: renderColumnTitle('Уведомление об окончании срока годности', 'expirationNotification'),
            dataIndex: 'expirationNotification',
            key: 'expirationNotification',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(newValue) => handleInputChange(record.key, 'expirationNotification', newValue)}
                    options={notificationOptions}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle('Дата выпуска экземпляра СО', 'releaseDate'),
            dataIndex: 'releaseDate',
            key: 'releaseDate',
            width: 232,
            render: (value: dayjs.Dayjs, record: DataType) => (
                <DatePicker
                    value={value}
                    onChange={(date) => handleInputChange(record.key, 'releaseDate', date)}
                    format="DD.MM.YYYY"
                    style={{ width: '100%' }}
                />
            ),
        },
        {
            title: renderColumnTitle('Примечание', 'notes'),
            dataIndex: 'notes',
            key: 'notes',
            width: 232,
            render: (value: string, record: DataType) => (
                <Input
                    value={value}
                    onChange={(e) => handleInputChange(record.key, 'notes', e.target.value)}
                />
            ),
        },
        {
            title: renderColumnTitle('Прекурсор', 'isPrecursor'),
            dataIndex: 'isPrecursor',
            key: 'isPrecursor',
            width: 232,
            render: (value: boolean, record: DataType) => (
                <Checkbox
                    checked={value}
                    onChange={(e) => handleInputChange(record.key, 'isPrecursor', e.target.checked)}
                />
            ),
        },
        {
            key: "action",
            width: 48,
            fixed: 'right',
            render: (_: unknown, record: DataType) => (
                <StyledButton 
                    type="text" 
                    icon={<DeleteOutlined style={{ color: '#f5222d' }} />}
                    style={{ width: '32px', padding: 0 }}
                    onClick={() => handleDelete(record.key)}
                />
            ),
        },
    ];

    return (
        <Modal
            title={<>Импорт данных из <span style={{ color: '#1890ff' }}>{fileName}</span></>}
            open={open}
            onCancel={onCancel}
            width={1200}
            footer={null}
            closeIcon={<CloseOutlined style={{ color: '#8C8C8C' }} />}
            centered
        >
            <StyledLink href="#" target="_blank">Нужна помощь с импортом?</StyledLink>
            <StyledTable
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                bordered
                style={{ marginTop: 16 }}
                scroll={{ x: 'max-content', y: 300 }}
            />
            <ButtonsRow justify="space-between" style={{ marginTop: 16 }}>
                <Space size={8}>
                    <StyledButton onClick={onCancel}>Отмена</StyledButton>
                </Space>
                <Space size={8}>
                    <StyledButton 
                        type="primary"
                        onClick={onCancel}
                    >
                        Импортировать
                    </StyledButton>
                </Space>
            </ButtonsRow>
        </Modal>
    );
};

export default ImportTable;
