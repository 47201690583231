import { CloseOutlined, DeleteOutlined, FileOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Table, Modal, Space, Typography, Select, DatePicker, Checkbox, Popover } from "antd";
import React, { useState } from "react";
import styled from 'styled-components';
import type { ColumnsType } from 'antd/es/table';
import type { TableProps } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';

const { Link } = Typography;

const StyledButton = styled(Button)`
  height: 40px;
  border-radius: 4px;
`;

const ButtonsRow = styled(Row)`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTable = styled((props: TableProps<any>) => <Table {...props} />)`
  .ant-table-tbody > tr > td {
    height: 55px;
    padding: 8px;
  }
  
  .ant-table-body {
    height: 260px !important;
    overflow-y: auto !important;
  }
`;

const StyledLink = styled(Link)`
  color: rgba(0, 0, 0, 0.45) !important;
  font-size: 14px;
  margin-top: 8px;
  display: block;
  text-decoration: underline !important;
  text-underline-offset: 2px;
`;

interface DataType {
    key: React.Key;
    number: number;
    receipt: {
        date: dayjs.Dayjs;
        name: string;
        batch: string;
        manufacturer: string;
        supplier: string;
        frequencyClass: string;
        expiryDate: dayjs.Dayjs;
        expiryNotification: string;
        containerSize: number;
        containerUnit: string;
        containerCount: number;
        documents: boolean;
        admission: boolean;
    };
    storage: {
        isPrecursor: boolean;
        location: string;
        conditions: string;
    };
    accounting: {
        openedShelfLife: string;
        used: number;
        remaining: number;
        notes: string;
    };
}

interface ImportTableProps {
    onCancel: () => void;
    open: boolean;
    selectedReagents: any[];
    fileName?: string;
}

const manufacturerOptions = [
    'Производитель 1',
    'Производитель 2',
    'Производитель 3',
    'Другой'
].map(item => ({ label: item, value: item }));

const supplierOptions = [
    'Поставщик 1',
    'Поставщик 2',
    'Поставщик 3',
    'Другой'
].map(item => ({ label: item, value: item }));

const frequencyClassOptions = [
    'A',
    'B',
    'C',
    'D'
].map(item => ({ label: item, value: item }));

const notificationOptions = [
    'За 30 дней',
    'За 60 дней',
    'За 90 дней',
    'Другое'
].map(item => ({ label: item, value: item }));

const containerUnitOptions = [
    'мл',
    'л',
    'кг',
    'г',
    'Другое'
].map(item => ({ label: item, value: item }));

const locationOptions = [
    'Склад A',
    'Склад B',
    'Склад C',
    'Другое'
].map(item => ({ label: item, value: item }));

const ColumnMappingPopover = styled.div`
  width: 250px;
`;

const ColumnTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
  
  .title-content {
    display: flex;
    flex-direction: column;
  }
  
  .edit-icon {
    color: #8c8c8c;
    font-size: 14px;
    margin-left: auto;
  }
  
  &:hover {
    color: #1890ff;
    
    .edit-icon {
      color: #1890ff;
    }
  }
`;

const ImportTable: React.FC<ImportTableProps> = ({ onCancel, open, fileName = "файл" }) => {
    const [dataSource, setDataSource] = useState<DataType[]>([
        {
            key: '1',
            number: 1,
            receipt: {
                date: dayjs('2024-03-20'),
                name: 'Тестовый реагент',
                batch: 'ABC123',
                manufacturer: 'Производитель 1',
                supplier: 'Поставщик 1',
                frequencyClass: 'A',
                expiryDate: dayjs('2025-03-20'),
                expiryNotification: 'За 30 дней',
                containerSize: 100,
                containerUnit: 'мл',
                containerCount: 1,
                documents: true,
                admission: true
            },
            storage: {
                isPrecursor: false,
                location: 'Склад A',
                conditions: 'Нормальные'
            },
            accounting: {
                openedShelfLife: '6 месяцев',
                used: 0,
                remaining: 100,
                notes: 'Примечание'
            }
        }
    ]);
    const [columnMapping, setColumnMapping] = useState<Record<string, string>>({});

    const handleDelete = (key: React.Key) => {
        setDataSource(prev => prev.filter(item => item.key !== key));
    };

    const handleInputChange = (key: React.Key, field: string[], value: any) => {
        setDataSource(prev => prev.map(item => {
            if (item.key === key) {
                const newItem = { ...item };
                let current: any = newItem;
                for (let i = 0; i < field.length - 1; i++) {
                    current = current[field[i]];
                }
                current[field[field.length - 1]] = value;
                return newItem;
            }
            return item;
        }));
    };

    const handleColumnMappingChange = (columnKey: string, excelColumn: string) => {
        setColumnMapping(prev => ({
            ...prev,
            [columnKey]: excelColumn
        }));
    };

    const renderColumnTitle = (title: string, columnKey: string) => {
        const content = (
            <ColumnMappingPopover>
                <div style={{ marginBottom: 8 }}>
                    <div style={{ marginBottom: 4, color: 'rgba(0, 0, 0, 0.45)', fontSize: 12 }}>
                        Укажите название колонки из Excel
                    </div>
                    <Input
                        placeholder="Название колонки"
                        value={columnMapping[columnKey] || ''}
                        onChange={(e) => handleColumnMappingChange(columnKey, e.target.value)}
                        style={{ width: '100%' }}
                    />
                </div>
            </ColumnMappingPopover>
        );

        return (
            <Popover
                content={content}
                title="Сопоставление колонок"
                trigger="click"
                placement="bottom"
            >
                <ColumnTitle>
                    <div className="title-content">
                        <span>{title}</span>
                        {columnMapping[columnKey] && (
                            <div style={{ fontSize: 12, color: '#1890ff', marginTop: 4 }}>
                                {columnMapping[columnKey]}
                            </div>
                        )}
                    </div>
                    <EditOutlined className="edit-icon" />
                </ColumnTitle>
            </Popover>
        );
    };

    const columns: ColumnsType<DataType> = [
        {
            title: renderColumnTitle("№ п/п", "number"),
            dataIndex: 'number',
            key: 'number',
            width: 100,
        },
        {
            title: renderColumnTitle("Дата поступления", "receipt.date"),
            dataIndex: ['receipt', 'date'],
            key: 'date',
            width: 232,
            render: (value: dayjs.Dayjs, record: DataType) => (
                <DatePicker
                    value={value}
                    onChange={(date) => handleInputChange(record.key, ['receipt', 'date'], date)}
                    format="DD.MM.YYYY"
                    style={{ width: '100%' }}
                />
            ),
        },
        {
            title: renderColumnTitle("Наименование", "receipt.name"),
            dataIndex: ['receipt', 'name'],
            key: 'name',
            width: 232,
            render: (value: string, record: DataType) => (
                <Input
                    value={value}
                    onChange={(e) => handleInputChange(record.key, ['receipt', 'name'], e.target.value)}
                />
            ),
        },
        {
            title: renderColumnTitle("№ партии/серии", "receipt.batch"),
            dataIndex: ['receipt', 'batch'],
            key: 'batch',
            width: 232,
            render: (value: string, record: DataType) => (
                <Input
                    value={value}
                    onChange={(e) => handleInputChange(record.key, ['receipt', 'batch'], e.target.value)}
                />
            ),
        },
        {
            title: renderColumnTitle("Производитель", "receipt.manufacturer"),
            dataIndex: ['receipt', 'manufacturer'],
            key: 'manufacturer',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(value) => handleInputChange(record.key, ['receipt', 'manufacturer'], value)}
                    options={manufacturerOptions}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle("Поставщик", "receipt.supplier"),
            dataIndex: ['receipt', 'supplier'],
            key: 'supplier',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(value) => handleInputChange(record.key, ['receipt', 'supplier'], value)}
                    options={supplierOptions}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle("Класс частоты", "receipt.frequencyClass"),
            dataIndex: ['receipt', 'frequencyClass'],
            key: 'frequencyClass',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(value) => handleInputChange(record.key, ['receipt', 'frequencyClass'], value)}
                    options={frequencyClassOptions}
                    style={{ width: '100%' }}
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle("Срок годности", "receipt.expiryDate"),
            dataIndex: ['receipt', 'expiryDate'],
            key: 'expiryDate',
            width: 232,
            render: (value: dayjs.Dayjs, record: DataType) => (
                <DatePicker
                    value={value}
                    onChange={(date) => handleInputChange(record.key, ['receipt', 'expiryDate'], date)}
                    format="DD.MM.YYYY"
                    style={{ width: '100%' }}
                />
            ),
        },
        {
            title: renderColumnTitle("Уведомление", "receipt.expiryNotification"),
            dataIndex: ['receipt', 'expiryNotification'],
            key: 'expiryNotification',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(value) => handleInputChange(record.key, ['receipt', 'expiryNotification'], value)}
                    options={notificationOptions}
                    style={{ width: '100%' }}
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle("Размер тары", "receipt.containerSize"),
            dataIndex: ['receipt', 'containerSize'],
            key: 'containerSize',
            width: 232,
            render: (value: number, record: DataType) => (
                <Input
                    type="number"
                    value={value}
                    onChange={(e) => handleInputChange(record.key, ['receipt', 'containerSize'], Number(e.target.value))}
                />
            ),
        },
        {
            title: renderColumnTitle("Ед. изм. тары", "receipt.containerUnit"),
            dataIndex: ['receipt', 'containerUnit'],
            key: 'containerUnit',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(value) => handleInputChange(record.key, ['receipt', 'containerUnit'], value)}
                    options={containerUnitOptions}
                    style={{ width: '100%' }}
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle("Кол-во тарных мест", "receipt.containerCount"),
            dataIndex: ['receipt', 'containerCount'],
            key: 'containerCount',
            width: 232,
            render: (value: number, record: DataType) => (
                <Input
                    type="number"
                    value={value}
                    onChange={(e) => handleInputChange(record.key, ['receipt', 'containerCount'], Number(e.target.value))}
                />
            ),
        },
        {
            title: renderColumnTitle("Документы получены", "receipt.documents"),
            dataIndex: ['receipt', 'documents'],
            key: 'documents',
            width: 232,
            render: (value: boolean, record: DataType) => (
                <Checkbox
                    checked={value}
                    onChange={(e) => handleInputChange(record.key, ['receipt', 'documents'], e.target.checked)}
                />
            ),
        },
        {
            title: renderColumnTitle("Допуск", "receipt.admission"),
            dataIndex: ['receipt', 'admission'],
            key: 'admission',
            width: 232,
            render: (value: boolean, record: DataType) => (
                <Checkbox
                    checked={value}
                    onChange={(e) => handleInputChange(record.key, ['receipt', 'admission'], e.target.checked)}
                />
            ),
        },
        {
            title: renderColumnTitle("Прекурсор", "storage.isPrecursor"),
            dataIndex: ['storage', 'isPrecursor'],
            key: 'isPrecursor',
            width: 232,
            render: (value: boolean, record: DataType) => (
                <Checkbox
                    checked={value}
                    onChange={(e) => handleInputChange(record.key, ['storage', 'isPrecursor'], e.target.checked)}
                />
            ),
        },
        {
            title: renderColumnTitle("Место хранения", "storage.location"),
            dataIndex: ['storage', 'location'],
            key: 'location',
            width: 232,
            render: (value: string, record: DataType) => (
                <Select
                    value={value}
                    onChange={(value) => handleInputChange(record.key, ['storage', 'location'], value)}
                    options={locationOptions}
                    style={{ width: '100%' }}
                    showSearch
                    allowClear
                />
            ),
        },
        {
            title: renderColumnTitle("Условия хранения", "storage.conditions"),
            dataIndex: ['storage', 'conditions'],
            key: 'conditions',
            width: 232,
            render: (value: string, record: DataType) => (
                <Input
                    value={value}
                    onChange={(e) => handleInputChange(record.key, ['storage', 'conditions'], e.target.value)}
                />
            ),
        },
        {
            title: renderColumnTitle("Срок после вскрытия", "accounting.openedShelfLife"),
            dataIndex: ['accounting', 'openedShelfLife'],
            key: 'openedShelfLife',
            width: 232,
            render: (value: string, record: DataType) => (
                <Input
                    value={value}
                    onChange={(e) => handleInputChange(record.key, ['accounting', 'openedShelfLife'], e.target.value)}
                />
            ),
        },
        {
            title: renderColumnTitle("Использовано", "accounting.used"),
            dataIndex: ['accounting', 'used'],
            key: 'used',
            width: 232,
            render: (value: number, record: DataType) => (
                <Input
                    type="number"
                    value={value}
                    onChange={(e) => handleInputChange(record.key, ['accounting', 'used'], Number(e.target.value))}
                />
            ),
        },
        {
            title: renderColumnTitle("Остаток", "accounting.remaining"),
            dataIndex: ['accounting', 'remaining'],
            key: 'remaining',
            width: 232,
            render: (value: number, record: DataType) => (
                <Input
                    type="number"
                    value={value}
                    onChange={(e) => handleInputChange(record.key, ['accounting', 'remaining'], Number(e.target.value))}
                />
            ),
        },
        {
            title: renderColumnTitle("Примечание", "accounting.notes"),
            dataIndex: ['accounting', 'notes'],
            key: 'notes',
            width: 232,
            render: (value: string, record: DataType) => (
                <Input
                    value={value}
                    onChange={(e) => handleInputChange(record.key, ['accounting', 'notes'], e.target.value)}
                />
            ),
        },
        {
            key: "action",
            width: 48,
            fixed: 'right',
            render: (_: unknown, record: DataType) => (
                <StyledButton 
                    type="text" 
                    icon={<DeleteOutlined style={{ color: '#f5222d' }} />}
                    style={{ width: '32px', padding: 0 }}
                    onClick={() => handleDelete(record.key)}
                />
            ),
        },
    ];

    return (
        <Modal
            title={<>Импорт данных из <span style={{ color: '#1890ff' }}>{fileName}</span></>}
            open={open}
            onCancel={onCancel}
            width={1200}
            footer={null}
            closeIcon={<CloseOutlined style={{ color: '#8C8C8C' }} />}
            centered
        >
            <StyledLink href="#" target="_blank">Нужна помощь с импортом?</StyledLink>
            <StyledTable
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                bordered
                style={{ marginTop: 16 }}
                scroll={{ x: 'max-content', y: 300 }}
            />
            <ButtonsRow justify="space-between" style={{ marginTop: 16 }}>
                <Space size={8}>
                    <StyledButton onClick={onCancel}>Отмена</StyledButton>
                </Space>
                <Space size={8}>
                    <StyledButton 
                        type="primary"
                        onClick={onCancel}
                    >
                        Импортировать
                    </StyledButton>
                </Space>
            </ButtonsRow>
        </Modal>
    );
};

export default ImportTable;
