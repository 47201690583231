import { axiosInstance } from './instance';

export interface AttestationValue {
    attestation_value: number;
    attestation_error: number;
}

export interface PackagingData {
    packaging_date: string;
    container_volume: number;
    nomenclature: string;
    visual_control: string;
    status: string;
    attestation_values?: AttestationValue[];
    intermediate_control_number?: string;
    measured_value?: number;
}

export interface Packaging extends PackagingData {
    id: number;
    batch_id: number;
}

export const createPackaging = async (batchId: number, data: PackagingData) => {
    const response = await axiosInstance.post(`/api/batches/${batchId}/packaging`, data);
    return response.data;
};

export const getBatchPackagings = async (batchId: number): Promise<Packaging[]> => {
    const response = await axiosInstance.get(`/api/batches/${batchId}/packaging`);
    return response.data;
};

export const completePackaging = async (batchId: number) => {
    const response = await axiosInstance.post(`/api/batches/${batchId}/packaging/complete`);
    return response.data;
};

export const deletePackaging = async (batchId: number, packagingId: number) => {
    const response = await axiosInstance.delete(`/api/batches/${batchId}/packagings/${packagingId}`);
    return response.data;
};

export const generateLabel = async (batchId: number, packagingId: number): Promise<Blob> => {
    const response = await axiosInstance.get(`/api/batches/${batchId}/packagings/${packagingId}/label`, {
        responseType: 'blob',
        headers: {
            Accept: 'application/zip'
        }
    });
    
    console.log('Response Content-Type:', response.headers['content-type']);
    
    if (response.headers['content-type'] !== 'application/zip') {
        console.warn('Unexpected content type:', response.headers['content-type']);
    }
    
    return response.data;
}; 