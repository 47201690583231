import React from 'react';
import { Row, Col, Input, Tag, Button } from 'antd';
import { EditOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { LaboratoryData } from '../../../api/laboratory';

const FormSection = styled.div`
    background: white;
    padding: 24px;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
    margin-bottom: 24px;
    border: 1px solid #e0e0e0;

    h2 {
        margin: 24px 0 16px 0;
        color: ${props => props.theme.token?.colorText};
        &:first-child {
            margin-top: 0;
        }
    }

    .ant-input {
        height: 40px;
        border-radius: 4px;
        background-color: #fAfAfA !important;

        &:hover, &:focus {
            background-color: #fAfAfA !important;
        }
    }

    .ant-input-affix-wrapper {
        background-color: #fAfAfA !important;
        padding: 0 11px !important;

        &:hover, &:focus, &-focused {
            background-color: #fAfAfA !important;
        }

        .ant-input {
            background-color: #fAfAfA !important;
        }
    }

    .ant-tag {
        border-radius: 4px;
        
        &.new-tag {
            background: white;
            border-style: dashed;
        }
    }

    .ant-input-suffix .anticon {
        color: ${props => props.theme.token?.['blue-6']} !important;
    }
`;

const StyledLabel = styled.label`
    display: block;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    font-weight: 600;
    padding: 0 0 4px !important;
`;

interface LaboratoryDataFormProps {
    laboratoryData: LaboratoryData;
    loading: boolean;
    newTagInput: string;
    token: any;
    onInputChange: (field: keyof LaboratoryData, value: string) => void;
    onAddTag: (tag: string) => void;
    onRemoveTag: (tag: string) => void;
    onSave: () => void;
    setNewTagInput: (value: string) => void;
}

const LaboratoryDataForm: React.FC<LaboratoryDataFormProps> = ({
    laboratoryData,
    loading,
    newTagInput,
    token,
    onInputChange,
    onAddTag,
    onRemoveTag,
    onSave,
    setNewTagInput
}) => {
    return (
        <Row gutter={[24, 0]}>
            <Col span={24}>
                <FormSection>
                    <Row gutter={[24, 0]}>
                        <Col span={12}>
                            <h2>Данные лаборатории</h2>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <StyledLabel>Юр.название компании</StyledLabel>
                                    <Input 
                                        value={laboratoryData.name} 
                                        onChange={(e) => onInputChange('name', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <StyledLabel>Номер телефона</StyledLabel>
                                    <Input 
                                        value={laboratoryData.phone} 
                                        onChange={(e) => onInputChange('phone', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <StyledLabel>E-mail</StyledLabel>
                                    <Input 
                                        value={laboratoryData.email} 
                                        onChange={(e) => onInputChange('email', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <StyledLabel>Юр.адрес</StyledLabel>
                                    <Input 
                                        value={laboratoryData.legal_address} 
                                        onChange={(e) => onInputChange('legal_address', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <StyledLabel>Факт.адрес</StyledLabel>
                                    <Input 
                                        value={laboratoryData.actual_address} 
                                        onChange={(e) => onInputChange('actual_address', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <StyledLabel>Адрес доставки</StyledLabel>
                                    <Input 
                                        value={laboratoryData.delivery_address} 
                                        onChange={(e) => onInputChange('delivery_address', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                            </Row>
                            <h2>Тег</h2>
                            <div style={{ marginBottom: 16 }}>
                                {laboratoryData.tags.map((tag: string) => (
                                    <Tag 
                                        key={tag} 
                                        closable 
                                        closeIcon={<CloseOutlined />} 
                                        onClose={() => onRemoveTag(tag)}
                                    >
                                        {tag}
                                    </Tag>
                                ))}
                                <Input
                                    type="text"
                                    size="small"
                                    style={{ width: 100, marginRight: 8, height: 22 }}
                                    value={newTagInput}
                                    onChange={(e) => setNewTagInput(e.target.value)}
                                    onPressEnter={() => {
                                        if (newTagInput) {
                                            onAddTag(newTagInput);
                                        }
                                    }}
                                    placeholder="Новый тег"
                                />
                                <Tag 
                                    className="new-tag" 
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        if (newTagInput) {
                                            onAddTag(newTagInput);
                                        }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Добавить тег
                                </Tag>
                            </div>
                        </Col>
                        <Col span={12}>
                            <h2>Платежные реквизиты</h2>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <StyledLabel>Название банка</StyledLabel>
                                    <Input 
                                        value={laboratoryData.bank_name} 
                                        onChange={(e) => onInputChange('bank_name', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <StyledLabel>Расчет.счет</StyledLabel>
                                    <Input 
                                        value={laboratoryData.account_number} 
                                        onChange={(e) => onInputChange('account_number', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <StyledLabel>Кор.счет</StyledLabel>
                                    <Input 
                                        value={laboratoryData.correspondent_account} 
                                        onChange={(e) => onInputChange('correspondent_account', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <StyledLabel>ИНН</StyledLabel>
                                    <Input 
                                        value={laboratoryData.inn} 
                                        onChange={(e) => onInputChange('inn', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <StyledLabel>БИК</StyledLabel>
                                    <Input 
                                        value={laboratoryData.bik} 
                                        onChange={(e) => onInputChange('bik', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <StyledLabel>КПП</StyledLabel>
                                    <Input 
                                        value={laboratoryData.kpp} 
                                        onChange={(e) => onInputChange('kpp', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <StyledLabel>ОГРН</StyledLabel>
                                    <Input 
                                        value={laboratoryData.ogrn} 
                                        onChange={(e) => onInputChange('ogrn', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 16 }}>
                                <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button 
                                        type="primary" 
                                        onClick={onSave} 
                                        loading={loading}
                                        style={{ height: 40, width: 300 }}
                                    >
                                        Сохранить
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </FormSection>
            </Col>
        </Row>
    );
};

export default LaboratoryDataForm; 