import { FC } from 'react';
import { Modal, Form, Input, Button, Typography, Space, Select, DatePicker, message } from 'antd';
import styled from 'styled-components';
import { CalendarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { createBatch } from '../../api/batches';

const StyledForm = styled(Form)`
    .ant-form-item {
        margin-bottom: 14px !important;
    }

    .ant-form-item-label {
        padding-bottom: 0px !important;
        
        > label {
            color: #8C8C8C !important;
            font-size: 12px !important;
            font-weight: 500 !important;
            height: 20px !important;
        }
    }

    .ant-input, .ant-select-selector, .ant-picker {
        height: 40px !important;
        background-color: #F5F5F5 !important;
        border-radius: 4px !important;
    }

    .ant-select {
        &.ant-select-single {
            height: 40px;
        }
        .ant-select-selector {
            display: flex;
            align-items: center;
            height: 40px !important;
            padding: 0 11px !important;
            .ant-select-selection-item {
                line-height: 40px !important;
            }
        }
        .ant-select-selection-placeholder {
            line-height: 40px !important;
        }
        .ant-select-arrow {
            inset-block-start: 50% !important;
            transform: translateY(-50%) !important;
        }
    }

    .ant-picker {
        width: 100%;
    }

    .ant-input-affix-wrapper {
        background-color: #F5F5F5 !important;
        padding: 0 11px !important;
        border-radius: 4px !important;

        &:hover, &:focus, &-focused {
            background-color: #F5F5F5 !important;
        }

        .ant-input {
            background-color: #F5F5F5 !important;
        }
    }
`;

interface AddBatchProps {
    open: boolean;
    onCancel: () => void;
    onSuccess: () => void;
}

const AddBatch: FC<AddBatchProps> = ({ open, onCancel, onSuccess }) => {
    const [form] = Form.useForm();

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            const batchData = {
                batch_number: values.batchIndex,
                batch_code: values.batchCode,
                registration_date: values.batchDate.format('YYYY-MM-DD'),
                target_value: parseFloat(values.targetValue),
                unit: values.unit,
                planned_volume: parseFloat(values.plannedVolume)
            };

            await createBatch(batchData);
            message.success('Партия успешно создана');
            form.resetFields();
            onSuccess();
            onCancel();
        } catch (error: any) {
            console.error('Failed:', error);
            message.error(error.response?.data?.detail || 'Ошибка при создании партии');
        }
    };

    return (
        <Modal
            title="Данные партии"
            open={open}
            onCancel={onCancel}
            footer={null}
            width={400}
            centered
        >
            <Space direction="vertical" size={16} style={{ width: '100%', paddingTop: '16px' }}>
                <StyledForm 
                    form={form} 
                    layout="vertical"
                    initialValues={{
                        batchDate: dayjs()
                    }}
                >
                    <Form.Item
                        label="Дата регистрации"
                        name="batchDate"
                        required={false}
                        rules={[{ required: true, message: 'Введите дату регистрации' }]}
                    >
                        <DatePicker 
                            format="DD.MM.YYYY"
                            placeholder="Выберите дату"
                            suffixIcon={<CalendarOutlined style={{ color: '#1890ff' }} />}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Индекс образца"
                        name="batchIndex"
                        required={false}
                        rules={[{ required: true, message: 'Введите индекс образца' }]}
                    >
                        <Input placeholder="Введите данные" />
                    </Form.Item>

                    <Form.Item
                        label="Целевое значение"
                        name="targetValue"
                        required={false}
                        rules={[{ required: true, message: 'Введите целевое значение' }]}
                    >
                        <Input placeholder="Введите данные" />
                    </Form.Item>

                    <Form.Item
                        label="Ед. измерения"
                        name="unit"
                        required={false}
                        rules={[{ required: true, message: 'Выберите единицу измерения' }]}
                    >
                        <Select
                            placeholder="Выберите единицу измерения"
                            suffixIcon={null}
                            options={[
                                { value: 'mg', label: 'мг' },
                                { value: 'ml', label: 'мл' },
                                { value: 'g', label: 'г' },
                                { value: 'kg', label: 'кг' },
                                { value: 'l', label: 'л' }
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Плановый объем"
                        name="plannedVolume"
                        required={false}
                        rules={[{ required: true, message: 'Введите плановый объем' }]}
                    >
                        <Input placeholder="Введите данные" />
                    </Form.Item>

                    <Form.Item
                        label="Шифр партии"
                        name="batchCode"
                        required={false}
                        rules={[{ required: true, message: 'Введите шифр партии' }]}
                    >
                        <Input placeholder="Введите данные" />
                    </Form.Item>

                    <div style={{ display: 'flex', gap: '12px', marginTop: '24px' }}>
                        <Button type="primary" onClick={handleOk} style={{ flex: 1, height: 40 }}>
                            Создать партию
                        </Button>
                    </div>
                </StyledForm>
            </Space>
        </Modal>
    );
};

export default AddBatch;
