import { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const StyledButton = styled(Button)`
    width: 100%;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.10);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border: none;
    opacity: 0.45;
    border: 1px solid rgba(0, 0, 0, 0);
    
    &:hover {
        background-color: #E8E8E8 !important;
        border: 1px solid #E8E8E8;
        opacity: 1;
    }
`;

const ButtonAddQC: FC = () => {
    return (
        <StyledButton>
            <span>Добавить фасовку</span>
            <PlusOutlined/>
        </StyledButton>
    );
};

export default ButtonAddQC;
