import { FC, useEffect, useState } from 'react';
import { Modal, Form, Button, Typography, Space, Collapse, theme, message } from 'antd';
import styled from 'styled-components';
import { DownOutlined, DeleteOutlined, PlusOutlined, DownloadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { BatchData } from './Table';
import DataBatch from './DataBatch';
import { deleteBatch } from '../../api/batches';
import { createQualityControl, QualityControlData } from '../../api/quality-control';
import MethodREPP from './MethodREPP';
import MethodMeasuring from './MethodMeasuring';
import MethodMLE from './MethodMLE';
import PackREPP from './PackREPP';
import PackMLE from './PackMLE';
import PackMeasuring from './PackMeasuring';
import { FormInstance } from 'antd/lib/form';
import { createPackaging, completePackaging, getBatchPackagings, deletePackaging, PackagingData, AttestationValue, generateLabel } from '../../api/packaging';

const { Panel } = Collapse;
const { useToken } = theme;

const StyledModal = styled(Modal)`
    .ant-modal-content {
        background: #FAFAFA;
    }

    .ant-modal-header {
        background: #FAFAFA !important;
    }
`;

const StyledCollapse = styled(Collapse)`
    background: white;
    margin-bottom: 0px;
    padding: 0px 12px;
    border-radius: 4px;
    
    .ant-collapse-header {
        padding: 16px 0px 16px 24px !important;
        border-bottom: none !important;
        align-items: flex-start !important;
        font-size: 14px !important;
        position: relative !important;
    }

    .ant-collapse-expand-icon {
        position: absolute !important;
        right: 0 !important;
        top: 16px !important;
        margin: 0 !important;
        padding-left: 16px !important;
        height: 22px !important;
    }

    .ant-collapse-header-text {
        order: 1;
        width: calc(100% - 40px);
        white-space: pre-wrap;
        word-wrap: break-word;
        line-height: 1.4;
    }

    .ant-collapse-content {
        background: white;
    }

    .ant-collapse-content-box {
        padding: 16px 0 0 0 !important;
    }
`;

const StatusLabel = styled(Typography.Text)`
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
    font-weight: 600;
`;

const StatusBlock = styled.div`
    background: white;
    padding: 9px 12px;
    border-radius: 4px;
    margin-bottom: 0px;
    font-size: 14px;
    border: 1px solid #E0E0E0;
    font-weight: 500;
`;

const StatusDot = styled.div<{ color: string }>`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${props => props.color};
    margin-right: 8px;
`;

const StatusContent = styled.div`
    display: flex;
    align-items: center;
`;

const AddPackagingButton = styled(Button)`
    width: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    height: 56px;
    color: rgba(0, 0, 0, 0.45);
    
    &:hover {
        background-color: #E8E8E8 !important;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    padding-left: -100px;
`;

const GenerateButton = styled(Button)`
    height: 67px;
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.02);
    width: calc(100% - 80px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    white-space: normal;
    line-height: 20px;
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
    }

    .anticon {
        margin-left: 8px;
        font-size: 20px;
        flex-shrink: 0;
    }

    span:not(.anticon) {
        text-align: left;
    }
`;

const DeletePackagingButton = styled(Button)`
    height: 67px;
    width: 47px;
    min-width: 47px !important;
    margin-right: 10px;
    background-color: rgba(0, 0, 0, 0.02);
    padding: 0;
    
    &.ant-btn {
        width: 47px;
        min-width: 47px;
    }
    
    &:hover {
        background-color: rgba(0, 0, 0, 0.04) !important;
    }

    .anticon {
        color: #ff4d4f;
        font-size: 20px;
    }
`;

const PanelHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    min-height: 22px;
`;

const QualityControlPanelHeader = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 20px;
    line-height: 1.4;
`;

interface AllDataBatchProps {
    open: boolean;
    onCancel: () => void;
    batch: BatchData | null;
    onSuccess?: () => void;
    onQualityControl?: () => void;
    selectedMethod?: string;
}

const getStatus = (batch: BatchData | null) => {
    if (!batch) return 'Не определен';
    if (batch.packaging_complete) return 'Расфасовано';
    if (batch.packaging_permission) return 'Допущено к фасовке';
    return 'Произведено';
};

const getMethodLabel = (method: string) => {
    switch (method) {
        case 'repp':
            return 'РЭПП';
        case 'measurement':
            return 'Методика измерения';
        case 'mle':
            return 'МЛЭ';
        default:
            return '';
    }
};

const getMethodComponent = (method: string, form: any, batch: BatchData | null) => {
    switch (method) {
        case 'repp':
            return <MethodREPP form={form} batch={batch} />;
        case 'measurement':
            return <MethodMeasuring form={form} batch={batch} />;
        case 'mle':
            return <MethodMLE form={form} batch={batch} />;
        default:
            return null;
    }
};

const getPackagingComponent = (method: string, form: any, batch: BatchData | null, existingData?: any) => {
    console.log('Метод:', method);
    console.log('Существующие данные для фасовки:', existingData);
    
    switch (method) {
        case 'mle':
            return <PackMLE form={form} batch={batch} existingData={existingData} />;
        case 'measurement':
            return <PackMeasuring form={form} batch={batch} existingData={existingData} />;
        default:
            return <PackREPP form={form} batch={batch} existingData={existingData} />;
    }
};

const AllDataBatch: FC<AllDataBatchProps> = ({ 
    open, 
    onCancel, 
    batch, 
    onSuccess,
    onQualityControl,
    selectedMethod 
}) => {
    const [form] = Form.useForm();
    const [packagingForms, setPackagingForms] = useState<FormInstance[]>([]);
    const { token } = useToken();
    const [loading, setLoading] = useState(false);
    const [showPackaging, setShowPackaging] = useState(false);
    const [existingPackagings, setExistingPackagings] = useState<any[]>([]);

    // Создаем пул форм при монтировании компонента
    const formPool = [
        Form.useForm()[0],
        Form.useForm()[0],
        Form.useForm()[0],
        Form.useForm()[0],
        Form.useForm()[0]
    ];

    useEffect(() => {
        console.log('Полные данные партии:', batch);
        console.log('Quality Control:', batch?.quality_control);
        console.log('Method:', batch?.quality_control?.method);
        if (batch) {
            // Очищаем формы фасовки при открытии нового окна
            setPackagingForms([]);
            setShowPackaging(false);
            
            form.setFieldsValue({
                batchDate: dayjs(batch.registration_date),
                batchIndex: batch.batch_number,
                targetValue: batch.target_value,
                unit: batch.unit,
                plannedVolume: batch.planned_volume,
                batchCode: batch.batch_code,
            });

            // Загружаем существующие фасовки
            const loadPackagings = async () => {
                try {
                    const packagings = await getBatchPackagings(batch.id);
                    setExistingPackagings(packagings);
                    
                    // Если есть фасовки, показываем секцию фасовки
                    if (packagings.length > 0) {
                        setShowPackaging(true);
                    }
                } catch (error) {
                    message.error('Ошибка при загрузке фасовок');
                }
            };

            loadPackagings();
        }
    }, [batch, form]);

    // Очищаем формы при закрытии модального окна
    const handleCancel = () => {
        setPackagingForms([]);
        setShowPackaging(false);
        onCancel();
    };

    const handleDelete = async () => {
        if (!batch) return;
        
        try {
            await deleteBatch(batch.id);
            message.success('Партия успешно удалена');
            onSuccess?.();
            onCancel();
        } catch (error) {
            message.error('Ошибка при удалении партии');
        }
    };

    const handleSaveQualityControl = async () => {
        if (!batch || !selectedMethod) return;
        
        try {
            setLoading(true);
            const values = await form.validateFields();
            
            let qualityControlData: QualityControlData = {
                method: selectedMethod,
                status: values.status
            };

            if (selectedMethod === 'repp') {
                qualityControlData = {
                    ...qualityControlData,
                    raw_materials: values.rawMaterials,
                    calculated_value: values.calculatedValue,
                    intermediate_control_number: values.intermediateControlNumber,
                    spc_requirement: values.spcRequirement,
                    spc_compliance: values.spcCompliance
                };
            } else if (selectedMethod === 'mle') {
                // Преобразуем данные МЛЭ в формат API
                const raw_materials = values.attestationValues?.map((av: any) => ({
                    name: 'АЗ',
                    certifiedValue: parseFloat(av.attestationValue) || 0,
                    weight: parseFloat(av.attestationValueError) || 0
                })) || [];

                qualityControlData = {
                    ...qualityControlData,
                    raw_materials,
                    intermediate_control_number: values.numberMO,
                    spc_requirement: values.spcValues?.[0]?.spcRequirement || '',
                    spc_compliance: values.spcValues?.[0]?.spcCompliance || ''
                };
            } else if (selectedMethod === 'measurement') {
                qualityControlData = {
                    ...qualityControlData,
                    raw_materials: values.rawMaterials,
                    intermediate_control_number: values.numberAO,
                    calculated_value: parseFloat(values.measuredValue) || 0,
                    spc_requirement: values.spcRequirement,
                    spc_compliance: values.spcCompliance
                };
            }
            
            await createQualityControl(batch.id, qualityControlData);
            
            message.success('Данные успешно сохранены');
            onSuccess?.();
            onCancel();
        } catch (error) {
            message.error('Ошибка при сохранении данных');
        } finally {
            setLoading(false);
        }
    };

    const handleStartPackaging = () => {
        setShowPackaging(true);
        setPackagingForms([formPool[0]]);
    };

    const handleFinishPackaging = async () => {
        if (!batch) return;

        try {
            // Валидируем и сохраняем все формы фасовки
            const values = await Promise.all(packagingForms.map(form => form.validateFields()));
            
            // Создаем записи о фасовке
            await Promise.all(values.map(value => {
                const packagingData: PackagingData = {
                    packaging_date: value.packagingDate.format('YYYY-MM-DD'),
                    container_volume: value.containerVolume,
                    nomenclature: value.nomenclature,
                    visual_control: value.visualControl,
                    status: value.status,
                };

                // Если метод МЛЭ, добавляем значения АЗ
                if (batch?.quality_control?.method === 'mle' && value.attestationValues) {
                    packagingData.attestation_values = value.attestationValues.map((av: { attestation_value: string; attestation_error: string }) => ({
                        attestation_value: parseFloat(av.attestation_value),
                        attestation_error: parseFloat(av.attestation_error)
                    }));
                } else if (batch?.quality_control?.method === 'measurement') {
                    console.log('Данные формы фасовки:', value);
                    packagingData.intermediate_control_number = value.intermediateControlNumber;
                    packagingData.measured_value = parseFloat(value.measuredValue) || 0;
                }

                console.log('Данные для сохранения фасовки:', packagingData);
                return createPackaging(batch.id, packagingData);
            }));

            // Завершаем фасовку
            await completePackaging(batch.id);
            
            message.success('Фасовка завершена, паспорт создан');
            onSuccess?.();
            onCancel();
        } catch (error) {
            message.error('Ошибка при сохранении данных фасовки');
        }
    };

    const handleAddPackaging = () => {
        if (packagingForms.length < formPool.length) {
            setPackagingForms(prev => [...prev, formPool[prev.length]]);
        } else {
            message.warning('Достигнуто максимальное количество фасовок');
        }
    };

    const handleGenerateQR = async (packagingId: number, e: React.MouseEvent) => {
        // Предотвращаем раскрытие/сворачивание панели при клике на кнопку
        e.stopPropagation();
        if (!batch) return;
        
        try {
            const response = await generateLabel(batch.id, packagingId);
            console.log('Content-Type:', response.type); // Для отладки
            
            // Создаем ссылку для скачивания
            const url = window.URL.createObjectURL(response);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `package_${batch.batch_number}_${packagingId}.zip`);
            
            // Эмулируем клик для скачивания
            document.body.appendChild(link);
            link.click();
            
            // Очищаем
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(url);
            
            message.success('Файлы успешно сгенерированы');
        } catch (error) {
            console.error('Ошибка при генерации файлов:', error);
            message.error('Ошибка при генерации файлов');
        }
    };

    const handleDeletePackaging = async (packagingId: number, e: React.MouseEvent) => {
        e.stopPropagation();
        
        if (!batch) return;

        Modal.confirm({
            title: 'Удаление фасовки',
            icon: <ExclamationCircleOutlined />,
            content: 'Вы уверены, что хотите удалить эту фасовку?',
            okText: 'Удалить',
            cancelText: 'Отмена',
            okButtonProps: { danger: true },
            async onOk() {
                try {
                    await deletePackaging(batch.id, packagingId);
                    
                    // Обновляем список фасовок
                    const packagings = await getBatchPackagings(batch.id);
                    setExistingPackagings(packagings);
                    
                    message.success('Фасовка успешно удалена');
                } catch (error) {
                    message.error('Ошибка при удалении фасовки');
                }
            }
        });
    };

    return (
        <StyledModal
            title="Данные партии"
            open={open}
            onCancel={handleCancel}
            footer={null}
            width={400}
            centered
        >
            <Space direction="vertical" size={16} style={{ width: '100%', paddingTop: '8px' }}>
                <div>
                    <StatusLabel>Статус</StatusLabel>
                    <StatusBlock>
                        <StatusContent>
                            <StatusDot color={token['blue-3']} />
                            {getStatus(batch)}
                        </StatusContent>
                    </StatusBlock>
                </div>
                
                <StyledCollapse 
                    defaultActiveKey={[]} 
                    expandIcon={({ isActive }) => (
                        <DownOutlined rotate={isActive ? 180 : 0} style={{ fontSize: '12px' }} />
                    )}
                >
                    <Panel header="Данные партии" key="1">
                        <DataBatch form={form} />
                    </Panel>
                </StyledCollapse>

                {(selectedMethod || batch?.packaging_permission) && (
                    <StyledCollapse 
                        defaultActiveKey={[]} 
                        expandIcon={({ isActive }) => (
                            <DownOutlined rotate={isActive ? 180 : 0} style={{ fontSize: '12px' }} />
                        )}
                    >
                        <Panel 
                            header={
                                <QualityControlPanelHeader>
                                    {`Контроль качества (${getMethodLabel(selectedMethod || batch?.quality_control?.method || 'repp')})`}
                                </QualityControlPanelHeader>
                            } 
                            key="1"
                        >
                            {getMethodComponent(selectedMethod || batch?.quality_control?.method || 'repp', form, batch)}
                        </Panel>
                    </StyledCollapse>
                )}

                {existingPackagings.map((packaging, index) => (
                    <StyledCollapse 
                        key={`existing-${index}`}
                        defaultActiveKey={[]} 
                        expandIcon={({ isActive }) => (
                            <DownOutlined rotate={isActive ? 180 : 0} style={{ fontSize: '12px' }} />
                        )}
                    >
                        <Panel 
                            key="1"
                            header={
                                <PanelHeader>
                                    <HeaderTitle>
                                        <span>Фасовка {index + 1}</span>
                                    </HeaderTitle>
                                    {batch?.packaging_complete && (
                                        <ButtonsContainer>
                                            <DeletePackagingButton
                                                icon={<DeleteOutlined />}
                                                onClick={(e) => handleDeletePackaging(packaging.id, e)}
                                            />
                                            <GenerateButton
                                                icon={<DownloadOutlined />}
                                                onClick={(e) => handleGenerateQR(packaging.id, e)}
                                            >
                                                Этикетка и паспорт с QR
                                            </GenerateButton>
                                        </ButtonsContainer>
                                    )}
                                </PanelHeader>
                            }
                        >
                            {getPackagingComponent(batch?.quality_control?.method || 'repp', formPool[index], batch, packaging)}
                        </Panel>
                    </StyledCollapse>
                ))}

                {showPackaging && packagingForms.map((packagingForm, index: number) => (
                    <StyledCollapse 
                        key={`new-${index}`}
                        defaultActiveKey={[]} 
                        expandIcon={({ isActive }) => (
                            <DownOutlined rotate={isActive ? 180 : 0} style={{ fontSize: '12px' }} />
                        )}
                    >
                        <Panel header={`Фасовка ${existingPackagings.length + index + 1}`} key="1">
                            {getPackagingComponent(batch?.quality_control?.method || 'repp', packagingForm, batch)}
                        </Panel>
                    </StyledCollapse>
                ))}

                {showPackaging && !batch?.packaging_complete && batch?.packaging_permission && (
                    <AddPackagingButton
                        type="text"
                        onClick={handleAddPackaging}
                        icon={<PlusOutlined />}
                    >
                        Добавить еще фасовку
                    </AddPackagingButton>
                )}

                <div style={{ display: 'flex', gap: '12px', marginTop: '0px' }}>
                    {batch?.packaging_complete ? (
                        <Button 
                            icon={<DeleteOutlined style={{ color: token.red6 }} />} 
                            style={{ height: 40, width: '100%' }}
                            onClick={handleDelete}
                        >
                            Удалить партию
                        </Button>
                    ) : (
                        <Button 
                            icon={<DeleteOutlined style={{ color: token.red6 }} />} 
                            style={{ height: 58, width: 46 }}
                            onClick={handleDelete}
                        />
                    )}
                    {!selectedMethod && !batch?.packaging_permission && (
                        <Button 
                            type="primary" 
                            style={{ 
                                flex: 1, 
                                whiteSpace: 'normal',
                                minHeight: 58,
                                padding: '0px 50px',
                                lineHeight: '20px'
                            }}
                            onClick={onQualityControl}
                        >
                            Перейти к контролю качества партии
                        </Button>
                    )}
                    {selectedMethod && !batch?.packaging_permission && (
                        <Button 
                            type="primary" 
                            style={{ 
                                flex: 1, 
                                whiteSpace: 'normal',
                                minHeight: 58,
                                padding: '0px 50px',
                                lineHeight: '20px'
                            }}
                            onClick={handleSaveQualityControl}
                            loading={loading}
                        >
                            Допустить к фасовке
                        </Button>
                    )}
                    {batch?.packaging_permission && !showPackaging && !batch?.packaging_complete && (
                        <Button 
                            type="primary" 
                            style={{ 
                                flex: 1, 
                                whiteSpace: 'normal',
                                minHeight: 58,
                                padding: '0px 50px',
                                lineHeight: '20px'
                            }}
                            onClick={handleStartPackaging}
                        >
                            Расфасовать
                        </Button>
                    )}
                    {showPackaging && !batch?.packaging_complete && batch?.packaging_permission && (
                        <Button 
                            type="primary" 
                            style={{ 
                                flex: 1, 
                                whiteSpace: 'normal',
                                minHeight: 58,
                                padding: '0px 50px',
                                lineHeight: '20px'
                            }}
                            onClick={handleFinishPackaging}
                        >
                            Завершить фасовку и создать паспорт
                        </Button>
                    )}
                </div>
            </Space>
        </StyledModal>
    );
};

export default AllDataBatch;
