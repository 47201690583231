import { FC } from 'react';
import styled from "styled-components";
import { Table, Typography, Space } from 'antd';
import ButtonAddQC from './ButtonAddQC';
import ButtonAddPack from './ButtonAddPack';
import ButtonApproved from './ButtonApproved';
import ButtonPack from './ButtonPack';

const StyledTable = styled(Table<BatchData>)`
  .ant-table-thead > tr > th {
    background: #fafafa;
    color: #595959 !important;
  }
  .ant-table-tbody > tr > td {
    height: 84px;
    padding: 20px 16px;
  }
  border-radius: 4px;
  border: 1px solid #e0e0e0;
`;

const BatchDataContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
`;

const BatchNumber = styled(Typography.Title)`
    margin: 0 !important;
`;

const BatchDetails = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-end;
`;

const BatchDate = styled(Typography.Text)`
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
`;

const BatchNumberContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0px;
`;

const BatchNumberLabel = styled(Typography.Text)`
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
`;

const ButtonsContainer = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
`;

export interface BatchData {
    id: number;
    batch_number: string;
    batch_code: string;
    registration_date: string;
    target_value: number;
    unit: string;
    planned_volume: number;
    packaging_permission: boolean;
    packaging_complete: boolean;
    quality_control?: {
        id: number;
        batch_id: number;
        method: string;
        status: string;
        raw_materials?: Array<{
            name: string;
            certifiedValue: number;
            weight: number;
        }>;
        calculated_value?: number;
        intermediate_control_number?: string;
        spc_requirement?: string;
        spc_compliance?: string;
    };
}

interface QualityControlTableProps {
    data: BatchData[];
    loading?: boolean;
    onRowClick?: (batch: BatchData) => void;
}

const QualityControlTable: FC<QualityControlTableProps> = ({ data, loading, onRowClick }) => {
    const columns = [
        {
            title: 'Данные партии',
            key: 'batchData',
            width: 300,
            render: (record: BatchData) => (
                <BatchDataContainer>
                    <BatchNumberContainer>
                        <BatchNumberLabel>Номер партии</BatchNumberLabel>
                        <BatchNumber level={3}>{record.batch_number}</BatchNumber>
                    </BatchNumberContainer>
                    <BatchDetails>
                        <BatchDate>{record.registration_date}</BatchDate>
                        <Typography.Text>{record.batch_code}</Typography.Text>
                    </BatchDetails>
                </BatchDataContainer>
            ),
        },
        {
            title: 'Допуск к фасовке',
            key: 'packagingPermission',
            width: 300,
            align: 'left' as const,
            render: (record: BatchData) => (
                record.packaging_permission ? (
                    <ButtonApproved />
                ) : (
                    <ButtonAddQC />
                )
            ),
        },
        {
            title: 'Расфасовано',
            key: 'packagingComplete',
            width: 300,
            align: 'left' as const,
            render: (record: BatchData) => (
                record.packaging_complete ? (
                    <ButtonsContainer>
                        <ButtonPack />
                        <ButtonAddPack />
                    </ButtonsContainer>
                ) : (
                    <ButtonAddPack />
                )
            ),
        },
    ];

    return (
        <StyledTable 
            columns={columns} 
            dataSource={data.map(item => ({ ...item, key: item.id }))}
            pagination={false}
            loading={loading}
            onRow={(record: BatchData) => ({
                onClick: () => onRowClick?.(record),
                style: { cursor: 'pointer' }
            })}
        />
    );
};

export default QualityControlTable;
