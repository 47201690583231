import { FC } from 'react';
import { Form, Input, Select, DatePicker } from 'antd';
import styled from 'styled-components';
import { CalendarOutlined } from '@ant-design/icons';
import { BatchData } from './Table';

const StyledForm = styled(Form)`
    .ant-form-item {
        margin-bottom: 14px !important;
    }

    .ant-form-item-label {
        padding-bottom: 0px !important;
        
        > label {
            color: #8C8C8C !important;
            font-size: 12px !important;
            font-weight: 500 !important;
            height: 20px !important;
        }
    }

    .ant-input, .ant-select-selector, .ant-picker {
        height: 40px !important;
        background-color: #F5F5F5 !important;
        border-radius: 4px !important;
    }

    .ant-select {
        &.ant-select-single {
            height: 40px;
        }
        .ant-select-selector {
            display: flex;
            align-items: center;
            height: 40px !important;
            padding: 0 11px !important;
            .ant-select-selection-item {
                line-height: 40px !important;
            }
        }
        .ant-select-selection-placeholder {
            line-height: 40px !important;
        }
        .ant-select-arrow {
            inset-block-start: 50% !important;
            transform: translateY(-50%) !important;
        }
    }

    .ant-picker {
        width: 100%;
    }

    .ant-input-affix-wrapper {
        background-color: #F5F5F5 !important;
        padding: 0 11px !important;
        border-radius: 4px !important;

        &:hover, &:focus, &-focused {
            background-color: #F5F5F5 !important;
        }

        .ant-input {
            background-color: #F5F5F5 !important;
        }
    }
`;

interface DataBatchProps {
    form: any;
}

const DataBatch: FC<DataBatchProps> = ({ form }) => {
    return (
        <StyledForm 
            form={form} 
            layout="vertical"
        >
            <Form.Item
                label="Дата регистрации"
                name="batchDate"
                required={false}
            >
                <DatePicker 
                    format="DD.MM.YYYY"
                    placeholder="Выберите дату"
                    suffixIcon={<CalendarOutlined style={{ color: '#1890ff' }} />}
                    disabled
                />
            </Form.Item>

            <Form.Item
                label="Индекс образца"
                name="batchIndex"
                required={false}
            >
                <Input placeholder="Введите данные" disabled />
            </Form.Item>

            <Form.Item
                label="Целевое значение"
                name="targetValue"
                required={false}
            >
                <Input placeholder="Введите данные" disabled />
            </Form.Item>

            <Form.Item
                label="Ед. измерения"
                name="unit"
                required={false}
            >
                <Select
                    placeholder="Выберите единицу измерения"
                    suffixIcon={null}
                    disabled
                    options={[
                        { value: 'mg', label: 'мг' },
                        { value: 'ml', label: 'мл' },
                        { value: 'g', label: 'г' },
                        { value: 'kg', label: 'кг' },
                        { value: 'l', label: 'л' }
                    ]}
                />
            </Form.Item>

            <Form.Item
                label="Плановый объем"
                name="plannedVolume"
                required={false}
            >
                <Input placeholder="Введите данные" disabled />
            </Form.Item>

            <Form.Item
                label="Шифр партии"
                name="batchCode"
                required={false}
            >
                <Input placeholder="Введите данные" disabled />
            </Form.Item>
        </StyledForm>
    );
};

export default DataBatch;
