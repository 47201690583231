import React, { useState, useEffect } from 'react';
import { Layout, Segmented, Button, theme, Typography, Input, message } from 'antd';
import HeaderQualityControl from './Header';
import styled from 'styled-components';
import { ExportOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import QualityControlTable, { BatchData } from './Table';
import AddBatch from './AddBatch';
import { getBatches } from '../../api/batches';
import DataBatch from './AllDataBatch';
import SelectMethod from './SelectMethod';

const { Content } = Layout;
const { Title } = Typography;
const { useToken } = theme;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const SearchInput = styled(Input)`
  width: 45px;
  height: 40px;
  border-color: #d9d9d9;
  border-radius: 6px !important;
  transition: all 0.3s ease-in-out;
  padding: 0;
  
  &.expanded {
    width: 240px;
    padding: 4px 11px;
    border-color: #d9d9d9;
  }

  &:hover {
    border-color: #d9d9d9;
  }

  &:focus {
    border-color: #d9d9d9;
    box-shadow: none;
  }

  .ant-input {
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .ant-input-prefix {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  input {
    line-height: normal !important;
    height: 100% !important;
  }

  &:not(.expanded) {
    .ant-input-prefix {
      margin-right: 0;
    }
    
    .ant-input {
      width: 0;
      padding: 0;
    }
  }

  &.expanded .ant-input-prefix {
    width: auto;
    margin-right: 8px;
  }
`;

const ControlsBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeadingExtra = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;


const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #262626;
    font-family: Roboto;
`;

const MainPage: React.FC = () => {
    const { token } = useToken();
    const [activeSegment, setActiveSegment] = useState<string | number>('Все');
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isAddBatchModalOpen, setIsAddBatchModalOpen] = useState(false);
    const [batches, setBatches] = useState<BatchData[]>([]);
    const [loading, setLoading] = useState(false);
    const [selectedBatch, setSelectedBatch] = useState<BatchData | null>(null);
    const [isDataBatchModalOpen, setIsDataBatchModalOpen] = useState(false);
    const [isMethodSelectOpen, setIsMethodSelectOpen] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState<string | undefined>();

    const fetchBatches = async () => {
        try {
            setLoading(true);
            const data = await getBatches();
            setBatches(data);
        } catch (error: any) {
            message.error('Ошибка при загрузке партий');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBatches();
    }, []);

    const filteredData = batches.filter(item => {
        const matchesSearch = searchValue === '' || 
            (item.batch_code && item.batch_code.toLowerCase().includes(searchValue.toLowerCase()));

        let matchesSegment = true;
        switch (activeSegment) {
            case 'Произведено':
                matchesSegment = !item.packaging_permission && !item.packaging_complete;
                break;
            case 'Допущено к фасовке':
                matchesSegment = item.packaging_permission && !item.packaging_complete;
                break;
            case 'Расфасовано':
                matchesSegment = item.packaging_complete;
                break;
        }

        return matchesSearch && matchesSegment;
    });

    const handleRowClick = (batch: BatchData) => {
        setSelectedBatch(batch);
        setIsDataBatchModalOpen(true);
    };

    const handleMethodConfirm = (method: string) => {
        setSelectedMethod(method);
        setIsMethodSelectOpen(false);
        setIsDataBatchModalOpen(true);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderQualityControl />
            <StyledContent>
                <TextParentRoot>
                    <Title level={4}>Партии</Title>
                </TextParentRoot>
                <ControlsBlock style={{ marginBottom: 16 }}>
                    <Segmented 
                        size="large" 
                        options={['Все', 'Произведено', 'Допущено к фасовке', 'Расфасовано']} 
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                        value={activeSegment}
                        onChange={setActiveSegment}
                    />
                    <HeadingExtra>
                        <SearchInput
                            className={isSearchExpanded ? "expanded" : ""}
                            placeholder={isSearchExpanded ? "Поиск" : ""}
                            prefix={<SearchOutlined style={{ color: token['blue-6'] }}/>}
                            onFocus={() => setIsSearchExpanded(true)}
                            onChange={(e) => setSearchValue(e.target.value)}
                            onBlur={(e) => {
                                if (!e.target.value) {
                                    setIsSearchExpanded(false);
                                }
                            }}
                            style={{ height: '40px' }}
                            value={searchValue}
                        />
                        <Button 
                            icon={<PlusOutlined style={{ color: token['blue-5'] }} />} 
                            style={{ height: '40px' }}
                            onClick={() => setIsAddBatchModalOpen(true)}
                        >
                            Создать задание на производство
                        </Button>
                    </HeadingExtra>
                </ControlsBlock>
                <QualityControlTable 
                    data={filteredData} 
                    loading={loading} 
                    onRowClick={handleRowClick}
                />
                <AddBatch 
                    open={isAddBatchModalOpen}
                    onCancel={() => setIsAddBatchModalOpen(false)}
                    onSuccess={fetchBatches}
                />
                <DataBatch
                    open={isDataBatchModalOpen}
                    onCancel={() => {
                        setIsDataBatchModalOpen(false);
                        setSelectedMethod(undefined);
                    }}
                    batch={selectedBatch}
                    onSuccess={fetchBatches}
                    onQualityControl={() => {
                        setIsDataBatchModalOpen(false);
                        setIsMethodSelectOpen(true);
                    }}
                    selectedMethod={selectedMethod}
                />
                <SelectMethod
                    open={isMethodSelectOpen}
                    onCancel={() => setIsMethodSelectOpen(false)}
                    batchCode={selectedBatch?.batch_code || ''}
                    onConfirm={handleMethodConfirm}
                />
            </StyledContent>
        </Layout>
    );
};

export default MainPage;
