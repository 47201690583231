import React, { useState, useEffect } from 'react';
import { Layout, Button, Space, Typography, Table, Tag, Badge, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import styled from 'styled-components';
import { PlusOutlined, ExportOutlined } from '@ant-design/icons';
import axiosInstance from '../../api/axios';
import { theme } from 'antd';

const { Content } = Layout;
const { Title, Text } = Typography;
const { useToken } = theme;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    min-height: 100vh;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #262626;
    font-family: Roboto;
`;

const HeadingExtra = styled(Space)`
    height: 40px;
`;

const StyledTable = styled(Table)`
    .ant-table-thead > tr > th {
        background: #fafafa;
        color: #595959 !important;
    }
    padding: 16px 0;
    margin-top: 24px;
`;

const TagContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

const StyledTag = styled(Tag)<{ $tagType: string; $token: any }>`
    width: max-content;
    margin: 0 !important;
    border-radius: 2px;
    color: ${props => {
        switch (props.$tagType) {
            case 'Фармакология':
                return props.$token['blue-6'];
            case 'Геология':
                return props.$token['cyan-6'];
            case 'Экология':
                return props.$token['green-6'];
            case 'Мед. оборудование':
                return props.$token['orange-6'];
            default:
                return props.$token['blue-6'];
        }
    }} !important;
    border-color: ${props => {
        switch (props.$tagType) {
            case 'Фармакология':
                return props.$token['blue-3'];
            case 'Геология':
                return props.$token['cyan-3'];
            case 'Экология':
                return props.$token['green-3'];
            case 'Мед. оборудование':
                return props.$token['orange-3'];
            default:
                return props.$token['blue-3'];
        }
    }} !important;
    background: ${props => {
        switch (props.$tagType) {
            case 'Фамакология':
                return props.$token['blue-1'];
            case 'Геология':
                return props.$token['cyan-1'];
            case 'Экология':
                return props.$token['green-1'];
            case 'Мед. оборудование':
                return props.$token['orange-1'];
            default:
                return props.$token['blue-1'];
        }
    }} !important;
`;

const NotificationCircle = styled.div<{ $type: 'warning' | 'emergency'; $token: any }>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.$type === 'warning' ? props.$token['yellow-6'] : props.$token['red-5']};
    color: white;
    font-size: 12px;
    font-weight: 600;
`;

interface Laboratory {
    id: number;
    name: string;
    city: string;
    email: string;
    phone: string;
    tags: string[];
    notification_warning: number;
    notification_emergency: number;
    legal_address: string;
}

const MainPage: React.FC = () => {
    const navigate = useNavigate();
    const { token } = useToken();
    const [laboratories, setLaboratories] = useState<Laboratory[]>([]);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: 'Лаборатория',
            key: 'laboratory',
            width: 247,
            render: (record: any) => (
                <Space>
                    <Space direction="vertical" size={0}>
                        <Typography.Text 
                            strong 
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`/distributor/manage-laboratory/${record.key}`)}
                        >
                            {record.name}
                        </Typography.Text>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Адрес',
            key: 'legal_address',
            width: 247,
            render: (record: any) => (
                <Space>
                    <Space direction="vertical" size={0}>
                        <Typography.Text strong>{record.legal_address}</Typography.Text>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Тег',
            key: 'tags',
            width: 247,
            align: 'left' as const,
            render: (record: any) => (
                <TagContainer>
                    {record.tags.map((tag: string) => (
                        <StyledTag 
                            key={tag}
                            $tagType={tag}
                            $token={token}
                        >
                            {tag}
                        </StyledTag>
                    ))}
                </TagContainer>
            ),
        },
        {
            title: 'E-mail',
            key: 'email',
            width: 247,
            align: 'left' as const,
            render: (record: any) => (
                <Space>
                    <Space direction="vertical" size={0}>
                        <Typography.Text strong>{record.email}</Typography.Text>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Телефон',
            key: 'phone',
            width: 247,
            align: 'left' as const,
            render: (record: any) => (
                <Space>
                    <Space direction="vertical" size={0}>
                        <Typography.Text strong>{record.phone}</Typography.Text>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Уведомления',
            key: 'notification',
            width: 150,
            align: 'center' as const,
            render: (record: any) => (
                <Space direction="vertical" size={4}>
                    <NotificationCircle $type="warning" $token={token}>
                        {record.notification_warning}
                    </NotificationCircle>
                    <NotificationCircle $type="emergency" $token={token}>
                        {record.notification_emergency}
                    </NotificationCircle>
                </Space>
            ),
        },
    ];

    const fetchLaboratories = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await axiosInstance.get('/api/laboratories', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setLaboratories(response.data);
        } catch (error: any) {
            message.error('Ошибка при загрузке лабораторий');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchLaboratories();
    }, []);

    const data = laboratories.map(lab => ({
        key: lab.id,
        name: lab.name,
        legal_address: lab.legal_address || '',
        tags: Array.isArray(lab.tags) ? lab.tags : [],
        email: lab.email,
        phone: lab.phone,
        notification_warning: lab.notification_warning,
        notification_emergency: lab.notification_emergency,
    }));

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header />
            <StyledContent>
                <TextParentRoot>
                    <Title level={4}>Лаборатории</Title>
                    {/* <HeadingExtra>
                        <Button icon={<ExportOutlined style={{ color: token['blue-5'] }} />} style={{ height: '40px', width: '45px' }} />
                        <Button 
                            icon={<PlusOutlined style={{ color: token['blue-5'] }} />} 
                            onClick={() => navigate('/distributor/laboratories')}
                            style={{ height: '40px' }}
                        >
                            Добавить лабораторию
                        </Button>
                    </HeadingExtra> */}
                </TextParentRoot>

                <StyledTable 
                    columns={columns} 
                    dataSource={data}
                    loading={loading}
                    pagination={false}
                />
            </StyledContent>
        </Layout>
    );
};

export default MainPage;
