import { FC, useEffect } from 'react';
import { Form, Input, Select, DatePicker, Button, Typography } from 'antd';
import styled from 'styled-components';
import { CalendarOutlined, PlusOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import { BatchData } from './Table';
import dayjs from 'dayjs';

const StyledForm = styled(Form)`
    .ant-form-item {
        margin-bottom: 14px !important;
    }

    .ant-form-item-label {
        padding-bottom: 0px !important;
        
        > label {
            color: #8C8C8C !important;
            font-size: 12px !important;
            font-weight: 500 !important;
            height: 20px !important;
        }
    }

    .ant-input, .ant-select-selector, .ant-picker {
        height: 40px !important;
        background-color: #F5F5F5 !important;
        border-radius: 4px !important;
    }

    .ant-select {
        &.ant-select-single {
            height: 40px;
        }
        .ant-select-selector {
            display: flex;
            align-items: center;
            height: 40px !important;
            padding: 0 11px !important;
            .ant-select-selection-item {
                line-height: 40px !important;
            }
        }
        .ant-select-selection-placeholder {
            line-height: 40px !important;
        }
    }

    .ant-picker {
        width: 100%;
        input {
            height: 38px !important;
            background-color: #F5F5F5 !important;
        }
    }
`;

const AddButton = styled(Button)`
    width: 100%;
    margin: 8px 0;
    background-color: #F5F5F5;
    border: 1px solid #D9D9D9;
    height: 40px;
    
    &:hover {
        background-color: #E8E8E8 !important;
    }
`;

const DeleteText = styled(Typography.Text)`
    color: #ff4d4f;
    cursor: pointer;
    margin-left: 8px;
    
    &:hover {
        color: #ff7875;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
`;

interface PackMLEProps {
    form: FormInstance;
    batch: BatchData | null;
    existingData?: {
        packaging_date: string;
        container_volume: number;
        nomenclature: string;
        visual_control: string;
        status: string;
        attestation_values?: Array<{
            attestation_value: number;
            attestation_error: number;
        }>;
    };
}

const PackMLE: FC<PackMLEProps> = ({ form, batch, existingData }) => {
    useEffect(() => {
        if (existingData) {
            form.setFieldsValue({
                packagingDate: dayjs(existingData.packaging_date),
                containerVolume: existingData.container_volume,
                nomenclature: existingData.nomenclature,
                visualControl: existingData.visual_control,
                status: existingData.status,
                attestationValues: existingData.attestation_values || []
            });
        }
    }, [existingData, form]);

    return (
        <StyledForm 
            form={form} 
            layout="vertical"
            initialValues={{
                packagingDate: dayjs(),
                attestationValues: [{}]
            }}
        >
            <Form.Item
                label="Дата фасовки"
                name="packagingDate"
                required={false}
                rules={[{ required: true, message: 'Введите дату фасовки' }]}
            >
                <DatePicker 
                    format="DD.MM.YYYY"
                    placeholder="Выберите дату"
                    suffixIcon={<CalendarOutlined style={{ color: '#1890ff' }} />}
                    disabled={!!existingData}
                />
            </Form.Item>

            <Form.Item
                label="Объем тары"
                name="containerVolume"
                required={false}
                rules={[{ required: true, message: 'Введите объем тары' }]}
            >
                <Input placeholder="Введите данные" disabled={!!existingData} />
            </Form.Item>

            <Form.Item
                label="Номенклатура"
                name="nomenclature"
                required={false}
                rules={[{ required: true, message: 'Введите номенклатуру' }]}
            >
                <Input placeholder="Введите данные" disabled={!!existingData} />
            </Form.Item>

            <Form.Item
                label="Визуальный контроль"
                name="visualControl"
                required={false}
                rules={[{ required: true, message: 'Выберите результат визуального контроля' }]}
            >
                <Select
                    placeholder="Выберите результат"
                    options={[
                        { value: 'passed', label: 'Пройден' },
                        { value: 'failed', label: 'Не пройден' }
                    ]}
                    disabled={!!existingData}
                />
            </Form.Item>

            <Form.Item
                label="Статус"
                name="status"
                required={false}
                rules={[{ required: true, message: 'Выберите статус' }]}
            >
                <Select
                    placeholder="Выберите статус"
                    options={[
                        { value: 'inProgress', label: 'В работе' },
                        { value: 'completed', label: 'Завершено' },
                        { value: 'rejected', label: 'Отклонено' }
                    ]}
                    disabled={!!existingData}
                />
            </Form.Item>

            <Form.List name="attestationValues">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <div key={field.key}>
                                {index > 0 && <div style={{ borderTop: '1px solid #f0f0f0', margin: '16px 0' }} />}

                                <HeaderContainer>
                                    <Typography.Text type="secondary" style={{ fontSize: '12px', fontWeight: 500 }}>
                                        {`АЗ ${index + 1}`}
                                    </Typography.Text>
                                    {!existingData && fields.length > 1 && (
                                        <DeleteText onClick={() => remove(field.name)}>
                                            Удалить
                                        </DeleteText>
                                    )}
                                </HeaderContainer>

                                <Form.Item
                                    name={[field.name, 'attestation_value']}
                                    required={false}
                                    rules={[{ required: true, message: 'Введите значение АЗ' }]}
                                >
                                    <Input placeholder="Введите значение" disabled={!!existingData} />
                                </Form.Item>

                                <Form.Item
                                    label={`Погрешность АЗ ${index + 1}`}
                                    name={[field.name, 'attestation_error']}
                                    required={false}
                                    rules={[{ required: true, message: 'Введите погрешность АЗ' }]}
                                >
                                    <Input placeholder="Введите значение" disabled={!!existingData} />
                                </Form.Item>
                            </div>
                        ))}

                        {!existingData && (
                            <AddButton 
                                type="text"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                            >
                                Добавить еще значения АЗ
                            </AddButton>
                        )}
                    </>
                )}
            </Form.List>
        </StyledForm>
    );
};

export default PackMLE;
