import { FC, useEffect } from 'react';
import { Form, Input, Select, Button, Space, Typography } from 'antd';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';
import { getQualityControl } from '../../api/quality-control';

const StyledForm = styled(Form)`
    .ant-form-item {
        margin-bottom: 14px !important;
    }

    .ant-form-item-label {
        padding-bottom: 0px !important;
        
        > label {
            color: #8C8C8C !important;
            font-size: 12px !important;
            font-weight: 500 !important;
            height: 20px !important;
        }
    }

    .ant-input, .ant-select-selector {
        height: 40px !important;
        background-color: #F5F5F5 !important;
        border-radius: 4px !important;
    }

    .ant-select {
        &.ant-select-single {
            height: 40px;
        }
        .ant-select-selector {
            display: flex;
            align-items: center;
            height: 40px !important;
            padding: 0 11px !important;
            .ant-select-selection-item {
                line-height: 40px !important;
            }
        }
        .ant-select-selection-placeholder {
            line-height: 40px !important;
        }
    }
`;

const AddButton = styled(Button)`
    width: 100%;
    margin: 8px 0;
    background-color: #F5F5F5;
    border: 1px solid #D9D9D9;
    height: 40px;
    
    &:hover {
        background-color: #E8E8E8 !important;
    }
`;

const DeleteText = styled(Typography.Text)`
    color: #ff4d4f;
    cursor: pointer;
    margin-left: 8px;
    
    &:hover {
        color: #ff7875;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
`;

interface MethodMLEProps {
    form: any;
    batch?: any;
}

const MethodMLE: FC<MethodMLEProps> = ({ form, batch }) => {
    useEffect(() => {
        const fetchQualityControlData = async () => {
            if (batch?.id) {
                try {
                    const data = await getQualityControl(batch.id);
                    if (data) {
                        form.setFieldsValue({
                            numberMO: data.intermediate_control_number,
                            attestationValues: data.raw_materials?.map((rm: any) => ({
                                attestationValue: rm.certifiedValue,
                                attestationValueError: rm.weight
                            })) || [],
                            spcValues: data.spc_requirement ? [{
                                spcRequirement: data.spc_requirement,
                                spcCompliance: data.spc_compliance
                            }] : [],
                            status: data.status
                        });
                    }
                } catch (error) {
                    if ((error as any).response?.status !== 404) {
                        console.error('Error fetching quality control data:', error);
                    }
                }
            } else {
                form.resetFields();
            }
        };

        fetchQualityControlData();
    }, [batch?.id, form]);

    return (
        <StyledForm 
            form={form} 
            layout="vertical"
            disabled={batch?.packaging_permission}
        >
            <Form.List name="rawMaterials">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <div key={field.key}>
                                {index > 0 && <div style={{ borderTop: '1px solid #f0f0f0', margin: '16px 0' }} />}
                                
                                <HeaderContainer>
                                    <Typography.Text type="secondary" style={{ fontSize: '12px', fontWeight: 500 }}>
                                        {`Наименование сырья ${index + 1}`}
                                    </Typography.Text>
                                    {!batch?.packaging_permission && (
                                        <DeleteText onClick={() => remove(field.name)}>
                                            Удалить
                                        </DeleteText>
                                    )}
                                </HeaderContainer>
                                <Form.Item
                                    name={[field.name, 'name']}
                                    required={false}
                                >
                                    <Input placeholder="Введите наименование" />
                                </Form.Item>

                                <Form.Item
                                    label={`Значение атестованной характеристики в сырье ${index + 1}`}
                                    name={[field.name, 'certifiedValue']}
                                    required={false}
                                >
                                    <Input placeholder="Введите значение" />
                                </Form.Item>

                                <Form.Item
                                    label={`Масса навески сырья ${index + 1}`}
                                    name={[field.name, 'weight']}
                                    required={false}
                                >
                                    <Input placeholder="Введите массу" />
                                </Form.Item>
                            </div>
                        ))}

                        {!batch?.packaging_permission && (
                            <AddButton 
                                type="text"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                            >
                                Добавить еще сырья
                            </AddButton>
                        )}
                    </>
                )}
            </Form.List>

            <Form.Item
                label="№ МО"
                name="numberMO"
                required={false}
            >
                <Input placeholder="Введите значение" />
            </Form.Item>

            <Form.List name="attestationValues">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <div key={field.key}>
                                {index > 0 && <div style={{ borderTop: '1px solid #f0f0f0', margin: '16px 0' }} />}

                                <HeaderContainer>
                                    <Typography.Text type="secondary" style={{ fontSize: '12px', fontWeight: 500 }}>
                                        {`АЗ ${index + 1}`}
                                    </Typography.Text>
                                    {!batch?.packaging_permission && (
                                        <DeleteText onClick={() => remove(field.name)}>
                                            Удалить
                                        </DeleteText>
                                    )}
                                </HeaderContainer>
                                <Form.Item
                                    name={[field.name, 'attestationValue']}
                                    required={false}
                                >
                                    <Input placeholder="Введите значение" />
                                </Form.Item>

                                <Form.Item
                                    label={`Погрешность АЗ ${index + 1}`}
                                    name={[field.name, 'attestationValueError']}
                                    required={false}
                                >
                                    <Input placeholder="Введите значение" />
                                </Form.Item>
                            </div>
                        ))}

                        {!batch?.packaging_permission && (
                            <AddButton 
                                type="text"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                            >
                                Добавить еще значения АЗ
                            </AddButton>
                        )}
                    </>
                )}
            </Form.List>

            <Form.List name="spcValues">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <div key={field.key}>
                                {index > 0 && <div style={{ borderTop: '1px solid #f0f0f0', margin: '16px 0' }} />}

                                <HeaderContainer>
                                    <Typography.Text type="secondary" style={{ fontSize: '12px', fontWeight: 500 }}>
                                        {`Требование СПЦ ${index + 1}`}
                                    </Typography.Text>
                                    {!batch?.packaging_permission && (
                                        <DeleteText onClick={() => remove(field.name)}>
                                            Удалить
                                        </DeleteText>
                                    )}
                                </HeaderContainer>
                                <Form.Item
                                    name={[field.name, 'spcRequirement']}
                                    required={false}
                                >
                                    <Input placeholder="Введите значение" />
                                </Form.Item>

                                <Form.Item
                                    label={`Соответствие требованиям СПЦ ${index + 1}`}
                                    name={[field.name, 'spcCompliance']}
                                    required={false}
                                >
                                    <Input placeholder="Введите значение" />
                                </Form.Item>
                            </div>
                        ))}

                        {!batch?.packaging_permission && (
                            <AddButton 
                                type="text"
                                onClick={() => add()}
                                icon={<PlusOutlined />}
                            >
                                Добавить еще требования СПЦ
                            </AddButton>
                        )}
                    </>
                )}
            </Form.List>

            <Form.Item
                label="Статус"
                name="status"
                required={false}
            >
                <Select
                    placeholder="Выберите статус"
                    options={[
                        { value: 'inProgress', label: 'В работе' },
                        { value: 'completed', label: 'Завершено' },
                        { value: 'rejected', label: 'Отклонено' }
                    ]}
                />
            </Form.Item>
        </StyledForm>
    );
};

export default MethodMLE;
