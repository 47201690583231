import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Table, Checkbox, Select, DatePicker, Button, Tooltip, Input, Space, Popover, theme } from 'antd';
import type { TableColumnsType, InputRef } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import { createStyles } from 'antd-style';
import dayjs from 'dayjs';
import { SearchOutlined, FilterOutlined, EyeInvisibleOutlined, DatabaseOutlined, ArrowUpOutlined, ArrowDownOutlined, MoreOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';
import ColumnManager from '../../components/ColumnManager';
import PrecursorGroups from '../../components/PrecursorGroups';

const { useToken } = theme;

const useStyle = createStyles(({ css }) => ({
  customTable: css`
    .ant-table {
      border: 1px solid #E0E0E0;
      
      .ant-table-thead .ant-table-cell {
        color: #595959;
      }

      .ant-table-tbody {
        .ant-table-cell {
          font-weight: 500;

          .ant-select-selection-item,
          .ant-picker-input > input {
            font-weight: 500;
          }

          .ant-select .ant-select-selector {
            border-radius: 4px !important;
          }
        }
      }

      .cell-content {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `,
}));

export interface PrecursorType {
  key: React.Key;
  name: string;
  batch: string;
  manufacturer: string;
  category: string;
  concentration: string;
  location: string;
  registrationNumber: string;
  month: string;
  initialBalance: string;
  income: {
    operationNumber: string;
    date: string;
    document: string;
    quantity: string;
    responsible: string;
  };
  monthlyIncome: string;
  totalWithBalance: string;
  expense: {
    operationNumber: string;
    type: string;
    date: string;
    document: string;
    quantity: string;
    responsible: string;
  };
  monthlyExpense: string;
  finalBalance: string;
  actualBalance: string;
}

export const testDataSource: PrecursorType[] = Array.from({ length: 100 }).map((_, index) => ({
  key: index,
  name: `Прекурсор ${index + 1}`,
  batch: `B${String(Math.floor(Math.random() * 1000)).padStart(3, '0')}`,
  manufacturer: `Производитель ${index + 1}`,
  category: ['Категория A', 'Категория B', 'Категория C'][Math.floor(Math.random() * 3)],
  concentration: `${Math.floor(Math.random() * 100)}%`,
  location: `Склад ${['A', 'B', 'C'][Math.floor(Math.random() * 3)]}-${Math.floor(Math.random() * 100)}`,
  registrationNumber: `РН-${String(Math.floor(Math.random() * 10000)).padStart(4, '0')}`,
  month: ['Январь', 'Февраль', 'Март', 'Апрель'][Math.floor(Math.random() * 4)],
  initialBalance: `${Math.floor(Math.random() * 1000)} кг`,
  income: {
    operationNumber: `ПР-${String(Math.floor(Math.random() * 1000)).padStart(3, '0')}`,
    date: `2024-${String(index + 1).padStart(2, '0')}-01`,
    document: `Накладная №${Math.floor(Math.random() * 1000)} от ${dayjs().format('DD.MM.YYYY')}`,
    quantity: `${Math.floor(Math.random() * 100)} кг`,
    responsible: ['Иванов И.И.', 'Петров П.П.', 'Сидоров С.С.'][Math.floor(Math.random() * 3)],
  },
  monthlyIncome: `${Math.floor(Math.random() * 1000)} кг`,
  totalWithBalance: `${Math.floor(Math.random() * 2000)} кг`,
  expense: {
    operationNumber: `Р-${String(Math.floor(Math.random() * 1000)).padStart(3, '0')}`,
    type: ['Производство', 'Лаборатория', 'Исследования'][Math.floor(Math.random() * 3)],
    date: `2024-${String(index + 1).padStart(2, '0')}-15`,
    document: `Требование №${Math.floor(Math.random() * 1000)} от ${dayjs().format('DD.MM.YYYY')}`,
    quantity: `${Math.floor(Math.random() * 50)} кг`,
    responsible: ['Иванов И.И.', 'Петров П.П.', 'Сидоров С.С.'][Math.floor(Math.random() * 3)],
  },
  monthlyExpense: `${Math.floor(Math.random() * 500)} кг`,
  finalBalance: `${Math.floor(Math.random() * 1500)} кг`,
  actualBalance: `${Math.floor(Math.random() * 1500)} кг`,
}));

const responsibleOptions = [
  'Иванов И.И.',
  'Петров П.П.',
  'Сидоров С.С.',
].map(item => ({ label: item, value: item }));

const expenseTypeOptions = [
  'Производство',
  'Лаборатория',
  'Исследования',
  'Другое'
].map(item => ({ label: item, value: item }));

const TextWithTooltip: React.FC<{ text: string | React.ReactNode }> = ({ text }) => {
  if (typeof text === 'string' && text.length > 24) {
    return (
      <Tooltip title={text}>
        <div className="cell-content">{text}</div>
      </Tooltip>
    );
  }
  return <div>{text}</div>;
};

const ColumnMenu = styled.div`
  width: 306px;
  padding: 12px;
  background: #F5F5F5;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

const MenuButton = styled(Button)<{ $isBlue?: boolean }>`
  width: 100%;
  height: 40px;
  padding: 6.4px 12px;
  background: ${props => props.$isBlue ? '#1890FF' : 'white'};
  color: ${props => props.$isBlue ? 'white' : 'rgba(0, 0, 0, 0.85)'};
  border: 0.5px solid ${props => props.$isBlue ? '#1890FF' : '#D9D9D9'};
  border-radius: 4px;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;

  .anticon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  span {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: ${props => props.$isBlue ? '#40a9ff' : '#fff'} !important;
    color: ${props => props.$isBlue ? 'white' : 'rgba(0, 0, 0, 0.85)'} !important;
    border-color: ${props => props.$isBlue ? '#40a9ff' : '#d9d9d9'} !important;
  }
`;

const FilterContent = styled.div`
  height: 200px;
  overflow-y: auto;
  margin-bottom: 8px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 2px;
  }
`;

const FilterDropdown: React.FC<{
  close: () => void;
  confirm: (selectedValues: string[]) => void;
  values: string[];
}> = ({ close, confirm, values }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const uniqueValues = Array.from(new Set(values)).sort();

  return (
    <div style={{ padding: 8, background: 'white', borderRadius: 4 }}>
      <FilterContent>
        {uniqueValues.map((value) => (
          <div key={value} style={{ marginBottom: 4 }}>
            <Checkbox
              checked={selectedValues.includes(value)}
              onChange={(e) => {
                const newValues = e.target.checked
                  ? [...selectedValues, value]
                  : selectedValues.filter(v => v !== value);
                setSelectedValues(newValues);
              }}
            >
              {value}
            </Checkbox>
          </div>
        ))}
      </FilterContent>
      <Space>
        <Button
          type="primary"
          onClick={() => {
            confirm(selectedValues);
            close();
          }}
          size="small"
        >
          Применить
        </Button>
        <Button
          onClick={() => {
            setSelectedValues([]);
            confirm([]);
            close();
          }}
          size="small"
        >
          Сбросить
        </Button>
      </Space>
    </div>
  );
};

interface CustomColumnTitleProps {
  title: React.ReactNode;
  onSort?: (order: 'asc' | 'desc') => void;
  onFilter?: (values: string[]) => boolean;
  onHide?: () => void;
  onManage?: () => void;
  type?: 'number' | 'date';
  dataIndex?: string[];
  filterValues?: string[];
  getAllColumnNames: () => { title: string; key: string }[];
  hiddenColumns: string[];
  onColumnVisibilityChange: (columns: string[]) => void;
}

const CustomColumnTitle: React.FC<CustomColumnTitleProps> = ({
  title,
  onSort,
  onFilter,
  onHide,
  onManage,
  type,
  filterValues = [],
  getAllColumnNames,
  hiddenColumns,
  onColumnVisibilityChange
}) => {
  const { token } = useToken();
  const [open, setOpen] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [showColumnManager, setShowColumnManager] = useState(false);

  const handleMenuClick = (callback?: () => void) => {
    if (callback) {
      callback();
    }
    setOpen(false);
  };

  const handleFilter = () => {
    setOpen(false);
    setFilterVisible(true);
  };

  const handleOpenChange = (newOpen: boolean) => {
    if (showColumnManager) return;
    setOpen(newOpen);
  };

  const columnMenu = (
    <ColumnMenu>
      {!showColumnManager ? (
        <>
          {type && (
            <>
              <MenuButton 
                icon={<ArrowUpOutlined style={{ color: token['blue-5'] }} />}
                onClick={() => handleMenuClick(() => onSort?.('asc'))}
              >
                Сортировать по возрастанию
              </MenuButton>
              <MenuButton 
                icon={<ArrowDownOutlined style={{ color: token['blue-5'] }} />}
                onClick={() => handleMenuClick(() => onSort?.('desc'))}
              >
                Сортировать по убыванию
              </MenuButton>
            </>
          )}
          <MenuButton 
            icon={<FilterOutlined style={{ color: token['blue-5'] }} />}
            onClick={handleFilter}
          >
            Филтры
          </MenuButton>
          <MenuButton 
            icon={<EyeInvisibleOutlined style={{ color: token['blue-5'] }} />}
            onClick={() => handleMenuClick(onHide)}
          >
            Скрыть
          </MenuButton>
          <MenuButton 
            $isBlue 
            icon={<DatabaseOutlined style={{ color: 'white' }} />}
            onClick={() => setShowColumnManager(true)}
          >
            Управление коонками
          </MenuButton>
        </>
      ) : (
        <ColumnManager
          columns={getAllColumnNames()}
          hiddenColumns={hiddenColumns}
          onColumnVisibilityChange={onColumnVisibilityChange}
          visible={true}
          onClose={() => setShowColumnManager(false)}
        />
      )}
    </ColumnMenu>
  );

  return (
    <>
      <Popover 
        content={columnMenu} 
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        placement="bottom"
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ background: 'transparent', boxShadow: 'none' }}
      >
        <div style={{ cursor: 'pointer' }}>{title}</div>
      </Popover>
      <Popover
        content={
          <FilterDropdown
            close={() => setFilterVisible(false)}
            confirm={(values) => {
              if (onFilter) {
                onFilter(values);
              }
              setFilterVisible(false);
            }}
            values={filterValues}
          />
        }
        trigger="click"
        open={filterVisible}
        onOpenChange={setFilterVisible}
        placement="bottom"
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ background: 'white', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
      >
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: filterVisible ? 'block' : 'none' }} />
      </Popover>
    </>
  );
};

interface FilterFunction {
  (value: string): boolean;
}

interface PrecursorsTableProps {
  data: PrecursorType[];
  onSelectionChange?: (selectedRowKeys: React.Key[]) => void;
}

const PrecursorsTable: React.FC<PrecursorsTableProps> = ({ 
  data = testDataSource,
  onSelectionChange 
}) => {
  const { styles } = useStyle();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  
  // Состояния для управления таблицей
  const [selectedGroup, setSelectedGroup] = useState<string>('');
  const [columnManagerVisible, setColumnManagerVisible] = useState(false);
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [sortedInfo, setSortedInfo] = useState<{ 
    columnKey: string | null; 
    order: 'ascend' | 'descend' | null 
  }>({
    columnKey: null,
    order: null,
  });
  const [sortConfig, setSortConfig] = useState<{
    columnKey: string | null;
    order: 'asc' | 'desc' | null;
  }>({
    columnKey: null,
    order: null
  });
  
  // Инициализируем состояние скрытых колонок из localStorage
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(() => {
    const saved = localStorage.getItem('precursorsHiddenColumns');
    return saved ? JSON.parse(saved) : [];
  });

  // Сохраняем изменения в localStorage при изменении hiddenColumns
  useEffect(() => {
    localStorage.setItem('precursorsHiddenColumns', JSON.stringify(hiddenColumns));
  }, [hiddenColumns]);

  const handleHideColumn = (dataIndex: string[]) => {
    const columnKey = dataIndex.join('.');
    setHiddenColumns(prev => [...prev, columnKey]);
  };

  // Добавим функцию для показа скрытых колонок
  const handleShowColumn = (columnKey: string) => {
    setHiddenColumns(prev => prev.filter(key => key !== columnKey));
  };

  const filteredData = useMemo(() => {
    let result = selectedGroup 
      ? data.filter(item => item.name === selectedGroup)
      : data;

    result = result.filter(record => {
      return Object.entries(filters).every(([key, filterValues]) => {
        if (filterValues.length === 0) return true;

        const keys = key.split('.');
        const fieldValue = keys.reduce((obj, key) => obj?.[key], record as any)?.toString();
        return filterValues.includes(fieldValue);
      });
    });

    if (sortConfig.columnKey && sortConfig.order) {
      result = [...result].sort((a, b) => {
        const aValue = sortConfig.columnKey!.split('.').reduce((obj, key) => obj?.[key], a as any);
        const bValue = sortConfig.columnKey!.split('.').reduce((obj, key) => obj?.[key], b as any);

        if (typeof aValue === 'string') {
          return sortConfig.order === 'asc'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        }

        return sortConfig.order === 'asc'
          ? (aValue > bValue ? 1 : -1)
          : (bValue > aValue ? 1 : -1);
      });
    }

    return result;
  }, [data, selectedGroup, filters, sortConfig]);

  const handleCheckboxChange = (
    record: PrecursorType,
    field: string,
    category: 'income' | 'expense',
    checked: boolean
  ) => {
    const newData = data.map(item => {
      if (item.key === record.key) {
        return {
          ...item,
          [category]: {
            ...item[category as keyof Pick<PrecursorType, 'income' | 'expense'>],
            [field]: checked
          }
        };
      }
      return item;
    });
  };

  const handleSelectChange = (
    record: PrecursorType,
    field: string,
    category: 'income' | 'expense',
    value: string
  ) => {
    const newData = data.map(item => {
      if (item.key === record.key) {
        return {
          ...item,
          [category]: {
            ...item[category as keyof Pick<PrecursorType, 'income' | 'expense'>],
            [field]: value
          }
        };
      }
      return item;
    });
  };

  const handleDateChange = (
    record: PrecursorType,
    field: string,
    category: 'income' | 'expense',
    value: dayjs.Dayjs | null
  ) => {
    const newData = data.map(item => {
      if (item.key === record.key) {
        return {
          ...item,
          [category]: {
            ...item[category as keyof Pick<PrecursorType, 'income' | 'expense'>],
            [field]: value ? value.format('YYYY-MM-DD') : null
          }
        };
      }
      return item;
    });
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: string[],
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex.join('.'));
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string[]) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Поиск"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поис
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Сброс
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex.join('.'));
            }}
          >
            Фильтр
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Закрыть
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value: any, record: PrecursorType) => {
      const fieldValue = dataIndex.reduce((obj, key) => obj?.[key], record as any);
      return fieldValue?.toString().toLowerCase().includes((value as string).toLowerCase());
    },
    filterDropdownProps: {
      onOpenChange(open: boolean) {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
    render: (text: string) =>
      searchedColumn === dataIndex.join('.') ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        <TextWithTooltip text={text} />
      ),
  });

  const handleSort = (columnKey: string, order: 'asc' | 'desc') => {
    const antdOrder = order === 'asc' ? 'ascend' : 'descend';
    setSortedInfo({ columnKey, order: antdOrder });
    setSortConfig({ columnKey, order });
  };

  const handleFilter = (columnKey: string, values: string[]): boolean => {
    if (values.length === 0) {
      const newFilters = { ...filters };
      delete newFilters[columnKey];
      setFilters(newFilters);
    } else {
      setFilters(prev => ({
        ...prev,
        [columnKey]: values
      }));
    }
    return true;
  };

  const getUniqueValues = (dataIndex: string[]) => {
    return Array.from(new Set(
      filteredData.map(item => {
        const value = dataIndex.reduce((obj, key) => obj?.[key], item as any);
        return value?.toString() || '';
      }).filter(Boolean)
    ));
  };

  const columnTitles: { [key: string]: string } = {
    'month': 'Месяц',
    'initialBalance': 'Остаток на 1 р/д месяца',
    'income.operationNumber': '№ операции по приходу',
    'income.date': 'Дата',
    'income.document': 'Наименование, номер и дата приходного документа',
    'income.quantity': 'Количество',
    'income.responsible': 'Фамилия, инициалы, подпись ответственного лица',
    'monthlyIncome': 'Приход за месяц, всего',
    'totalWithBalance': 'Всего приход с остатком',
    'expense.operationNumber': '№ операции по расходу',
    'expense.type': 'Вид расхода',
    'expense.date': 'Дата',
    'expense.document': 'Наименование, № и дата расходного документа',
    'expense.quantity': 'Количество',
    'expense.responsible': 'Фамилия, инициалы, подпись ответственного лица',
    'monthlyExpense': 'Расход за месяц, всего',
    'finalBalance': 'Остаток на последний р/д месяца',
    'actualBalance': 'Фактический остаток на последний р/д месяца'
  };

  const getAllColumnNames = () => {
    const columnData: { title: string; key: string }[] = [];
    
    const extractNames = (cols: TableColumnsType<PrecursorType>) => {
      cols.forEach(col => {
        if ('children' in col) {
          extractNames(col.children || []);
        } else if (col.key) {
          const key = Array.isArray(col.dataIndex) 
            ? col.dataIndex.join('.')
            : col.dataIndex?.toString() || col.key.toString();
          columnData.push({
            title: columnTitles[key] || key,
            key: key
          });
        }
      });
    };
    
    extractNames(columns);
    return columnData;
  };

  const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    

    .column-menu-icon {
      opacity: 1;
    }
  `;

  const TitleText = styled.div`
    flex: 1;
  `;

  const MenuIcon = styled.div`
    opacity: 0;
    display: flex;
    align-items: center;
    padding: 2px;
    border-radius: 4px;
    transition: all 0.2s;
    
    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }
    
    .anticon {
      font-size: 16px;
      color: #8C8C8C;
    }
  `;

  const createColumnTitle = (title: string, dataIndex?: string[], isGroupTitle?: boolean, type?: 'number' | 'date') => {
    if (isGroupTitle) {
      return <TextWithTooltip text={title} />;
    }

    return (
      <CustomColumnTitle 
        title={
          <TitleContainer>
            <TitleText>
              <TextWithTooltip text={title} />
            </TitleText>
            <MenuIcon className="column-menu-icon">
              <MoreOutlined />
            </MenuIcon>
          </TitleContainer>
        }
        onSort={dataIndex && type ? (order) => handleSort(dataIndex.join('.'), order) : undefined}
        onFilter={dataIndex ? (values) => handleFilter(dataIndex.join('.'), values) : undefined}
        onHide={dataIndex ? () => handleHideColumn(dataIndex) : undefined}
        onManage={() => setColumnManagerVisible(true)}
        type={type}
        dataIndex={dataIndex}
        filterValues={dataIndex ? getUniqueValues(dataIndex) : []}
        getAllColumnNames={getAllColumnNames}
        hiddenColumns={hiddenColumns}
        onColumnVisibilityChange={setHiddenColumns}
      />
    );
  };

  const columns: TableColumnsType<PrecursorType> = [
    {
      title: createColumnTitle('Месяц', ['month']),
      dataIndex: 'month',
      key: 'month',
      width: 150,
    },
    {
      title: createColumnTitle('Остаток на 1 р/д месяца', ['initialBalance']),
      dataIndex: 'initialBalance',
      key: 'initialBalance',
      width: 200,
    },
    {
      title: createColumnTitle('Приход', undefined, true),
      children: [
        {
          title: createColumnTitle('№ операции по приходу', ['income', 'operationNumber']),
          dataIndex: ['income', 'operationNumber'],
          key: 'incomeOperationNumber',
          width: 200,
        },
        {
          title: createColumnTitle('Дата', ['income', 'date']),
          dataIndex: ['income', 'date'],
          key: 'incomeDate',
          width: 150,
          render: (value: string, record: PrecursorType) => (
            <DatePicker
              value={value ? dayjs(value) : null}
              onChange={(date) => handleDateChange(record, 'date', 'income', date)}
              format="DD.MM.YYYY"
              bordered={false}
              style={{ width: '100%' }}
            />
          ),
        },
        {
          title: createColumnTitle('Наименование, номер и дата приходного документа', ['income', 'document']),
          dataIndex: ['income', 'document'],
          key: 'incomeDocument',
          width: 300,
        },
        {
          title: createColumnTitle('Кол-во', ['income', 'quantity']),
          dataIndex: ['income', 'quantity'],
          key: 'incomeQuantity',
          width: 150,
        },
        {
          title: createColumnTitle('Фамилия, инициалы, подпись ответственного лица', ['income', 'responsible']),
          dataIndex: ['income', 'responsible'],
          key: 'incomeResponsible',
          width: 300,
          render: (value: string, record: PrecursorType) => (
            <Select
              value={value}
              onChange={(newValue) => handleSelectChange(record, 'responsible', 'income', newValue)}
              options={responsibleOptions}
              style={{ width: '100%' }}
              showSearch
            />
          ),
        },
      ],
    },
    {
      title: createColumnTitle('Приход за месяц, всего', ['monthlyIncome']),
      dataIndex: 'monthlyIncome',
      key: 'monthlyIncome',
      width: 200,
    },
    {
      title: createColumnTitle('Всего приход с остатком', ['totalWithBalance']),
      dataIndex: 'totalWithBalance',
      key: 'totalWithBalance',
      width: 200,
    },
    {
      title: createColumnTitle('Расход', undefined, true),
      children: [
        {
          title: createColumnTitle('№ операции по расходу', ['expense', 'operationNumber']),
          dataIndex: ['expense', 'operationNumber'],
          key: 'expenseOperationNumber',
          width: 200,
        },
        {
          title: createColumnTitle('Вид расхода', ['expense', 'type']),
          dataIndex: ['expense', 'type'],
          key: 'expenseType',
          width: 200,
          render: (value: string, record: PrecursorType) => (
            <Select
              value={value}
              onChange={(newValue) => handleSelectChange(record, 'type', 'expense', newValue)}
              options={expenseTypeOptions}
              style={{ width: '100%' }}
              showSearch
            />
          ),
        },
        {
          title: createColumnTitle('Дата', ['expense', 'date']),
          dataIndex: ['expense', 'date'],
          key: 'expenseDate',
          width: 150,
          render: (value: string, record: PrecursorType) => (
            <DatePicker
              value={value ? dayjs(value) : null}
              onChange={(date) => handleDateChange(record, 'date', 'expense', date)}
              format="DD.MM.YYYY"
              bordered={false}
              style={{ width: '100%' }}
            />
          ),
        },
        {
          title: createColumnTitle('Наименование, № и дата расходного документа', ['expense', 'document']),
          dataIndex: ['expense', 'document'],
          key: 'expenseDocument',
          width: 400,
        },
        {
          title: createColumnTitle('Кол-во', ['expense', 'quantity']),
          dataIndex: ['expense', 'quantity'],
          key: 'expenseQuantity',
          width: 150,
        },
        {
          title: createColumnTitle('Фамилия, инициалы, подпись ответственного лица', ['expense', 'responsible']),
          dataIndex: ['expense', 'responsible'],
          key: 'expenseResponsible',
          width: 300,
          render: (value: string, record: PrecursorType) => (
            <Select
              value={value}
              onChange={(newValue) => handleSelectChange(record, 'responsible', 'expense', newValue)}
              options={responsibleOptions}
              style={{ width: '100%' }}
              showSearch
            />
          ),
        },
      ],
    },
    {
      title: createColumnTitle('Расход за месяц, всего', ['monthlyExpense']),
      dataIndex: 'monthlyExpense',
      key: 'monthlyExpense',
      width: 200,
    },
    {
      title: createColumnTitle('Остаток на последний р/д месяца', ['finalBalance']),
      dataIndex: 'finalBalance',
      key: 'finalBalance',
      width: 250,
    },
    {
      title: createColumnTitle('Фактический остаток на последний р/д месяца', ['actualBalance']),
      dataIndex: 'actualBalance',
      key: 'actualBalance',
      width: 300,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
      onSelectionChange?.(newSelectedRowKeys);
    },
    columnWidth: 48,
    fixed: true
  };

  const getVisibleColumns = (cols: TableColumnsType<PrecursorType>): TableColumnsType<PrecursorType> => {
    return cols.map(col => {
      const newCol = { ...col };
      
      if ('children' in newCol) {
        const visibleChildren = getVisibleColumns(newCol.children || []);
        if (visibleChildren.length === 0) {
          return { ...newCol, hidden: true };
        }
        return { ...newCol, children: visibleChildren };
      }

      const columnKey = Array.isArray(newCol.dataIndex) 
        ? newCol.dataIndex.join('.') 
        : newCol.dataIndex?.toString();

      if (columnKey && hiddenColumns.includes(columnKey)) {
        return { ...newCol, hidden: true };
      }

      return newCol;
    }).filter(col => !col.hidden);
  };

  const visibleColumns = getVisibleColumns(columns);

  const Container = styled.div`
    display: flex;
    gap: 16px;
    height: 100%;
  `;

  const TableContainer = styled.div`
    flex: 1;
    min-width: 0; // Важно для корректной работы flex-shrink
  `;

  const GroupsContainer = styled.div`
    width: 288px;
    flex-shrink: 0;
  `;

  return (
    <Container>
      <GroupsContainer>
        <PrecursorGroups onGroupSelect={setSelectedGroup} />
      </GroupsContainer>
      <TableContainer>
        <Table<PrecursorType>
          className={styles.customTable}
          columns={visibleColumns}
          dataSource={filteredData}
          rowSelection={rowSelection}
          bordered
          size="middle"
          scroll={{ x: 'max-content', y: 'calc(100vh - 300px)' }}
        />
      </TableContainer>
    </Container>
  );
};

export const testPrecursorsData = testDataSource;

export default PrecursorsTable;