import { FC } from 'react';
import { Modal, Form, Input, Select, Space, Button } from 'antd';
import styled from 'styled-components';

const StyledForm = styled(Form)`
    .ant-form-item {
        margin-bottom: 14px !important;
    }

    .ant-form-item-label {
        padding-bottom: 0px !important;
        
        > label {
            color: #8C8C8C !important;
            font-size: 12px !important;
            font-weight: 500 !important;
            height: 20px !important;
        }
    }

    .ant-input, .ant-select-selector {
        height: 40px !important;
        background-color: #F5F5F5 !important;
        border-radius: 4px !important;
    }

    .ant-select {
        &.ant-select-single {
            height: 40px;
        }
        .ant-select-selector {
            display: flex;
            align-items: center;
            height: 40px !important;
            padding: 0 11px !important;
            .ant-select-selection-item {
                line-height: 40px !important;
            }
        }
        .ant-select-selection-placeholder {
            line-height: 40px !important;
        }
    }
`;

interface SelectMethodProps {
    open: boolean;
    onCancel: () => void;
    batchCode: string;
    onConfirm: (method: string) => void;
}

const SelectMethod: FC<SelectMethodProps> = ({ open, onCancel, batchCode, onConfirm }) => {
    const [form] = Form.useForm();

    const handleConfirm = async () => {
        try {
            const values = await form.validateFields();
            onConfirm(values.method);
        } catch (error) {
            // Форма не прошла валидацию
        }
    };

    return (
        <Modal
            title="Контроль качества"
            open={open}
            onCancel={onCancel}
            footer={null}
            width={400}
            centered
        >
            <Space direction="vertical" size={16} style={{ width: '100%', paddingTop: '10px' }}>
                <StyledForm 
                    form={form} 
                    layout="vertical"
                    initialValues={{
                        batchCode
                    }}
                >
                    <Form.Item
                        label="Шифр партии"
                        name="batchCode"
                    >
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                        label="Метод аттестации"
                        name="method"
                        required={false}
                        rules={[{ required: true, message: 'Выберите метод аттестации' }]}
                    >
                        <Select
                            placeholder="Выберите метод"
                            options={[
                                { value: 'repp', label: 'РЭПП' },
                                { value: 'measurement', label: 'Методика измерения' },
                                { value: 'mle', label: 'МЛЭ' }
                            ]}
                        />
                    </Form.Item>

                    <div style={{ marginTop: '24px' }}>
                        <Button 
                            type="primary" 
                            style={{ 
                                width: '100%',
                                height: 40
                            }}
                            onClick={handleConfirm}
                        >
                            Подтвердить метод
                        </Button>
                    </div>
                </StyledForm>
            </Space>
        </Modal>
    );
};

export default SelectMethod;
