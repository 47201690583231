import { FC } from 'react';
import styled from 'styled-components';
import { Button, theme } from 'antd';
import { RightOutlined } from '@ant-design/icons';

const { useToken } = theme;

const StyledButton = styled(Button)`
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border: none;
    border: 1px solid #e0e0e0;
    
    &:hover {
        border: 1px solid #E8E8E8;
    }
`;

const Circle = styled.div<{ $color: string }>`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${props => props.$color};
    margin-right: 8px;
`;

const TextContainer = styled.div`
    display: flex;
    align-items: center;
`;

const ButtonAddQC: FC = () => {
    const { token } = useToken();

    return (
        <StyledButton>
            <TextContainer>
                <Circle $color={token['blue-5']} />
                <span>Допущено к фасовке</span>
            </TextContainer>
            <RightOutlined/>
        </StyledButton>
    );
};

export default ButtonAddQC;
