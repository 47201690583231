import { FunctionComponent, useState, useCallback, useMemo, useEffect } from 'react';
import styled from "styled-components";
import HeaderLab from './Header';
import { Typography, Layout, Space, Button, theme, Segmented, Input, Popover, message } from 'antd';
import { ExportOutlined, SearchOutlined, PlusOutlined, CalendarOutlined, ImportOutlined, ScanOutlined } from '@ant-design/icons';
import ReagentsTable, { DataType } from '../tables/Reagents';
import StandartSamplesTable, { StandardType } from '../tables/StandartSamples';
import PrecursorsTable, { PrecursorType, testPrecursorsData } from '../tables/Precursors';
import debounce from 'lodash/debounce';
import Screen from './Screen';
import PurchaseRequest from '../../components/PurchaseRequest';
import ExportTypes, { ExportFormat } from '../../components/ExportTypes';
import ExportProcess from '../../components/ExportProcess';
import Scan from '../../components/Scan';
import { useParams, useNavigate } from 'react-router-dom';
import { getReagents, exportReagents, exportStandards } from '../../api/chemicalRegistry';
import ScreenSS from './ScreenSS';
import { getStandards } from '../../api/chemicalRegistry';
import ImportUploadFile from '../../components/ImportUploadFile';

const { Title } = Typography;
const { Content } = Layout;
const { useToken } = theme;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #262626;
    font-family: Roboto;
`;

const ControlsBlock = styled.div`
    // margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeadingExtra = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const SearchInput = styled(Input)`
  width: 45px;
  height: 40px;
  border-color: #d9d9d9;
  border-radius: 6px !important;
  transition: all 0.3s ease-in-out;
  padding: 0;
  
  &.expanded {
    width: 240px;
    padding: 4px 11px;
    border-color: #d9d9d9;
  }

  &:hover {
    border-color: #d9d9d9;
  }

  &:focus {
    border-color: #d9d9d9;
    box-shadow: none;
  }

  .ant-input {
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .ant-input-prefix {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  input {
    line-height: normal !important;
    height: 100% !important;
  }

  &:not(.expanded) {
    .ant-input-prefix {
      margin-right: 0;
    }
    
    .ant-input {
      width: 0;
      padding: 0;
    }
  }

  &.expanded .ant-input-prefix {
    width: auto;
    margin-right: 8px;
  }
`;

const Laboratory: FunctionComponent = () => {
    const { token } = useToken();
    const { laboratoryId } = useParams();
    const [reagents, setReagents] = useState<DataType[]>([]);
    const [standards, setStandards] = useState<StandardType[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedRows, setSelectedRows] = useState<{
        reagents: React.Key[];
        standards: React.Key[];
        precursors: React.Key[];
    }>({
        reagents: [],
        standards: [],
        precursors: []
    });

    const [activeSegment, setActiveSegment] = useState<string>(() => {
        const saved = localStorage.getItem('chemicalsActiveSegment');
        return saved || 'Реактивы';
    });

    useEffect(() => {
        localStorage.setItem('chemicalsActiveSegment', activeSegment);
    }, [activeSegment]);

    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    
    const [data, setData] = useState<{
        reagents: DataType[];
        standards: StandardType[];
        precursors: PrecursorType[];
    }>({
        reagents: [],
        standards: [],
        precursors: testPrecursorsData
    });

    const [modalStates, setModalStates] = useState({
        screen: false,
        purchaseRequest: false,
        export: false,
        exportProcess: false,
        scan: false,
        import: false
    });

    const navigate = useNavigate();

    const fetchReagents = useCallback(async () => {
        try {
            if (!laboratoryId) return;
            
            console.log('Fetching reagents for laboratory:', laboratoryId);
            const reagentsData = await getReagents(laboratoryId);
            console.log('Fetched reagents:', reagentsData);
            
            setData(prev => ({
                ...prev,
                reagents: reagentsData
            }));
        } catch (error) {
            console.error('Error fetching reagents:', error);
            message.error('Ошибка при загрузке реагентов');
        }
    }, [laboratoryId]);

    const fetchStandards = useCallback(async () => {
        try {
            if (!laboratoryId) return;
            
            console.log('Fetching standards for laboratory:', laboratoryId);
            const standardsData = await getStandards(Number(laboratoryId));
            console.log('Fetched standards:', standardsData);
            
            setData(prev => ({
                ...prev,
                standards: standardsData
            }));
        } catch (error) {
            console.error('Error fetching standards:', error);
            message.error('Ошибка при загрузке стандартных образцов');
        }
    }, [laboratoryId]);

    useEffect(() => {
        if (!laboratoryId) {
            const user = JSON.parse(localStorage.getItem('user') || '{}');
            if (user.laboratory_id) {
                navigate(`/laboratory/${user.laboratory_id}/chemicals`);
            }
        } else {
            fetchReagents();
            fetchStandards();
        }
    }, [laboratoryId, navigate, fetchReagents, fetchStandards]);

    const handleScreenSuccess = async () => {
        if (activeSegment === 'Реактивы') {
            await fetchReagents();
        } else if (activeSegment === 'Стандартные образцы (СО)') {
            await fetchStandards();
        }
    };

    const searchInFields = useCallback((item: any, value: string) => {
        const searchableFields = Object.values(item).filter(
            field => typeof field === 'string' || typeof field === 'number'
        );
        const lowerValue = value.toLowerCase();
        return searchableFields.some(field => 
            String(field).toLowerCase().includes(lowerValue)
        );
    }, []);

    const filterData = useCallback((value: string) => {
        if (!value) {
            setData(prev => ({
                ...prev,
                reagents: prev.reagents
            }));
            return;
        }

        setData(prev => ({
            ...prev,
            reagents: value ? prev.reagents.filter(item => searchInFields(item, value)) : prev.reagents,
            standards: value ? prev.standards.filter(item => searchInFields(item, value)) : prev.standards,
            precursors: value ? prev.precursors.filter(item => searchInFields(item, value)) : prev.precursors
        }));
    }, [searchInFields]);

    const debouncedFilter = useMemo(
        () => debounce((value: string) => {
            filterData(value);
        }, 300),
        [filterData]
    );

    const handleSearch = useCallback((value: string) => {
        setSearchValue(value);
        debouncedFilter(value);
    }, [debouncedFilter]);

    const handleSegmentChange = useCallback((value: string | number) => {
        setActiveSegment(value.toString());
        if (searchValue) {
            filterData(searchValue);
        }
    }, [searchValue]);

    const handleExport = async (format: ExportFormat) => {
        try {
            setModalStates(prev => ({
                ...prev,
                export: false,
                exportProcess: true
            }));
            
            if (activeSegment === 'Реактивы') {
                await exportReagents(selectedRows.reagents, format);
            } else if (activeSegment === 'Стандартные образцы (СО)') {
                await exportStandards(selectedRows.standards, format);
            }
            
            message.success('Экспорт успешно завершен');
        } catch (error) {
            message.error('Ошибка при экспорте');
        } finally {
            setModalStates(prev => ({
                ...prev,
                exportProcess: false
            }));
        }
    };

    const selectedItems = useMemo(() => {
        switch (activeSegment) {
            case 'Реактивы':
                return data.reagents
                    .filter(item => selectedRows.reagents.includes(item.key))
                    .map((item, index) => ({
                        key: item.key,
                        number: index + 1,
                        name: item.receipt.name
                    }));
            case 'Стандартные образцы (СО)':
                return data.standards
                    .filter(item => selectedRows.standards.includes(item.key))
                    .map((item, index) => ({
                        key: item.key,
                        number: index + 1,
                        name: item.name
                    }));
            case 'Прекурсоры':
                return data.precursors
                    .filter(item => selectedRows.precursors.includes(item.key))
                    .map((item, index) => ({
                        key: item.key,
                        number: index + 1,
                        name: item.name
                    }));
            default:
                return [];
        }
    }, [selectedRows, data, activeSegment]);

    const filteredData = useMemo(() => {
        if (!searchValue) return data;

        return {
            reagents: data.reagents.filter(item => searchInFields(item, searchValue)),
            standards: data.standards.filter(item => searchInFields(item, searchValue)),
            precursors: data.precursors.filter(item => searchInFields(item, searchValue))
        };
    }, [data, searchValue, searchInFields]);

    const getCurrentData = useCallback(() => {
        switch (activeSegment) {
            case 'Реактивы':
                return data.reagents;
            case 'Стандартные образцы (СО)':
                return data.standards;
            case 'Прекурсоры':
                return data.precursors;
            default:
                return [];
        }
    }, [activeSegment, data]);

    const renderTable = useMemo(() => {
        const currentData = getCurrentData();
        
        switch (activeSegment) {
            case 'Реактивы':
                return (
                    <ReagentsTable 
                        data={currentData as DataType[]}
                        onSelectionChange={(keys) => setSelectedRows(prev => ({ ...prev, reagents: keys }))}
                        laboratoryId={laboratoryId!}
                    />
                );
            case 'Стандартные образцы (СО)':
                return (
                    <StandartSamplesTable 
                        data={currentData as StandardType[]}
                        onSelectionChange={(keys) => setSelectedRows(prev => ({ ...prev, standards: keys }))}
                    />
                );
            case 'Прекурсоры':
                return (
                    <PrecursorsTable 
                        data={currentData as PrecursorType[]}
                        onSelectionChange={(keys) => setSelectedRows(prev => ({ ...prev, precursors: keys }))}
                    />
                );
            default:
                return null;
        }
    }, [activeSegment, getCurrentData, laboratoryId]);

    const shouldShowButtons = useMemo(() => activeSegment !== 'Прекурсоры', [activeSegment]);

    const getScreenComponent = () => {
        if (activeSegment === 'Стандартные образцы (СО)') {
            return (
                <ScreenSS 
                    onCancel={() => setModalStates(prev => ({
                        ...prev,
                        screen: false
                    }))}
                    onSuccess={handleScreenSuccess}
                />
            );
        }
        return (
            <Screen 
                onCancel={() => setModalStates(prev => ({
                    ...prev,
                    screen: false
                }))}
                onSuccess={handleScreenSuccess}
            />
        );
    };

    const handleImport = (file: File, headerRows: number) => {
        console.log('Importing file:', file, 'with header rows:', headerRows);
        message.success('Файл успешно импортирован');
        setModalStates(prev => ({
            ...prev,
            import: false
        }));
    };

    const handleScan = async (scannedData: string) => {
        try {
            // Здесь можно добавить логику обработки отсканированных данных
            // Например, поиск реактива по штрих-коду или другому идентификатору
            console.log('Отсканированные данные:', scannedData);
            
            // Пример поиска реактива по отсканированным данным
            const foundReagent = data.reagents.find(reagent => 
                reagent.receipt.batch === scannedData || 
                reagent.receipt.name === scannedData
            );

            if (foundReagent) {
                message.success(`Найден реактив: ${foundReagent.receipt.name}`);
                // Здесь можно добавить дополнительную логику, например:
                // - Открыть карточку реактива
                // - Обновить количество
                // - и т.д.
            } else {
                message.warning('Реактив не найден');
            }
        } catch (error) {
            console.error('Ошибка при обработке отсканированных данных:', error);
            message.error('Ошибка при обработке отсканированных данных');
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderLab />
            <StyledContent>
                <ControlsBlock style={{ marginBottom: 24 }}>
                    <Segmented 
                        size="large" 
                        options={['Реактивы', 'Стандартные образцы (СО)', 'Прекурсоры']} 
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                        value={activeSegment}
                        onChange={handleSegmentChange}
                    />
                    <HeadingExtra>
                        <SearchInput
                            className={isSearchExpanded ? "expanded" : ""}
                            placeholder={isSearchExpanded ? "Поиск" : ""}
                            prefix={<SearchOutlined style={{ color: token['blue-6'] }} />}
                            onFocus={() => setIsSearchExpanded(true)}
                            onChange={(e) => handleSearch(e.target.value)}
                            onBlur={(e) => {
                                if (!e.target.value) {
                                    setIsSearchExpanded(false);
                                }
                            }}
                            style={{ height: '40px' }}
                            value={searchValue}
                        />
                        <Button 
                            icon={<ExportOutlined style={{ color: token['blue-5'] }} />} 
                            style={{ height: '40px', width: '45px' }}
                            onClick={() => setModalStates(prev => ({
                                ...prev,
                                export: true
                            }))}
                        />
                        {shouldShowButtons && (
                            <>
                                <Popover 
                                    content={getScreenComponent()}
                                    trigger="click"
                                    open={modalStates.screen}
                                    onOpenChange={(visible) => setModalStates(prev => ({
                                        ...prev,
                                        screen: visible
                                    }))}
                                    placement="bottomLeft"
                                    overlayStyle={{ 
                                        padding: 0,
                                        zIndex: 1100,
                                        marginTop: '57px'
                                    }}
                                    overlayInnerStyle={{ 
                                        padding: 0
                                    }}
                                    arrow={false}
                                >
                                    <Button 
                                        icon={<PlusOutlined style={{ color: token['blue-5'] }} />} 
                                        style={{ height: '40px' }}
                                    >
                                        Добавить запись
                                    </Button>
                                </Popover>
                                <Button 
                                    icon={<ScanOutlined style={{ color: token['blue-5'] }} />} 
                                    style={{ height: '40px' }}
                                    onClick={() => setModalStates(prev => ({
                                        ...prev,
                                        scan: true
                                    }))}
                                >
                                    Сканировать
                                </Button>
                                <Button 
                                    icon={<ImportOutlined style={{ color: token['blue-5'] }} />} 
                                    style={{ height: '40px' }}
                                    onClick={() => setModalStates(prev => ({
                                        ...prev,
                                        import: true
                                    }))}
                                >
                                    Импорт
                                </Button>
                                <Button 
                                    icon={
                                        (activeSegment === 'Реактивы' && selectedRows.reagents.length > 0) || 
                                        (activeSegment === 'Стандартные образцы (СО)' && selectedRows.standards.length > 0) ? 
                                            <span style={{ 
                                                display: 'inline-flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                width: 16,
                                                height: 16,
                                                backgroundColor: token['blue-5'],
                                                borderRadius: '50%',
                                                color: 'white',
                                                fontSize: 12,
                                                marginRight: 0
                                            }}>
                                                {activeSegment === 'Реактивы' 
                                                    ? selectedRows.reagents.length 
                                                    : selectedRows.standards.length}
                                            </span> : 
                                            <PlusOutlined style={{ color: token['blue-5'] }} />
                                    }
                                    style={{ height: '40px' }}
                                    onClick={() => setModalStates(prev => ({
                                        ...prev,
                                        purchaseRequest: true
                                    }))}
                                >
                                    Заявка на закупку
                                </Button>
                            </>
                        )}
                    </HeadingExtra>
                </ControlsBlock>
                {renderTable}
                <PurchaseRequest 
                    open={modalStates.purchaseRequest}
                    onCancel={() => setModalStates(prev => ({
                        ...prev,
                        purchaseRequest: false
                    }))}
                    selectedReagents={selectedItems}
                />
                <ExportTypes 
                    open={modalStates.export}
                    onCancel={() => setModalStates(prev => ({
                        ...prev,
                        export: false
                    }))}
                    onExport={handleExport}
                    selectedRows={
                        activeSegment === 'Реактивы' 
                            ? selectedRows.reagents 
                            : selectedRows.standards
                    }
                />
                <ExportProcess 
                    open={modalStates.exportProcess}
                />
                <Scan 
                    open={modalStates.scan}
                    onCancel={() => setModalStates(prev => ({
                        ...prev,
                        scan: false
                    }))}
                    onScan={handleScan}
                />
                <ImportUploadFile 
                    open={modalStates.import}
                    onCancel={() => setModalStates(prev => ({
                        ...prev,
                        import: false
                    }))}
                    onImport={handleImport}
                    tableType={activeSegment === 'Реактивы' ? 'reagents' : 'standartSamples'}
                />
            </StyledContent>
        </Layout>
    );
};

export default Laboratory; 