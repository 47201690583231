import { FunctionComponent, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import HeaderLab from './Header';
import { Typography, Layout, Button, theme, Segmented, Input, message, Select, Space, Table, Avatar, Form, Modal } from 'antd';
import { SearchOutlined, UserAddOutlined, MailOutlined, PhoneOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { laboratoryApi, LaboratoryData, LaboratorySettings } from '../../api/laboratory';
import axiosInstance from '../../api/axios';
import LaboratoryDataForm from './components/LaboratoryDataForm';
import EmployeesPage from './components/EmployeesPage';

const { Title } = Typography;
const { Content } = Layout;
const { useToken } = theme;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    color: #262626;
    font-family: Roboto;
`;

const ControlsBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
`;

const HeadingExtra = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const SearchInput = styled(Input)`
  width: 45px;
  height: 40px;
  border-color: #d9d9d9;
  border-radius: 6px !important;
  transition: all 0.3s ease-in-out;
  padding: 0;
  
  &.expanded {
    width: 240px;
    padding: 4px 11px;
    border-color: #d9d9d9;
  }

  &:hover {
    border-color: #d9d9d9;
  }

  &:focus {
    border-color: #d9d9d9;
    box-shadow: none;
  }

  .ant-input {
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .ant-input-prefix {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  input {
    line-height: normal !important;
    height: 100% !important;
  }

  &:not(.expanded) {
    .ant-input-prefix {
      margin-right: 0;
    }
    
    .ant-input {
      width: 0;
      padding: 0;
    }
  }

  &.expanded .ant-input-prefix {
    width: auto;
    margin-right: 8px;
  }
`;

const SettingsBlock = styled.div`
  width: 690px;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  overflow: hidden;
`;

const SettingRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);

  .setting-label {
    padding: 16px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 22px;
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  .ant-select {
    width: 271px;
    height: 54px;

    .ant-select-selector {
      border-radius: 4px !important;
    }
  }
`;

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    background: #fafafa;
    color: #595959 !important;
  }
    padding: 16px 0;
`;

const FormRow = styled.div`
    display: flex;
    gap: 12px;
    width: 100%;
    
    .ant-form-item {
        flex: 1;
        margin-bottom: 0;
    }

    .ant-form-item-label > label {
        color: #8C8C8C;
        font-size: 12px;
    }
`;

const StyledForm = styled(Form)`
    .ant-form-item-label {
        padding: 0 0 4px !important;
    }
`;

interface RouteParams {
    laboratoryId?: string;
}

interface Employee {
    id: number;
    user_id: number;
    laboratory_id: number;
    name: string;
    email: string;
    phone: string | null;
    role: string;
    is_admin: boolean;
}

const Laboratory: FunctionComponent = () => {
    const { laboratoryId } = useParams<keyof RouteParams>();
    const { token } = useToken();
    const [activeSegment, setActiveSegment] = useState<string | number>('Данные лаборатории');
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isAddEmployeeModalOpen, setIsAddEmployeeModalOpen] = useState(false);
    
    const [laboratoryData, setLaboratoryData] = useState<LaboratoryData>({
        name: '',
        phone: '',
        email: '',
        legal_address: '',
        actual_address: '',
        delivery_address: '',
        tags: [],
        bank_name: '',
        account_number: '',
        correspondent_account: '',
        inn: '',
        bik: '',
        kpp: '',
        ogrn: ''
    });
    const [newTagInput, setNewTagInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | null>(null);
    const [editEmployeeData, setEditEmployeeData] = useState<any>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [form] = Form.useForm();

    const handleSegmentChange = (value: string | number) => {
        setActiveSegment(value);
    };

    const notificationOptions = [
        { value: '1month', label: 'За 1 месяц' },
        { value: '2months', label: 'За 2 месяца' },
        { value: '3months', label: 'За 3 месяца' },
    ];

    const displayOptions = [
        { value: '1month', label: '1 месяц' },
        { value: '2months', label: '2 месяца' },
        { value: '3months', label: '3 месяца' },
    ];

    const renderSettings = () => {
        return (
            <SettingsBlock>
                <SettingRow>
                    <div className="setting-label">
                        Уведомление об окончании срока годности
                    </div>
                    <Select
                        defaultValue="1month"
                        options={notificationOptions}
                    />
                </SettingRow>

                <SettingRow>
                    <div className="setting-label">
                        Уведомление о поверке оборудование
                    </div>
                    <Select
                        defaultValue="1month"
                        options={notificationOptions}
                    />
                </SettingRow>

                <SettingRow>
                    <div className="setting-label">
                        Уведомление о заканчивающихся реактивах
                    </div>
                    <Select
                        defaultValue="1month"
                        options={notificationOptions}
                    />
                </SettingRow>

                <SettingRow>
                    <div className="setting-label">
                        Уведомление о заканчивающихся СО
                    </div>
                    <Select
                        defaultValue="1month"
                        options={notificationOptions}
                    />
                </SettingRow>

                <SettingRow>
                    <div className="setting-label">
                        Отображать в таблице после исчерпания остатков
                    </div>
                    <Select
                        defaultValue="1month"
                        options={displayOptions}
                    />
                </SettingRow>
            </SettingsBlock>
        );
    };

    const fetchEmployees = async () => {
        if (!laboratoryId) return;
        
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await axiosInstance.get(`/api/laboratories/${laboratoryId}/employees`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setEmployees(response.data);
        } catch (error: any) {
            message.error('Ошибка при загрузке списка сотрудников');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (laboratoryId) {
            fetchEmployees();
        }
    }, [laboratoryId]);

    const handleAddEmployee = async () => {
        if (!laboratoryId) {
            message.error('ID лаборатории не найден');
            return;
        }

        try {
            const values = await form.validateFields();
            await axiosInstance.post(
                `/api/laboratories/${laboratoryId}/employees`,
                {
                    ...values,
                    role: 'quality',
                    is_admin: false
                },
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            message.success('Сотрудник успешно добавлен');
            setIsAddEmployeeModalOpen(false);
            form.resetFields();
            fetchEmployees();
        } catch (error: any) {
            const errorMessage = error.response?.data?.detail || 'Ошибка при добавлении сотрудника';
            message.error(errorMessage);
        }
    };

    const handleDeleteEmployee = async (employeeId: number) => {
        try {
            const token = localStorage.getItem('token');
            await axiosInstance.delete(`/api/laboratories/employees/${employeeId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            message.success('Сотрудник успешно удален');
            fetchEmployees();
        } catch (error: any) {
            message.error('Ошибка при удалении сотрудника');
        }
    };

    const handleEditEmployee = async () => {
        try {
            const values = await form.validateFields();
            if (selectedEmployeeId === null) return;

            await axiosInstance.put(
                `/api/laboratories/employees/${selectedEmployeeId}`,
                values,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            message.success('Данные сотрудника обновлены');
            setIsEditModalOpen(false);
            form.resetFields();
            fetchEmployees();
        } catch (error: any) {
            message.error(error.response?.data?.detail || 'Ошибка при обновлении данных');
        }
    };

    const showEditModal = (employee: any) => {
        setEditEmployeeData(employee);
        setSelectedEmployeeId(employee.key);
        form.setFieldsValue({
            name: employee.name,
            email: employee.email,
            phone: employee.phone
        });
        setIsEditModalOpen(true);
    };

    const employeeColumns = [
        {
            title: 'Сотрудник',
            key: 'employee',
            render: (record: any) => (
                <Space>
                    <Avatar style={{ backgroundColor: token['volcano-2'], width: '50px', height: '50px', color: token['volcano-6'] }}>{record.initials}</Avatar>
                    <Space direction="vertical" size={0}>
                        <Typography.Text strong>{record.name}</Typography.Text>
                        <Space>
                            <Space>
                                <MailOutlined />
                                <Typography.Text type="secondary">{record.email}</Typography.Text>
                            </Space>
                            <Space>
                                <PhoneOutlined />
                                <Typography.Text type="secondary">{record.phone}</Typography.Text>
                            </Space>
                        </Space>
                    </Space>
                </Space>
            ),
        },
        {
            title: 'Действия',
            key: 'actions',
            width: 300,
            align: 'left' as const,
            render: (record: any) => (
                <Space>
                    <Button 
                        type="text" 
                        icon={<EditOutlined style={{ color: token['blue-6'] }} />}
                        onClick={() => showEditModal(record)}
                    />
                    <Button 
                        type="text" 
                        icon={<DeleteOutlined style={{ color: token['red-6'] }} />}
                        onClick={() => setIsDeleteModalOpen(true)}
                    />
                </Space>
            ),
        },
    ];

    const employeeData = employees.map(employee => ({
        key: employee.id,
        name: employee.name,
        email: employee.email,
        phone: employee.phone || '',
        avatarColor: token['volcano-2'],
        initialsColor: token['volcano-6'],
        initials: employee.name.split(' ').map(n => n[0]).join(''),
    }));

    const renderEmployees = () => {
        return (
            <>
                <Space direction="vertical" style={{ width: '100%' }} size={24}>

                    <StyledTable 
                        columns={employeeColumns} 
                        dataSource={employeeData}
                        pagination={false}
                    />
                </Space>

                <Modal
                    title="Пригласить сотрудника"
                    open={isAddEmployeeModalOpen}
                    onCancel={() => {
                        setIsAddEmployeeModalOpen(false);
                        form.resetFields();
                    }}
                    centered
                    width={610}
                    footer={[
                        <Button 
                            key="submit"
                            type="primary"
                            onClick={handleAddEmployee}
                            style={{ width: '100%', height: '40px' }}
                        >
                            Пригласить
                        </Button>
                    ]}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        style={{ marginTop: '16px' }}
                    >
                        <FormRow>
                            <Form.Item
                                label="Фамилия и Имя"
                                name="name"
                            >
                                <Input placeholder="введите данные" style={{ height: '40px', width: '178px', backgroundColor: '#F5F5F5' }} />
                            </Form.Item>
                            
                            <Form.Item
                                label="E-mail"
                                name="email"
                                rules={[
                                    { type: 'email', message: 'Пожалуйста, введите корректный email' }
                                ]}
                            >
                                <Input placeholder="введите данные" style={{ height: '40px', width: '178px', backgroundColor: '#F5F5F5' }} />
                            </Form.Item>
                            
                            <Form.Item
                                label="Пароль"
                                name="password"
                            >
                                <Input.Password placeholder="введите данные" style={{ height: '40px', width: '178px', backgroundColor: '#F5F5F5' }} />
                            </Form.Item>
                        </FormRow>
                    </Form>
                </Modal>

                <Modal
                    title={null}
                    open={isDeleteModalOpen}
                    onCancel={() => setIsDeleteModalOpen(false)}
                    centered
                    width={571}
                    footer={null}
                    closable={false}
                >
                    <div style={{ textAlign: 'left', marginBottom: 16 }}>
                        <Space direction="vertical" size={8}>
                            <Typography.Title level={4} style={{ margin: 0 }}>
                                Вы уверены что хотите удалить сотрудника?
                            </Typography.Title>
                            <Typography.Text type="secondary">
                                Данные об удалении записи сохранятся в истории изменений
                            </Typography.Text>
                        </Space>
                    </div>
                    
                    <Space style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Button 
                            onClick={() => setIsDeleteModalOpen(false)}
                            style={{ width: '262px', height: 40 }}
                        >
                            Отмена
                        </Button>
                        <Button 
                            danger
                            type="primary"
                            onClick={() => {
                                if (selectedEmployeeId !== null) {
                                    handleDeleteEmployee(selectedEmployeeId);
                                }
                                setIsDeleteModalOpen(false);
                            }}
                            style={{ width: '262px', height: 40 }}
                        >
                            Да, удалить
                        </Button>
                    </Space>
                </Modal>

                <Modal
                    title="Редактирование сотрудника"
                    open={isEditModalOpen}
                    onCancel={() => {
                        setIsEditModalOpen(false);
                        setEditEmployeeData(null);
                        form.resetFields();
                    }}
                    footer={null}
                    width={330}
                    centered
                >
                    <div style={{ padding: '16px 0' }}>
                        <Space direction="vertical" size={16} style={{ width: '100%' }}>
                            {editEmployeeData && (
                                <Space size={16}>
                                    <Avatar style={{ backgroundColor: token['volcano-2'], width: 50, height: 50, color: token['volcano-6'] }}>
                                        {editEmployeeData.initials}
                                    </Avatar>
                                    <Typography.Title level={5} style={{ margin: 0 }}>
                                        {editEmployeeData.name}
                                    </Typography.Title>
                                </Space>
                            )}
                            <StyledForm form={form} layout="vertical">
                                <Form.Item 
                                    label="Фамилия и Имя"
                                    name="name"
                                    rules={[{ required: true, message: 'Введите имя' }]}
                                >
                                    <Input 
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />}
                                        style={{ height: 40, backgroundColor: '#f5f5f5' }}
                                    />
                                </Form.Item>

                                <Form.Item 
                                    label="E-mail"
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Введите email' },
                                        { type: 'email', message: 'Введите корректный email' }
                                    ]}
                                >
                                    <Input 
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />}
                                        style={{ height: 40, backgroundColor: '#f5f5f5' }}
                                    />
                                </Form.Item>

                                <Form.Item 
                                    label="Номер телефона"
                                    name="phone"
                                >
                                    <Input 
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />}
                                        style={{ height: 40, backgroundColor: '#f5f5f5' }}
                                    />
                                </Form.Item>
                            </StyledForm>

                            <div style={{ display: 'flex', gap: '12px' }}>
                                <Button 
                                    onClick={() => {
                                        setIsEditModalOpen(false);
                                        setEditEmployeeData(null);
                                        form.resetFields();
                                    }}
                                    style={{ flex: 1, height: 40 }}
                                >
                                    Отмена
                                </Button>
                                <Button 
                                    type="primary"
                                    onClick={handleEditEmployee}
                                    style={{ flex: 1, height: 40 }}
                                >
                                    Сохранить
                                </Button>
                            </div>
                        </Space>
                    </div>
                </Modal>
            </>
        );
    };

    const renderContent = () => {
        switch (activeSegment) {
            case 'Данные лаборатории':
                return (
                    <LaboratoryDataForm
                        laboratoryData={laboratoryData}
                        loading={loading}
                        newTagInput={newTagInput}
                        token={token}
                        onInputChange={handleInputChange}
                        onAddTag={handleAddTag}
                        onRemoveTag={handleRemoveTag}
                        onSave={handleSave}
                        setNewTagInput={setNewTagInput}
                    />
                );
            case 'Сотрудники':
                return renderEmployees();
            case 'Настройки':
                return renderSettings();
            default:
                return (
                    <LaboratoryDataForm
                        laboratoryData={laboratoryData}
                        loading={loading}
                        newTagInput={newTagInput}
                        token={token}
                        onInputChange={handleInputChange}
                        onAddTag={handleAddTag}
                        onRemoveTag={handleRemoveTag}
                        onSave={handleSave}
                        setNewTagInput={setNewTagInput}
                    />
                );
        }
    };

    useEffect(() => {
        const fetchLaboratoryData = async () => {
            if (!laboratoryId) return;
            
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await axiosInstance.get(`/api/laboratories/${laboratoryId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setLaboratoryData(response.data);
            } catch (error) {
                console.error('Error fetching laboratory data:', error);
                message.error('Ошибка при загрузке данных лаборатории');
            } finally {
                setLoading(false);
            }
        };

        fetchLaboratoryData();
    }, [laboratoryId]);

    const handleInputChange = (field: keyof LaboratoryData, value: string) => {
        setLaboratoryData((prev: LaboratoryData) => ({
            ...prev,
            [field]: value
        }));
    };

    const handleAddTag = (tag: string) => {
        if (tag && !laboratoryData.tags.includes(tag)) {
            setLaboratoryData((prev: LaboratoryData) => ({
                ...prev,
                tags: [...prev.tags, tag]
            }));
            setNewTagInput('');
        }
    };

    const handleRemoveTag = (tagToRemove: string) => {
        setLaboratoryData((prev: LaboratoryData) => ({
            ...prev,
            tags: prev.tags.filter((tag: string) => tag !== tagToRemove)
        }));
    };

    const handleSave = async () => {
        if (!laboratoryId) return;

        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            await axiosInstance.put(
                `/api/laboratories/${laboratoryId}`,
                {
                    name: laboratoryData.name,
                    email: laboratoryData.email,
                    phone: laboratoryData.phone,
                    tags: laboratoryData.tags,
                    legal_address: laboratoryData.legal_address,
                    actual_address: laboratoryData.actual_address,
                    delivery_address: laboratoryData.delivery_address,
                    bank_name: laboratoryData.bank_name,
                    account_number: laboratoryData.account_number,
                    correspondent_account: laboratoryData.correspondent_account,
                    inn: laboratoryData.inn,
                    bik: laboratoryData.bik,
                    kpp: laboratoryData.kpp,
                    ogrn: laboratoryData.ogrn
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            message.success('Данные успешно сохранены');
        } catch (error) {
            console.error('Error updating laboratory data:', error);
            message.error('Ошибка при сохранении данных');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderLab />
            <StyledContent>
                <ControlsBlock style={{ marginBottom: 24 }}>
                    <Segmented 
                        size="large" 
                        options={['Данные лаборатории', 'Сотрудники', 'Настройки']} 
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }}
                        value={activeSegment}
                        onChange={handleSegmentChange}
                    />
                    {activeSegment === 'Сотрудники' && (
                        <Button 
                            icon={<UserAddOutlined style={{ color: token['blue-5'] }} />} 
                            onClick={() => setIsAddEmployeeModalOpen(true)} 
                            style={{ height: '40px' }}
                        >
                            Добавить сотрудника
                        </Button>
                    )}
                </ControlsBlock>
                {renderContent()}
            </StyledContent>
        </Layout>
    );
};

export default Laboratory; 