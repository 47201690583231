import { useState, useRef, useCallback, useMemo, useEffect, type FunctionComponent } from 'react';
import styled from "styled-components";
import Header from './Header';
import { Typography, Layout, Space, Row, Col, Button, theme, Segmented, Modal, Input, Tag } from 'antd';
import type { InputRef } from 'antd';
import { ArrowLeftOutlined, MailOutlined, PhoneOutlined, SettingOutlined, TeamOutlined, FolderOutlined, FilterOutlined, ExportOutlined, SearchOutlined, PlusOutlined, CalendarOutlined, ImportOutlined, ScanOutlined, EditOutlined, CloseOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import ReagentsTable, { DataType } from '../tables/Reagents';
import StandartSamplesTable, { StandardType } from '../tables/StandartSamples';
import PrecursorsTable from '../tables/Precursors';
import MeasuringInstrumentsTable, { MeasuringInstrumentType } from '../tables/MeasuringInstruments';
import TestInstrumentsTable, { TestInstrumentType, testTestingData } from '../tables/TestInstruments';
import HelpInstrumentsTable, { HelpInstrumentType, testHelpData } from '../tables/HelpInstruments';
import MSITable, { MSIType, testMSIData } from '../tables/MSI';
import MSIYearPlanTable, { MSIYearPlanType, testMSIYearPlanData } from '../tables/MSIYearPlan';
import debounce from 'lodash/debounce';
import axiosInstance from '../../api/axios';
import { message } from 'antd';
import { getStandards } from '../../api/chemicalRegistry';
import { instrumentsApi } from '../../api/instruments';
import { msiApi } from '../../api/msi';
import MSIGroups from '../../components/MSIgroups';

const { Title, Text } = Typography;
const { Content } = Layout;
const { useToken } = theme;

const StyledContent = styled(Content)`
    padding: 32px;
    background-color: #f5f5f5;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
`;

const TextParentRoot = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    color: #262626;
    font-family: Roboto;
    cursor: pointer;
`;

const BackButton = styled(Space)`
    &:hover {
        opacity: 0.8;
    }
    align-items: center;
`;

const StyledArrow = styled(ArrowLeftOutlined)`
    color: #262626;
    font-size: 16px;
    display: flex;
    align-items: center;
`;

const InfoBlock = styled.div`
    background: white;
    border-radius: 4px;
    padding: 16px;
    margin-top: 24px;
    border: 1px solid #d9d9d9;
`;

const ContactInfo = styled(Space)`
    color: ${props => props.theme.token?.colorTextSecondary};
`;

const TabButton = styled(Button)<{ $active?: boolean }>`
    height: 40px;
    border-color: ${props => props.$active ? '#1890ff' : '#d9d9d9'} !important;
    color: ${props => props.$active ? '#1890ff' : 'rgba(0, 0, 0, 0.85)'} !important;

    &:hover {
        border-color: ${props => props.$active ? '#1890ff' : '#d9d9d9'} !important;
        color: ${props => props.$active ? '#1890ff' : 'rgba(0, 0, 0, 0.85)'} !important;
    }
`;

const ActionButton = styled(Button)`
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const ControlsBlock = styled.div`
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const HeadingExtra = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const StyledModal = styled(Modal)<{ $token?: any }>`
  .ant-modal-content {
    padding: 24px;
    margin: 0 32px;
    border-radius: 4px;
  }
  
  .ant-modal-close {
    display: none;
  }
  
  label {
    display: block;
    color: ${props => props.$token?.colorTextTertiary};
    font-size: 12px;
    font-weight: 600;
    padding: 0 0 4px !important;
  }

  h2 {
    margin: 24px 0 16px 0;
    color: ${props => props.$token?.colorText};
  }

  .ant-input {
    height: 40px;
    border-radius: 4px;
    background-color: #fAfAfA !important;

    &:hover, &:focus {
      background-color: #fAfAfA !important;
    }
  }

  .ant-input-affix-wrapper {
    background-color: #fAfAfA !important;
    padding: 0 11px !important;

    &:hover, &:focus, &-focused {
      background-color: #fAfAfA !important;
    }

    .ant-input {
      background-color: #fAfAfA !important;
    }
  }

  .ant-btn {
    height: 40px;
    border-radius: 4px;
  }

  .ant-tag {
    border-radius: 4px;
    
    &.new-tag {
      background: white;
      border-style: dashed;
    }
  }

  .ant-input-suffix .anticon {
    color: ${props => props.$token['blue-6']} !important;
  }
`;

const SearchInput = styled(Input)`
  width: 45px;
  height: 40px;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  padding: 0;
  
  &.expanded {
    width: 259px;
    padding: 4px 11px;
    border-color: #d9d9d9;
  }

  &:hover {
    border-color: #d9d9d9;
  }

  &:focus {
    border-color: #d9d9d9;
    box-shadow: none;
  }

  .ant-input {
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .ant-input-prefix {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  input {
    line-height: normal !important;
    height: 100% !important;
  }

  &:not(.expanded) {
    .ant-input-prefix {
      margin-right: 0;
    }
    
    .ant-input {
      width: 0;
      padding: 0;
    }
  }

  &.expanded .ant-input-prefix {
    width: auto;
    margin-right: 8px;
  }
`;

interface LaboratoryData {
    id: number;
    name: string;
    city: string;
    email: string;
    phone: string;
    tags: string[];
    notification_warning: number;
    notification_emergency: number;
    legal_address: string | null;
    actual_address: string | null;
    delivery_address: string | null;
    bank_name: string | null;
    account_number: string | null;
    correspondent_account: string | null;
    inn: string | null;
    bik: string | null;
    kpp: string | null;
    ogrn: string | null;
}

const ManageLaboratory: FunctionComponent = () => {
    const navigate = useNavigate();
    const { token } = useToken();
    const [activeTab, setActiveTab] = useState('chemicals');
    const [activeReagentType, setActiveReagentType] = useState('reagents');
    const [activeEquipmentType, setActiveEquipmentType] = useState('measuring');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const searchInputRef = useRef<InputRef>(null);
    const [searchValue, setSearchValue] = useState('');
    const [tableData, setTableData] = useState<DataType[]>([]);
    const [standardsData, setStandardsData] = useState<StandardType[]>([]);
    const [activeMsiType, setActiveMsiType] = useState('plan');
    const [testInstrumentsData, setTestInstrumentsData] = useState<TestInstrumentType[]>(testTestingData);
    const [helpInstrumentsData, setHelpInstrumentsData] = useState<HelpInstrumentType[]>(testHelpData);
    const [msiData, setMsiData] = useState<MSIType[]>([]);
    const { id } = useParams<{ id: string }>();
    const [laboratoryData, setLaboratoryData] = useState<LaboratoryData | null>(null);
    const [loading, setLoading] = useState(false);
    const [newTagInput, setNewTagInput] = useState('');
    const [measuringData, setMeasuringData] = useState<MeasuringInstrumentType[]>([]);

    const handleBack = () => {
        navigate('/distributor');
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleEmployersClick = () => {
        navigate(`/distributor/employers-laboratory/${id}`);
    };

    const handleSettingsClick = () => {
        navigate(`/distributor/settings-laboratory/${id}`);
    };

    const searchInReagentsFields = useCallback((item: DataType, value: string) => {
        const searchFields = [
            item.receipt.name,
            item.receipt.batch,
            item.receipt.manufacturer,
            item.receipt.supplier,
            item.receipt.frequencyClass,
            item.storage.location,
            item.storage.conditions,
            item.accounting.openedShelfLife,
            item.accounting.notes
        ];

        const lowerValue = value.toLowerCase();
        return searchFields.some(field => 
            field?.toString().toLowerCase().includes(lowerValue)
        );
    }, []);

    const searchInStandardsFields = useCallback((item: StandardType, value: string) => {
        const searchFields = [
            item.name,
            item.manufacturer,
            item.purpose,
            item.indicator,
            item.additionalInfo,
            item.normativeDocument,
            item.notes
        ];

        const lowerValue = value.toLowerCase();
        return searchFields.some(field => 
            field?.toString().toLowerCase().includes(lowerValue)
        );
    }, []);

    const searchInTestInstrumentsFields = useCallback((item: TestInstrumentType, value: string) => {
        const searchFields = [
            item.status,
            item.characteristics,
            item.testName,
            item.equipmentName,
            item.manufacturer,
            item.technicalSpecs,
            String(item.commissioningYear),
            item.documentInfo,
            item.ownershipDocument,
            item.location,
            item.notes
        ];

        const lowerValue = value.toLowerCase();
        return searchFields.some(field => 
            field?.toString().toLowerCase().includes(lowerValue)
        );
    }, []);

    const searchInHelpInstrumentsFields = useCallback((item: HelpInstrumentType, value: string) => {
        const searchFields = [
            item.status,
            item.name,
            item.manufacturer,
            String(item.commissioningYear),
            item.purpose,
            item.location,
            item.ownershipDocument,
            item.notes
        ];

        const lowerValue = value.toLowerCase();
        return searchFields.some(field => 
            field?.toString().toLowerCase().includes(lowerValue)
        );
    }, []);

    const searchInMSIFields = useCallback((item: MSIType, value: string) => {
        const searchFields = [
            item.measurement_method,
            item.measurement_methodology,
            item.research_object,
            item.characteristic,
            item.employee,
            item.provider,
            item.deadline
        ];

        const lowerValue = value.toLowerCase();
        return searchFields.some(field => 
            field?.toString().toLowerCase().includes(lowerValue)
        );
    }, []);

    const searchInMSIYearPlanFields = useCallback((item: MSIYearPlanType, value: string) => {
        const searchFields = [
            item.object,
            item.roundDates,
            item.applicationDeadline,
            item.pickupDates,
            item.resultSubmission,
            item.conclusionDates,
            item.programType
        ];

        const lowerValue = value.toLowerCase();
        return searchFields.some(field => 
            field?.toString().toLowerCase().includes(lowerValue)
        );
    }, []);

    const filterData = useCallback((value: string) => {
        if (!value) {
            fetchStandards();
            return;
        }

        if (activeTab === 'chemicals') {
            switch (activeReagentType) {
                case 'reagents':
                    const filteredReagents = tableData.filter(item => searchInReagentsFields(item, value));
                    setTableData(filteredReagents);
                    break;
                case 'standards':
                    const filteredStandards = standardsData.filter(item => 
                        searchInStandardsFields(item, value)
                    );
                    setStandardsData(filteredStandards);
                    break;
                case 'precursors':
                    // Добавить поиск по прекурсорам, когда удет готова таблица
                    break;
            }
        } else if (activeTab === 'equipment') {
            switch (activeEquipmentType) {
                case 'measuring':
                    // Добавить поиск по средству измерения
                    break;
                case 'testing':
                    const filteredTestInstruments = testTestingData.filter(item => 
                        searchInTestInstrumentsFields(item, value)
                    );
                    setTestInstrumentsData(filteredTestInstruments);
                    break;
                case 'auxiliary':
                    const filteredHelpInstruments = testHelpData.filter(item => 
                        searchInHelpInstrumentsFields(item, value)
                    );
                    setHelpInstrumentsData(filteredHelpInstruments);
                    break;
            }
        } else if (activeTab === 'msi') {
            switch (activeMsiType) {
                case 'plan':
                    const filteredMSI = msiData.filter(item => 
                        searchInMSIFields(item, value)
                    );
                    setMsiData(filteredMSI);
                    break;
                case 'year_plan':
                    break;
            }
        }
    }, [activeTab, activeReagentType, standardsData, searchInReagentsFields, searchInStandardsFields, searchInTestInstrumentsFields, searchInHelpInstrumentsFields, searchInMSIFields, searchInMSIYearPlanFields]);

    const debouncedFilter = useMemo(
        () => debounce(filterData, 300),
        [filterData]
    );

    const handleSearch = useCallback((value: string) => {
        setSearchValue(value);
        debouncedFilter(value);
    }, [debouncedFilter]);

    useEffect(() => {
        return () => {
            debouncedFilter.cancel();
        };
    }, [debouncedFilter]);

    const fetchLaboratoryData = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await axiosInstance.get(`/api/laboratories/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setLaboratoryData(response.data);
        } catch (error: any) {
            message.error('Ошибка при загрузке данных лборатории');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchStandards = useCallback(async () => {
        if (!id) return;
        
        setLoading(true);
        try {
            const standards = await getStandards(Number(id));
            setStandardsData(standards);
        } catch (error) {
            console.error('Error fetching standards:', error);
            message.error('Ошибка при загрузке стандартных образцов');
        } finally {
            setLoading(false);
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            fetchLaboratoryData();
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            fetchStandards();
        }
    }, [id, fetchStandards]);

    const handleSave = async () => {
        if (!laboratoryData) {
            message.error('Нет данных для обновления');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            await axiosInstance.put(
                `/api/laboratories/${id}`,
                {
                    name: laboratoryData.name,
                    email: laboratoryData.email,
                    phone: laboratoryData.phone,
                    tags: laboratoryData.tags,
                    legal_address: laboratoryData.legal_address,
                    actual_address: laboratoryData.actual_address,
                    delivery_address: laboratoryData.delivery_address,
                    bank_name: laboratoryData.bank_name,
                    account_number: laboratoryData.account_number,
                    correspondent_account: laboratoryData.correspondent_account,
                    inn: laboratoryData.inn,
                    bik: laboratoryData.bik,
                    kpp: laboratoryData.kpp,
                    ogrn: laboratoryData.ogrn
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
            message.success('Данные успешно обновлены');
            handleModalClose();
            fetchLaboratoryData(); // Перезагружаем данные
        } catch (error: any) {
            message.error('Ошибка при обновлении данных');
            console.error('Error:', error);
        }
    };

    const handleInputChange = (field: keyof LaboratoryData, value: string) => {
        if (!laboratoryData) {
            return;
        }
        
        setLaboratoryData({
            ...laboratoryData,
            [field]: value
        });
    };

    const handleAddTag = (newTag: string) => {
        if (!laboratoryData || !newTag || laboratoryData.tags.includes(newTag)) {
            return;
        }
        
        setLaboratoryData({
            ...laboratoryData,
            tags: [...laboratoryData.tags, newTag]
        });
        setNewTagInput('');
    };

    const handleRemoveTag = (removedTag: string) => {
        if (!laboratoryData) {
            return;
        }
        
        setLaboratoryData({
            ...laboratoryData,
            tags: laboratoryData.tags.filter(tag => tag !== removedTag)
        });
    };

    const fetchInstruments = useCallback(async () => {
        if (!id) return;
        
        setLoading(true);
        try {
            let endpoint;
            switch (activeEquipmentType) {
                case 'measuring':
                    const measuringResponse = await axiosInstance.get(`/instruments/laboratory/${id}/measuring`);
                    setMeasuringData(measuringResponse.data);
                    break;
                case 'testing':
                    const testingResponse = await axiosInstance.get(`/instruments/laboratory/${id}/testing`);
                    setTestInstrumentsData(testingResponse.data);
                    break;
                case 'auxiliary':
                    const helpingResponse = await axiosInstance.get(`/instruments/laboratory/${id}/helping`);
                    setHelpInstrumentsData(helpingResponse.data);
                    break;
            }
        } catch (error) {
            console.error('Error fetching instruments:', error);
            message.error('Ошибка при загрузке оборудования');
        } finally {
            setLoading(false);
        }
    }, [id, activeEquipmentType]);

    useEffect(() => {
        if (activeTab === 'equipment' && id) {
            fetchInstruments();
        }
    }, [activeTab, activeEquipmentType, id, fetchInstruments]);

    const renderEquipmentContent = () => {
        switch (activeEquipmentType) {
            case 'measuring':
                return (
                    <MeasuringInstrumentsTable 
                        data={measuringData}
                        searchValue={searchValue}
                    />
                );
            case 'testing':
                return (
                    <TestInstrumentsTable 
                        data={testInstrumentsData}
                        searchValue={searchValue}
                    />
                );
            case 'auxiliary':
                return (
                    <HelpInstrumentsTable 
                        data={helpInstrumentsData}
                        searchValue={searchValue}
                    />
                );
            default:
                return null;
        }
    };

    const fetchMSIData = useCallback(async () => {
        if (!id) return;
        
        setLoading(true);
        try {
            const response = await msiApi.getLaboratoryMSI(parseInt(id));
            const msiWithKeys = response.data.map((item: any) => ({
                ...item,
                key: item.id
            }));
            setMsiData(msiWithKeys);
        } catch (error) {
            console.error('Error fetching MSI data:', error);
            message.error('Ошибка при загрузке данных МСИ');
        } finally {
            setLoading(false);
        }
    }, [id]);

    useEffect(() => {
        if (activeTab === 'msi' && id) {
            fetchMSIData();
        }
    }, [activeTab, id, fetchMSIData]);

    const renderMSIContent = () => {
        switch (activeMsiType) {
            case 'plan':
                return (
                    <MSITable 
                        data={msiData} 
                        searchValue={searchValue}
                    />
                );
            case 'year_plan':
                return (
                    <Row gutter={24}>
                        <Col span={6}>
                            <MSIGroups onSelect={(item) => console.log('Selected:', item)} />
                        </Col>
                        <Col span={18}>
                            <MSIYearPlanTable 
                                searchValue={searchValue}
                            />
                        </Col>
                    </Row>
                );
            default:
                return null;
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!laboratoryData) {
        return <div>Лборатория не найдена</div>;
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header />
            <StyledContent>
                <TextParentRoot onClick={handleBack}>
                    <BackButton align="center">
                        <StyledArrow />
                        <Title level={4} style={{ margin: 0, paddingLeft: 16, fontWeight: 500 }}>Назад</Title>
                    </BackButton>
                </TextParentRoot>

                <InfoBlock>
                    <Row justify="space-between">
                        <Col>
                            <Title level={4} style={{ margin: '0 0 16px 0', fontSize: 20, fontWeight: 500 }}>
                                {laboratoryData.name}
                            </Title>
                            <Space size={8}>
                                <TabButton 
                                    $active={activeTab === 'chemicals'} 
                                    onClick={() => setActiveTab('chemicals')}
                                >
                                    Химические вещества
                                </TabButton>
                                <TabButton 
                                    $active={activeTab === 'equipment'} 
                                    onClick={() => setActiveTab('equipment')}
                                >
                                    Оборудование
                                </TabButton>
                                <TabButton 
                                    $active={activeTab === 'msi'} 
                                    onClick={() => setActiveTab('msi')}
                                >
                                    МСИ
                                </TabButton>
                            </Space>
                        </Col>
                        <Col>
                            <Row justify="end" style={{ marginBottom: 22 }}>
                                <Space size={12}>
                                    <Space size={6}>
                                        <MailOutlined style={{ color: token.colorTextSecondary }} />
                                        <Text type="secondary" strong>{laboratoryData.email}</Text>
                                    </Space>
                                    <Space size={6}>
                                        <PhoneOutlined style={{ color: token.colorTextSecondary }} />
                                        <Text type="secondary" strong>{laboratoryData.phone}</Text>
                                    </Space>
                                </Space>
                            </Row>
                            <Space size={8}>
                                <ActionButton 
                                    icon={<FolderOutlined style={{ color: token['blue-6'] }} />}
                                    onClick={() => setIsModalOpen(true)}
                                >
                                    Данные лаборатории
                                </ActionButton>
                                {/* <ActionButton 
                                    icon={<TeamOutlined style={{ color: token['blue-6'] }} />}
                                    onClick={handleEmployersClick}
                                >
                                    Сотрудники лаборатории
                                </ActionButton>
                                <ActionButton 
                                    icon={<SettingOutlined style={{ color: token['blue-6'] }} />}
                                    onClick={handleSettingsClick}
                                >
                                    Настройки
                                </ActionButton> */}
                            </Space>
                        </Col>
                    </Row>
                </InfoBlock>

                <ControlsBlock style={{ marginBottom: 24 }}>
                    {activeTab === 'chemicals' ? (
                        <Segmented 
                            size="large" 
                            options={[
                                { label: 'Реактивы', value: 'reagents' },
                                { label: 'Стандартные образцы (СО)', value: 'standards' },
                                { label: 'Прекурсоры', value: 'precursors' }
                            ]} 
                            value={activeReagentType}
                            onChange={(value) => setActiveReagentType(value.toString())}
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }} 
                        />
                    ) : activeTab === 'equipment' ? (
                        <Segmented 
                            size="large" 
                            options={[
                                { label: 'Средства измерения (СИ)', value: 'measuring' },
                                { label: 'Испытательное оборудование (ИО)', value: 'testing' },
                                { label: 'Вспомогательное оборудование (ВО)', value: 'auxiliary' }
                            ]} 
                            value={activeEquipmentType}
                            onChange={(value) => setActiveEquipmentType(value.toString())}
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }} 
                        />
                    ) : activeTab === 'msi' ? (
                        <Segmented 
                            size="large" 
                            options={[
                                { label: 'МСИ - план участия в испытаниях', value: 'plan' },
                                { label: 'Годовой план МСИ', value: 'year_plan' }
                            ]} 
                            value={activeMsiType}
                            onChange={(value) => setActiveMsiType(value.toString())}
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.06)' }} 
                        />
                    ) : null}
                    <HeadingExtra>
                        <Button icon={<ExportOutlined style={{ color: token['blue-5'] }} />} style={{ height: '40px', width: '45px' }} />
                    </HeadingExtra>
                </ControlsBlock>

                {activeTab === 'chemicals' && (
                    <>
                        {activeReagentType === 'reagents' && (
                            <ReagentsTable 
                                data={tableData} 
                                laboratoryId={id!}
                            />
                        )}
                        {activeReagentType === 'standards' && (
                            <StandartSamplesTable 
                                data={standardsData} 
                                loading={loading}
                            />
                        )}
                        {activeReagentType === 'precursors' && <PrecursorsTable data={[]} />}
                    </>
                )}

                {activeTab === 'equipment' && (
                    <>
                        {renderEquipmentContent()}
                    </>
                )}

                {activeTab === 'msi' && (
                    <>
                        {renderMSIContent()}
                    </>
                )}

                <StyledModal
                    open={isModalOpen}
                    onCancel={handleModalClose}
                    footer={null}
                    width="calc(100vw - 64px)"
                    style={{
                        top: '50%',
                        transform: 'translateY(-50%)',
                        padding: 0, 
                        maxWidth: 'none'
                    }}
                    $token={token}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <h2 style={{ marginTop: 0 }}>Данные лаборатории</h2>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <label>Юр.название компании</label>
                                    <Input 
                                        value={laboratoryData.name} 
                                        onChange={(e) => handleInputChange('name', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <label>Номер телефона</label>
                                    <Input 
                                        value={laboratoryData.phone} 
                                        onChange={(e) => handleInputChange('phone', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <label>E-mail</label>
                                    <Input 
                                        value={laboratoryData.email} 
                                        onChange={(e) => handleInputChange('email', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <label>Юр.адрес</label>
                                    <Input 
                                        value={laboratoryData.legal_address || ''} 
                                        onChange={(e) => handleInputChange('legal_address', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <label>Факт.адрес</label>
                                    <Input 
                                        value={laboratoryData.actual_address || ''} 
                                        onChange={(e) => handleInputChange('actual_address', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <label>Адрес доставки</label>
                                    <Input 
                                        value={laboratoryData.delivery_address || ''} 
                                        onChange={(e) => handleInputChange('delivery_address', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                            </Row>
                            <h2>Тег</h2>
                            <div>
                                {laboratoryData.tags.map(tag => (
                                    <Tag 
                                        key={tag} 
                                        closable 
                                        closeIcon={<CloseOutlined />} 
                                        onClose={() => handleRemoveTag(tag)}
                                    >
                                        {tag}
                                    </Tag>
                                ))}
                                <Input
                                    type="text"
                                    size="small"
                                    style={{ width: 100, marginRight: 8, height: 22 }}
                                    value={newTagInput}
                                    onChange={(e) => setNewTagInput(e.target.value)}
                                    onPressEnter={() => {
                                        if (newTagInput) {
                                            handleAddTag(newTagInput);
                                        }
                                    }}
                                    placeholder="Новый тег"
                                />
                                <Tag 
                                    className="new-tag" 
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        if (newTagInput) {
                                            handleAddTag(newTagInput);
                                        }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Добавить тег
                                </Tag>
                            </div>
                        </Col>
                        <Col span={12}>
                            <h2 style={{ marginTop: 0 }}>Платежные реквизиты</h2>
                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    <label>Название банка</label>
                                    <Input 
                                        value={laboratoryData.bank_name || ''} 
                                        onChange={(e) => handleInputChange('bank_name', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <label>Расчет.счет</label>
                                    <Input 
                                        value={laboratoryData.account_number || ''} 
                                        onChange={(e) => handleInputChange('account_number', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <label>Кор.счет</label>
                                    <Input 
                                        value={laboratoryData.correspondent_account || ''} 
                                        onChange={(e) => handleInputChange('correspondent_account', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <label>ИНН</label>
                                    <Input 
                                        value={laboratoryData.inn || ''} 
                                        onChange={(e) => handleInputChange('inn', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <label>БИК</label>
                                    <Input 
                                        value={laboratoryData.bik || ''} 
                                        onChange={(e) => handleInputChange('bik', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <label>КПП</label>
                                    <Input 
                                        value={laboratoryData.kpp || ''} 
                                        onChange={(e) => handleInputChange('kpp', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                                <Col span={24}>
                                    <label>ОГРН</label>
                                    <Input 
                                        value={laboratoryData.ogrn || ''} 
                                        onChange={(e) => handleInputChange('ogrn', e.target.value)}
                                        suffix={<EditOutlined style={{ color: token['blue-6'] }} />} 
                                    />
                                </Col>
                            </Row>
                            <Row gutter={16} style={{ marginTop: 16 }}>
                                <Col span={12}>
                                    <Button block onClick={handleModalClose}>Закрыть</Button>
                                </Col>
                                <Col span={12}>
                                    <Button type="primary" block onClick={handleSave}>
                                        Сохранить
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </StyledModal>
            </StyledContent>
        </Layout>
    );
};

export default ManageLaboratory; 