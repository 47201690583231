import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Table, Checkbox, Select, DatePicker, Button, Tooltip, Input, Space, Popover, theme } from 'antd';
import type { TableColumnsType, InputRef } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import { createStyles } from 'antd-style';
import dayjs from 'dayjs';
import { SearchOutlined, FilterOutlined, EyeInvisibleOutlined, DatabaseOutlined, ArrowUpOutlined, ArrowDownOutlined, MoreOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';
import ColumnManager from '../../components/ColumnManager';
import EditInstrument from '../../components/EditInstrument';
import { INSTRUMENT_TYPES } from '../../constants/instrumentTypes';

const { useToken } = theme;

const useStyle = createStyles(({ css }) => ({
  customTable: css`
    .ant-table {
      border: 1px solid #E0E0E0;
      
      .ant-table-thead .ant-table-cell {
        color: #595959;
      }

      .ant-table-tbody {
        .ant-table-cell {
          font-weight: 500;

          .ant-select-selection-item,
          .ant-picker-input > input {
            font-weight: 500;
          }

          .ant-select .ant-select-selector {
            border-radius: 4px !important;
          }
        }
      }

      .cell-content {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `,
}));

export interface TestInstrumentType {
  key: React.Key;
  id: number;
  number: number;
  status: string;
  characteristics: string;
  testName: string;
  equipmentName: string;
  manufacturer: string;
  technicalSpecs: string;
  commissioningYear: number;
  documentInfo: string;
  ownershipDocument: string;
  location: string;
  notes: string;
}

export const testTestingData: TestInstrumentType[] = Array.from({ length: 100 }).map((_, index) => ({
  key: index,
  id: index + 1,
  number: index + 1,
  status: ['В работе', 'На аттестации', 'В ремонте', 'Списан'][Math.floor(Math.random() * 4)],
  characteristics: `Характер��стика ${index + 1}`,
  testName: `Испытание ${index + 1}`,
  equipmentName: `Оборудование ${index + 1}`,
  manufacturer: `Произв��ель ${index + 1}`,
  technicalSpecs: `Тех. характеристики ${index + 1}`,
  commissioningYear: 2015 + Math.floor(Math.random() * 9),
  documentInfo: `Документ №${String(Math.floor(Math.random() * 1000)).padStart(3, '0')} от ${2024-Math.floor(Math.random() * 3)}-${String(Math.floor(Math.random() * 12) + 1).padStart(2, '0')}-${String(Math.floor(Math.random() * 28) + 1).padStart(2, '0')}`,
  ownershipDocument: `Договор №${String(Math.floor(Math.random() * 1000)).padStart(3, '0')}`,
  location: `Лаборатория ${['A', 'B', 'C'][Math.floor(Math.random() * 3)]}-${Math.floor(Math.random() * 100)}`,
  notes: `Примечание ${index + 1}`,
}));

const statusOptions = [
  'В работе',
  'На аттестации',
  'В ремонте',
  'Списан'
].map(item => ({ label: item, value: item }));

const locationOptions = [
  'Лаборатория A',
  'Лаборатория B',
  'Лаборатория C'
].map(item => ({ label: item, value: item }));

const TextWithTooltip: React.FC<{ text: string | React.ReactNode }> = ({ text }) => {
  if (typeof text === 'string' && text.length > 24) {
    return (
      <Tooltip title={text}>
        <div className="cell-content">{text}</div>
      </Tooltip>
    );
  }
  return <div>{text}</div>;
};

const ColumnMenu = styled.div`
  width: 306px;
  padding: 12px;
  background: #F5F5F5;
  border-radius: 4px;
  border: 1px solid #D9D9D9;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;

const MenuButton = styled(Button)<{ $isBlue?: boolean }>`
  width: 100%;
  height: 40px;
  padding: 6.4px 12px;
  background: ${props => props.$isBlue ? '#1890FF' : 'white'};
  color: ${props => props.$isBlue ? 'white' : 'rgba(0, 0, 0, 0.85)'};
  border: 0.5px solid ${props => props.$isBlue ? '#1890FF' : '#D9D9D9'};
  border-radius: 4px;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;

  .anticon {
    margin-right: 8px;
    display: flex;
    align-items: center;
  }

  span {
    display: flex;
    align-items: center;
  }

  &:hover {
    background: ${props => props.$isBlue ? '#40a9ff' : '#fff'} !important;
    color: ${props => props.$isBlue ? 'white' : 'rgba(0, 0, 0, 0.85)'} !important;
    border-color: ${props => props.$isBlue ? '#40a9ff' : '#d9d9d9'} !important;
  }
`;

const FilterContent = styled.div`
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 8px;
  padding-right: 8px;
`;

const FilterDropdown: React.FC<{
  close: () => void;
  confirm: (selectedValues: string[]) => void;
  values: string[];
}> = ({ close, confirm, values }) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const uniqueValues = Array.from(new Set(values)).sort();

  return (
    <div style={{ padding: 8, background: 'white', borderRadius: 4 }}>
      <FilterContent>
        {uniqueValues.map((value) => (
          <div key={value} style={{ marginBottom: 4 }}>
            <Checkbox
              checked={selectedValues.includes(value)}
              onChange={(e) => {
                const newValues = e.target.checked
                  ? [...selectedValues, value]
                  : selectedValues.filter(v => v !== value);
                setSelectedValues(newValues);
              }}
            >
              {value}
            </Checkbox>
          </div>
        ))}
      </FilterContent>
      <Space>
        <Button
          type="primary"
          onClick={() => {
            confirm(selectedValues);
            close();
          }}
          size="small"
        >
          Применить
        </Button>
        <Button
          onClick={() => {
            setSelectedValues([]);
            confirm([]);
            close();
          }}
          size="small"
        >
          Сбросить
        </Button>
      </Space>
    </div>
  );
};

interface CustomColumnTitleProps {
  title: React.ReactNode;
  onSort?: (order: 'asc' | 'desc') => void;
  onFilter?: (values: string[]) => boolean;
  onHide?: () => void;
  type?: 'number' | 'date';
  dataIndex?: string[];
  filterValues?: string[];
  hiddenColumns: string[];
  onColumnVisibilityChange: (columns: string[]) => void;
}

const CustomColumnTitle: React.FC<CustomColumnTitleProps> = ({
  title,
  onSort,
  onFilter,
  onHide,
  type,
  dataIndex,
  filterValues = [],
  hiddenColumns,
  onColumnVisibilityChange
}) => {
  const { token } = useToken();
  const [showFilter, setShowFilter] = useState(false);
  const [showColumnManager, setShowColumnManager] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);

  const handleMenuClick = (callback?: () => void) => {
    if (callback) {
      callback();
    }
    setShowFilter(false);
  };

  const handleFilter = () => {
    setShowFilter(false);
    setFilterVisible(true);
  };

  const columnMenu = (
    <ColumnMenu>
      {!showColumnManager ? (
        <>
          {type && (
            <>
              <MenuButton 
                icon={<ArrowUpOutlined style={{ color: token['blue-5'] }} />}
                onClick={() => handleMenuClick(() => onSort?.('asc'))}
              >
                Сортировать по возрастанию
              </MenuButton>
              <MenuButton 
                icon={<ArrowDownOutlined style={{ color: token['blue-5'] }} />}
                onClick={() => handleMenuClick(() => onSort?.('desc'))}
              >
                Сортировать по убыванию
              </MenuButton>
            </>
          )}
          <MenuButton 
            icon={<FilterOutlined style={{ color: token['blue-5'] }} />}
            onClick={handleFilter}
          >
            Фильтры
          </MenuButton>
          <MenuButton 
            icon={<EyeInvisibleOutlined style={{ color: token['blue-5'] }} />}
            onClick={() => handleMenuClick(onHide)}
          >
            Скрыть
          </MenuButton>
          <MenuButton 
            $isBlue 
            icon={<DatabaseOutlined style={{ color: 'white' }} />}
            onClick={() => setShowColumnManager(true)}
          >
            Управление колонками
          </MenuButton>
        </>
      ) : (
        <ColumnManager
          columns={getAllColumnNames()}
          hiddenColumns={hiddenColumns}
          onColumnVisibilityChange={onColumnVisibilityChange}
          visible={true}
          onClose={() => setShowColumnManager(false)}
        />
      )}
    </ColumnMenu>
  );

  return (
    <>
      <Popover 
        content={columnMenu} 
        trigger="click"
        open={showFilter}
        onOpenChange={setShowFilter}
        placement="bottom"
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ background: 'transparent', boxShadow: 'none' }}
      >
        <div style={{ cursor: 'pointer' }}>{title}</div>
      </Popover>
      <Popover
        content={
          <FilterDropdown
            close={() => setFilterVisible(false)}
            confirm={(values) => {
              if (onFilter) {
                onFilter(values);
              }
              setFilterVisible(false);
            }}
            values={filterValues}
          />
        }
        trigger="click"
        open={filterVisible}
        onOpenChange={setFilterVisible}
        placement="bottom"
        overlayStyle={{ padding: 0 }}
        overlayInnerStyle={{ background: 'white', boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)' }}
      >
        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: filterVisible ? 'block' : 'none' }} />
      </Popover>
    </>
  );
};

interface FilterFunction {
  (value: string): boolean;
}

interface TestInstrumentsTableProps {
    data?: TestInstrumentType[];
    searchValue?: string;
    onSelectionChange?: (selectedRowKeys: React.Key[]) => void;
    loading?: boolean;
    onSuccess?: () => void;
}

const HIDDEN_COLUMNS_STORAGE_KEY = 'test-instruments-hidden-columns';

const getAllColumnNames = () => [
  { title: '№ п/п', key: 'number' },
  { title: 'Статус оборудования', key: 'status' },
  { title: 'Характеристики', key: 'characteristics' },
  { title: 'Наименование испытания', key: 'testName' },
  { title: 'Наименование оборудования', key: 'equipmentName' },
  { title: 'Изготовитель', key: 'manufacturer' },
  { title: 'Технические характеристики', key: 'technicalSpecs' },
  { title: 'Год ввода в эксплуатацию', key: 'commissioningYear' },
  { title: 'Документ о повер��е/аттестации', key: 'documentInfo' },
  { title: 'Право собственности или документ', key: 'ownershipDocument' },
  { title: 'Место установки или хранения', key: 'location' },
  { title: 'Примечания', key: 'notes' }
];

const TestInstrumentsTable: React.FC<TestInstrumentsTableProps> = ({ 
    data = [],
    searchValue = '',
    onSelectionChange,
    loading = false,
    onSuccess
}) => {
  const { styles } = useStyle();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);
  const [hiddenColumns, setHiddenColumns] = useState<string[]>(() => {
    const saved = localStorage.getItem(HIDDEN_COLUMNS_STORAGE_KEY);
    return saved ? JSON.parse(saved) : [];
  });
  const [sortedInfo, setSortedInfo] = useState<{ columnKey: string | null; order: 'ascend' | 'descend' | null }>({
    columnKey: null,
    order: null,
  });
  const [filteredData, setFilteredData] = useState<TestInstrumentType[]>(data);
  const [filters, setFilters] = useState<Record<string, string[]>>({});
  const [editRecord, setEditRecord] = useState<TestInstrumentType | null>(null);

  useEffect(() => {
    localStorage.setItem(HIDDEN_COLUMNS_STORAGE_KEY, JSON.stringify(hiddenColumns));
  }, [hiddenColumns]);

  const searchInFields = useCallback((item: TestInstrumentType, value: string) => {
    const searchFields = [
        item.status,
        item.characteristics,
        item.testName,
        item.equipmentName,
        item.manufacturer,
        item.technicalSpecs,
        String(item.commissioningYear),
        item.documentInfo,
        item.ownershipDocument,
        item.location,
        item.notes
    ];

    const lowerValue = value.toLowerCase();
    return searchFields.some(field => 
        field?.toString().toLowerCase().includes(lowerValue)
    );
  }, []);

  useEffect(() => {
    const searchFiltered = searchValue 
      ? data.filter(item => searchInFields(item, searchValue))
      : data;

    const newData = searchFiltered.filter(record => {
      return Object.entries(filters).every(([key, filterValues]) => {
        if (filterValues.length === 0) return true;
        const keys = key.split('.');
        const fieldValue = keys.reduce((obj, key) => obj?.[key], record as any)?.toString();
        return filterValues.includes(fieldValue);
      });
    });

    setFilteredData(newData);
  }, [filters, data, searchValue, searchInFields]);

  const handleCheckboxChange = (
    record: TestInstrumentType,
    field: 'isPrecursor',
    checked: boolean
  ) => {
    setFilteredData(prevData => 
      prevData.map(item => {
        if (item.key === record.key) {
          return {
            ...item,
            [field]: checked
          };
        }
        return item;
      })
    );
  };

  const handleSelectChange = (
    record: TestInstrumentType,
    field: string,
    value: string
  ) => {
    setFilteredData(prevData =>
      prevData.map(item => {
        if (item.key === record.key) {
          return {
            ...item,
            [field]: value
          };
        }
        return item;
      })
    );
  };

  const handleDateChange = (
    record: TestInstrumentType,
    field: string,
    value: dayjs.Dayjs | null
  ) => {
    setFilteredData(prevData =>
      prevData.map(item => {
        if (item.key === record.key) {
          return {
            ...item,
            [field]: value ? value.format('YYYY-MM-DD') : null
          };
        }
        return item;
      })
    );
  };

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: string[],
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex.join('.'));
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: string[]) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder="Поиск"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Пои��к
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Сброс
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText((selectedKeys as string[])[0]);
              setSearchedColumn(dataIndex.join('.'));
            }}
          >
            Фильтр
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Закрть
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value: any, record: TestInstrumentType) => {
      const fieldValue = dataIndex.reduce((obj, key) => obj?.[key], record as any);
      return fieldValue?.toString().toLowerCase().includes((value as string).toLowerCase());
    },
    filterDropdownProps: {
      onOpenChange(open: boolean) {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
    render: (text: string) =>
      searchedColumn === dataIndex.join('.') ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        <TextWithTooltip text={text} />
      ),
  });

  const handleHideColumn = (dataIndex: string[]) => {
    const columnKey = dataIndex.join('.');
    setHiddenColumns(prev => [...prev, columnKey]);
  };

  const handleSort = (columnKey: string, order: 'asc' | 'desc') => {
    const antdOrder = order === 'asc' ? 'ascend' : 'descend';
    setSortedInfo({ columnKey, order: antdOrder });

    setFilteredData(prevData => {
      const newData = [...prevData];
      newData.sort((a, b) => {
        const aValue = columnKey.split('.').reduce((obj, key) => obj?.[key], a as any);
        const bValue = columnKey.split('.').reduce((obj, key) => obj?.[key], b as any);

        if (typeof aValue === 'string') {
          return order === 'asc' 
            ? aValue.localeCompare(bValue) 
            : bValue.localeCompare(aValue);
        }

        return order === 'asc' 
          ? (aValue > bValue ? 1 : -1)
          : (bValue > aValue ? 1 : -1);
      });
      return newData;
    });
  };

  const handleFilter = (columnKey: string, values: string[]): boolean => {
    if (values.length === 0) {
      const newFilters = { ...filters };
      delete newFilters[columnKey];
      setFilters(newFilters);
    } else {
      setFilters(prev => ({
        ...prev,
        [columnKey]: values
      }));
    }
    return true;
  };

  const getUniqueValues = (dataIndex: string[]) => {
    return Array.from(new Set(
      filteredData.map(item => {
        const value = dataIndex.reduce((obj, key) => obj?.[key], item as any);
        return value?.toString() || '';
      }).filter(Boolean)
    ));
  };

  const handleColumnVisibilityChange = (newHiddenColumns: string[]) => {
    setHiddenColumns(newHiddenColumns);
    localStorage.setItem(HIDDEN_COLUMNS_STORAGE_KEY, JSON.stringify(newHiddenColumns));
  };

  const createColumnTitle = (title: string, dataIndex?: string[], isGroupTitle?: boolean, type?: 'number' | 'date') => {
    if (isGroupTitle) {
      return <TextWithTooltip text={title} />;
    }

    return (
      <CustomColumnTitle 
        title={
          <TitleContainer>
            <TitleText>
              <TextWithTooltip text={title} />
            </TitleText>
            <MenuIcon className="column-menu-icon">
              <MoreOutlined />
            </MenuIcon>
          </TitleContainer>
        }
        onSort={dataIndex && type ? (order) => handleSort(dataIndex.join('.'), order) : undefined}
        onFilter={dataIndex ? (values) => handleFilter(dataIndex.join('.'), values) : undefined}
        onHide={() => dataIndex && handleHideColumn(dataIndex)}
        type={type}
        dataIndex={dataIndex}
        filterValues={dataIndex ? getUniqueValues(dataIndex) : []}
        hiddenColumns={hiddenColumns}
        onColumnVisibilityChange={handleColumnVisibilityChange}
      />
    );
  };

  const columns: TableColumnsType<TestInstrumentType> = [
    {
        title: createColumnTitle('№ п/п', ['number'], false, 'number'),
        dataIndex: 'number',
        key: 'number',
        width: 80,
        fixed: 'left',
        sortOrder: sortedInfo.columnKey === 'number' ? sortedInfo.order : null,
        hidden: hiddenColumns.includes('number'),
    },
    {
        title: createColumnTitle('Статус оборудования', ['status']),
        dataIndex: 'status',
        key: 'status',
        width: 232,
        hidden: hiddenColumns.includes('status'),
        render: (value: string, record: TestInstrumentType) => (
            <Select
                value={value}
                onChange={(newValue) => handleSelectChange(record, 'status', newValue)}
                options={statusOptions}
                style={{ width: '100%' }}
                allowClear
            />
        ),
    },
    {
        title: createColumnTitle('Наименование определяемых характеристик', ['characteristics']),
        dataIndex: 'characteristics',
        key: 'characteristics',
        width: 232,
        hidden: hiddenColumns.includes('characteristics'),
    },
    {
        title: createColumnTitle('Наименование испытуемых групп объектов', ['testName']),
        dataIndex: 'testName',
        key: 'testName',
        width: 232,
        hidden: hiddenColumns.includes('testName'),
    },
    {
        title: createColumnTitle('Наименование испытательного оборудования', ['equipmentName']),
        dataIndex: 'equipmentName',
        key: 'equipmentName',
        width: 232,
        hidden: hiddenColumns.includes('equipmentName'),
    },
    {
        title: createColumnTitle('Изготовитель', ['manufacturer']),
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        width: 232,
        hidden: hiddenColumns.includes('manufacturer'),
    },
    {
        title: createColumnTitle('Основные тех. характеристики', ['technicalSpecs']),
        dataIndex: 'technicalSpecs',
        key: 'technicalSpecs',
        width: 232,
        hidden: hiddenColumns.includes('technicalSpecs'),
    },
    {
        title: createColumnTitle('Год ввода в эксплуатацию', ['commissioningYear'], false, 'number'),
        dataIndex: 'commissioningYear',
        key: 'commissioningYear',
        width: 232,
        hidden: hiddenColumns.includes('commissioningYear'),
    },
    {
        title: createColumnTitle('Дата и номер документа', ['documentInfo']),
        dataIndex: 'documentInfo',
        key: 'documentInfo',
        width: 232,
        hidden: hiddenColumns.includes('documentInfo'),
    },
    {
        title: createColumnTitle('Право собственности или документ', ['ownershipDocument']),
        dataIndex: 'ownershipDocument',
        key: 'ownershipDocument',
        width: 232,
        hidden: hiddenColumns.includes('ownershipDocument'),
    },
    {
        title: createColumnTitle('Место установки или хранения', ['location']),
        dataIndex: 'location',
        key: 'location',
        width: 232,
        hidden: hiddenColumns.includes('location'),
        render: (value: string, record: TestInstrumentType) => (
            <Select
                value={value}
                onChange={(newValue) => handleSelectChange(record, 'location', newValue)}
                options={locationOptions}
                style={{ width: '100%' }}
                allowClear
            />
        ),
    },
    {
        title: createColumnTitle('Примечания', ['notes']),
        dataIndex: 'notes',
        key: 'notes',
        width: 232,
        hidden: hiddenColumns.includes('notes'),
        render: (value: string, record: TestInstrumentType) => (
            <Input
                value={value}
                onChange={(e) => handleSelectChange(record, 'notes', e.target.value)}
                style={{ width: '100%' }}
                bordered={false}
            />
        ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
      onSelectionChange?.(newSelectedRowKeys);
    },
    columnWidth: 48,
    fixed: true
  };

  const getVisibleColumns = (cols: TableColumnsType<TestInstrumentType>): TableColumnsType<TestInstrumentType> => {
    return cols.map(col => {
      if ('children' in col) {
        return {
          ...col,
          children: getVisibleColumns(col.children || []).filter(child => !child.hidden)
        };
      }
      return col;
    }).filter(col => {
      if ('children' in col) {
        return col.children && col.children.length > 0;
      }
      return !col.hidden;
    });
  };

  const visibleColumns = getVisibleColumns(columns);

  const handleRowClick = (record: TestInstrumentType) => {
    setEditRecord(record);
  };

  const PopoverWrapper = styled.div`
    position: fixed;
    top: 550px;
    right: 30px;
    z-index: 1000;
  `;

  return (
    <>
      <Table<TestInstrumentType>
        className={styles.customTable}
        columns={visibleColumns}
        dataSource={filteredData}
        rowSelection={rowSelection}
        bordered
        size="middle"
        scroll={{ x: 'max-content', y: 'calc(100vh - 300px)' }}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
          style: { cursor: 'pointer' }
        })}
      />
      
      <PopoverWrapper>
        <Popover
          content={
            editRecord && (
              <EditInstrument
                record={editRecord}
                type={INSTRUMENT_TYPES.TESTING}
                onCancel={() => setEditRecord(null)}
                onSuccess={() => {
                  setEditRecord(null);
                  onSuccess?.();
                }}
              />
            )
          }
          trigger="click"
          open={!!editRecord}
          onOpenChange={(visible) => !visible && setEditRecord(null)}
          placement="left"
          getPopupContainer={(triggerNode) => triggerNode.parentElement || document.body}
          overlayInnerStyle={{ 
            background: 'transparent', 
            boxShadow: 'none'
          }}
          overlayStyle={{ 
            padding: 0
          }}
        />
      </PopoverWrapper>
    </>
  );
};

export default TestInstrumentsTable;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  
  .column-menu-icon {
    opacity: 1;
  }
`;

const TitleText = styled.div`
  flex: 1;
`;

const MenuIcon = styled.div`
  opacity: 0;
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 4px;
  transition: all 0.2s;
  
  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }
  
  .anticon {
    font-size: 16px;
    color: #8C8C8C;
  }
`;