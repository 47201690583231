import { axiosInstance } from './instance';

export interface RawMaterial {
    name: string;
    certifiedValue: number;
    weight: number;
}

export interface QualityControlData {
    method: string;
    status: string;
    raw_materials?: RawMaterial[];
    calculated_value?: number;
    intermediate_control_number?: string;
    spc_requirement?: string;
    spc_compliance?: string;
}

export const createQualityControl = async (batchId: number, data: QualityControlData) => {
    const response = await axiosInstance.post(`/api/batches/${batchId}/quality-control`, {
        ...data,
        batch_id: batchId
    });
    return response.data;
};

export const getQualityControl = async (batchId: number) => {
    const response = await axiosInstance.get(`/api/batches/${batchId}/quality-control`);
    return response.data;
};