import axiosInstance from './axios';

export interface BatchCreate {
    batch_number: string;
    batch_code: string;
    registration_date: string;
    target_value: number;
    unit: string;
    planned_volume: number;
}

export interface Batch extends BatchCreate {
    id: number;
    packaging_permission: boolean;
    packaging_complete: boolean;
}

export const createBatch = async (data: BatchCreate): Promise<Batch> => {
    const response = await axiosInstance.post<Batch>('/api/batches', data);
    return response.data;
};

export const getBatches = async (): Promise<Batch[]> => {
    const response = await axiosInstance.get<Batch[]>('/api/batches');
    return response.data;
};

export const getBatch = async (id: number): Promise<Batch> => {
    const response = await axiosInstance.get<Batch>(`/api/batches/${id}`);
    return response.data;
};

export const updateBatch = async (id: number, data: Partial<BatchCreate>): Promise<Batch> => {
    const response = await axiosInstance.put<Batch>(`/api/batches/${id}`, data);
    return response.data;
};

export const deleteBatch = async (id: number): Promise<void> => {
    await axiosInstance.delete(`/api/batches/${id}`);
}; 