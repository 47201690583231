import React, { useState } from 'react';
import { Button, Input, Typography, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from '../../api/axios';

const { Title, Text } = Typography;

const PageWrapper = styled.div`
  width: 100%;
  height: 867px;
  background-color: #f5f5f5;
  position: relative;
  font-family: 'Roboto', sans-serif;
`;

const ContentWrapper = styled.div`
  position: absolute;
  top: calc(50% - 211.5px);
  left: calc(50% - 236px);
  width: 472px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
`;

const FormWrapper = styled.div`
  width: 376px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Description = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  color: #bfbfbf;
  text-align: center;
  font-weight: 300;
`;

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InputLabel = styled.div`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #8c8c8c;
`;

const StyledInput = styled(Input)`
  background: #fafafa;
  border: 0.5px solid #d9d9d9;
  height: 40px;
  
  &::placeholder {
    color: rgba(0, 0, 0, 0.25);
    font-weight: 400;
    font-size: 14px;
  }
`;

const BackText = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  color: #2b2b2f;
  cursor: pointer;
  font-weight: 400;
`;

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!email) {
      message.error('Пожалуйста, введите email');
      return;
    }

    if (!email.includes('@')) {
      message.error('Пожалуйста, введите корректный email');
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post('/api/auth/reset-password', { email });
      message.success('Новый пароль отправлен на указанный email');
      navigate('/auth/login');
    } catch (error: any) {
      console.error('Reset password error:', error);
      if (error.response) {
        message.error(error.response.data.detail || 'Произошла ошибка при сбросе пароля');
      } else if (error.request) {
        message.error('Не удалось связаться с сервером. Пожалуйста, проверьте подключение к интернету');
      } else {
        message.error('Произошла ошибка при отправке запроса');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageWrapper>
      <ContentWrapper>
        <Title
          level={2}
          style={{
            margin: 0,
            fontSize: 30,
            fontWeight: 500,
            lineHeight: '40px'
          }}
        >
          Личный кабинет
        </Title>

        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
          <FormWrapper>
            <Title
              level={5}
              style={{
                margin: 0,
                color: '#000',
                fontSize: 16,
                lineHeight: '24px',
                fontWeight: 600
              }}
            >
              Восстановление пароля
            </Title>

            <Description>
              Для восстановления пароля введите ваш e-mail
              <br />
              и следуйте инструкциям в письме
            </Description>

            <InputWrapper>
              <InputLabel>E-mail</InputLabel>
              <StyledInput 
                placeholder="введите e-mail" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ borderRadius: 4 }} 
              />
            </InputWrapper>

            <Button
              type="primary"
              block
              loading={loading}
              onClick={handleSubmit}
              style={{
                height: 48,
                fontSize: 14,
                fontWeight: 300,
                lineHeight: '28px'
              }}
            >
              Подтвердить
            </Button>
          </FormWrapper>

          <BackText onClick={() => navigate('/auth/login')}>
            &lt; Назад
          </BackText>
        </div>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default ResetPassword;